import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label, Media,
    Row
} from "reactstrap";
import Select from "react-select";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {NavLink, useNavigate} from "react-router-dom";

import Breadcrumbs from "../partials/Breadcrumbs";
import {decryptData, encryptData} from "../../utils/crypto";
import {getAllTagsList} from "../../services/Tags";
import {getAllCategory} from "../../services/Category";
import configDb from "../../config";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {getUnitsAll} from "../../services/Units";
import {getAllBranchList} from "../../services/Branch";
import Utils from "../../utils";
import {getSupplierAllList} from "../../services/Supplier";
import {getTaxAllList} from "../../services/Tax";
import {actionStoreProduct} from "../../services/Product";

let statusList = configDb.data.statusList;
let priceTypeList = configDb.data.priceTypeList;

function CreateProduct(props) {
    const navigate = useNavigate();
    const company = useSelector(x => x.company?.value);
    const loginAuth = useSelector(x => x.auth.value);
    const [name, setName] = useState('');
    const [sku, setSKU] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [tagList, setTagList] = useState([]);
    const [selectTag, setSelectTag] = useState([]);
    const [printerList, setPrinterList] = useState([]);
    const [selectPrinter, setSelectPrinter] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [selectCategory, setSelectCategory] = useState(null);
    const [selectSupplier, setSelectSupplier] = useState(null);
    const [supplierList, setSupplierList] = useState([]);
    const [isService, setIsService] = useState(false);
    const [showServiceItems, setShowServiceItems] = useState(true);
    const [showService, setShowService] = useState(false);
    const [statusId, setStatusId] = useState(1);
    const [priceType, setPriceType] = useState(1);
    const [price, setPrice] = useState(0);
    const [priceBeforeTax, setPriceBeforeTax] = useState(0);
    const [priceTaxList, setPriceTaxList] = useState([]);
    const [priceTax, setPriceTax] = useState('');
    const [priceAfterTax, setPriceAfterTax] = useState(0);
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showUnits, setShowUnits] = useState(false)
    const [showPoints, setShowPoints] = useState(false)
    const [showOpenPrice, setShowOpenPrice] = useState(true)
    const [unitList, setUnitList] = useState([]);
    const [unitId, setUnitId] = useState(null);
    const [productPoint, setProductPoint] = useState('');
    const [productPointLimit, setProductPointLimit] = useState('');
    const [barcodeList, setBarcodeList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [isVariant, setIsVariant] = useState(false);
    const [isInventory, setIsInventory] = useState(false);
    const [isTrackLevel, setIsTrackLevel] = useState(false);
    let scItemList = [{
        option_name: '',
        option_error: '',
        quantity: '',
        quantity_error: '',
        price_te: '',
        price_te_error: '',
        price_ti: '',
        price_ti_error: '',
        is_default: false
    }];
    let scList = {group_name: '', error: '', enable_quantity: false, list: scItemList};
    const [singleGroupList, setSingleGroupList] = useState([]);
    let mcItemList = [{
        option_name: '',
        option_error: '',
        quantity: '',
        quantity_error: '',
        price_te: '',
        price_te_error: '',
        price_ti: '',
        price_ti_error: ''
    }];
    let mcList = {group_name: '', error: '', enable_quantity: true, list: mcItemList};
    const [multiGroupList, setMultiGroupList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    useDocumentTitle(props.t('CreateProduct'));

    const errorsObj = {
        name: "",
        sku: "",
        quantity: "",
        tag_id: "",
        category_id: "",
        printer: "",
        description: "",
        barcode: "",
        image: "",
        price_type: "",
        price: "",
        price_before_tax: "",
        price_after_tax: "",
        tax_id: "",
        unit_id: "",
        product_point: "",
        product_point_limit: "",
        group: ""
    }
    const [errors, setErrors] = useState(errorsObj);

    useEffect(() => {
        if (company?.kitchen_printer) {
            let list = company?.kitchen_printer.split(',');
            let listArray = [];
            for (let i = 0; i < list.length; i++) {
                listArray.push({
                    value: list[i],
                    label: list[i]
                });
            }
            setPrinterList(listArray);
            if (company?.default_kitchen_printer) {
                let index = list.findIndex(x => x === company.default_kitchen_printer);
                if (index > -1) {
                    setSelectPrinter({value: list[index], label: list[index]});
                }
            }
        }
        if (company?.business_category_name === configDb.data.serviceLine) {
            setShowService(true);
        }
    }, [company]);

    useEffect(function () {
        let data = encryptData({entity_type: 1, language: getLanguage()});
        getAllTagsList(data)
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    list.push({
                        value: response.data[i].id,
                        label: response.data[i].name
                    });
                }
                setTagList(list);
            })
            .catch(err => {
            });
    }, []);

    useEffect(function () {
        let branchId = (loginAuth?.main_branch) ? loginAuth.main_branch : '';
        let data = encryptData({branch_id: branchId, is_all: true});
        getAllCategory(data)
            .then(response => {
                response = decryptData(response.data);
                setCategoryList(response.data);
            })
            .catch(err => {

            });
    }, [loginAuth]);

    useEffect(function () {
        getSupplierAllList()
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    list.push({
                        value: response.data[i].id,
                        label: response.data[i].full_name
                    });
                }
                setSupplierList(list);
            })
            .catch(err => {

            });
    }, []);

    useEffect(() => {
        getTaxAllList()
            .then(response => {
                response = decryptData(response.data);
                setPriceTaxList(response.data);
                if (company?.default_tax) {
                    setPriceTax(company?.default_tax);
                }
            })
            .catch(err => {

            });
    }, [company?.default_tax]);

    useEffect(function () {
        getUnitsAll()
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    list.push({
                        value: response.data[i].id,
                        label: response.data[i].name
                    });
                }
                setUnitList(list);
            })
            .catch(err => {

            });
    }, []);

    useEffect(function () {
        setBranchList([]);
        if (selectCategory) {
            let data = encryptData({category_id: selectCategory});
            getAllBranchList(data)
                .then(response => {
                    response = decryptData(response.data);
                    let list = [];
                    for (let i = 0; i < response.data.length; i++) {
                        let details = response.data[i];
                        list.push({
                            ...details, ...{
                                quantity: 0,
                                quantity_error: '',
                                ideal_stock_level: '',
                                ideal_stock_error: '',
                                warning_stock_level: '',
                                warning_stock_level_error: ''
                            }
                        });
                    }
                    setBranchList(list);
                })
                .catch(err => {

                });
        }
    }, [selectCategory]);
    const handleService = (e) => {
        setIsService(!isService);
        setShowServiceItems(true);
        if (e.target.checked) {
            setPriceType(1);
            setShowUnits(false);
            setShowPoints(false);
            setShowOpenPrice(true);
            setUnitId(null);
            setProductPoint('');
            setProductPointLimit('');
            setShowServiceItems(false);
            setBarcodeList([]);
            setSingleGroupList([]);
            setMultiGroupList([]);
            setIsVariant(false);
            setIsInventory(false);
        }
    }
    const onAddBarcode = (e) => {
        e.preventDefault();
        let list = [...barcodeList];
        list.push({barcode: '', error: ''});
        setBarcodeList(list);
        let errorObj = {...errors};
        errorObj.barcode = '';
        setErrors(errorObj);
    };

    const handleBarcodeValue = (e, index) => {
        let list = [...barcodeList];
        list[index]['barcode'] = e.target.value.trim();
        setBarcodeList(list);
    };

    const handleBarcodeRemove = (e, index) => {
        e.preventDefault();
        let list = [...barcodeList];
        list.splice(index, 1);
        setBarcodeList(list);
    };

    const onAddImage = (e) => {
        e.preventDefault();
        let list = [...imageList];
        let isDefault = false;
        if (list.length === 0) {
            isDefault = true;
        }
        list.push({file: {}, image: '', is_default: isDefault, error: ''});
        setImageList(list);
        let errorObj = {...errors};
        errorObj.image = '';
        setErrors(errorObj);
    }

    const handleSelectImage = (e, index) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            let list = [...imageList];
            if (!e.target.files[0].name.match(configDb.data.imageExt)) {
                list[index]['file'] = {};
                list[index]['error'] = props.t(`InvalidImage`);
                list[index].image = '';
            } else if (((e.target.files[0].size / 1000) / 1000) > 2) {
                list[index]['file'] = {};
                list[index]['error'] = props.t(`InvalidImageSize`);
                list[index].image = '';
            } else {
                list[index].image = URL.createObjectURL(e.target.files[0]);
                list[index]['file'] = e.target.files[0];
                list[index]['error'] = '';
            }
            setImageList(list);
        }
    }

    const handelImageDefault = (e, index) => {
        let list = [...imageList];
        for (let i = 0; i < list.length; i++) {
            let isDefault = false;
            if (i === index) {
                isDefault = true;
            }
            list[i]['is_default'] = isDefault;
        }
        setImageList(list);
    }
    const handleImageRemove = (e, index) => {
        e.preventDefault();
        let list = [...imageList];
        list.splice(index, 1);

        setImageList(list);
    };
    const handelChangePriceType = (e) => {
        let priceValue = e.target.value;
        setPriceType(priceValue);
        setShowUnits(false);
        setShowPoints(false);
        setShowOpenPrice(true);
        setShowServiceItems(true);
        setUnitId(null);
        setProductPoint('');
        setProductPointLimit('');
        if (parseInt(priceValue) === 2) {
            setShowUnits(true);
        } else if (parseInt(priceValue) === 3) {
            setShowPoints(true);
        } else if (parseInt(priceValue) === 4) {
            setShowOpenPrice(false);
            setShowServiceItems(false);
            setIsVariant(false);
            setIsInventory(false);
            setPriceBeforeTax(0);
            setPriceTax('');
            setPriceAfterTax(0);
        }
    }
    const handelChangePriceBeforeTax = (e) => {
        let priceBefore = e.target.value;
        setPriceBeforeTax(priceBefore);
        if (priceBefore) {
            let index = priceTaxList.findIndex(x => parseInt(x.id) === parseInt(priceTax));
            if (index > -1) {
                let value = priceTaxList[index]['value'];
                if (value > 0) {
                    setPriceAfterTax(parseFloat(parseFloat(priceBefore) + ((priceBefore * value) / 100)).toFixed(2));
                } else {
                    setPriceAfterTax(parseFloat(priceBefore).toFixed(2));
                }
            }
        } else {
            setPriceAfterTax(0);
        }
    }
    const handelChangeTax = (e) => {
        let taxValue = e.target.value;
        setPriceTax(e.target.value);
        let index = priceTaxList.findIndex(x => parseInt(x.id) === parseInt(taxValue));
        let value = 0;
        if (index > -1) {
            value = priceTaxList[index]['value'];
        }
        if (priceBeforeTax) {
            if (value > 0) {
                setPriceAfterTax(parseFloat(parseFloat(priceBeforeTax) + ((priceBeforeTax * value) / 100)).toFixed(2));
            } else {
                setPriceAfterTax(parseFloat(priceBeforeTax).toFixed(2));
            }

        } else {
            setPriceAfterTax(0);
        }

        /*Group */
        let sList = [...singleGroupList];
        let mList = [...multiGroupList];
        for (let i = 0; i < sList.length; i++) {
            for (let j = 0; j < sList[i]['list'].length; j++) {
                if (sList[i]['list'][j]['price_te']) {
                    if (value > 0) {
                        sList[i]['list'][j]['price_ti'] = parseFloat(parseFloat(sList[i]['list'][j]['price_te']) + ((sList[i]['list'][j]['price_te'] * value) / 100)).toFixed(2);
                    } else {
                        sList[i]['list'][j]['price_ti'] = parseFloat(sList[i]['list'][j]['price_te']).toFixed(2);
                    }
                }
            }
        }

        for (let i = 0; i < mList.length; i++) {
            for (let j = 0; j < mList[i]['list'].length; j++) {
                if (mList[i]['list'][j]['price_te']) {
                    if (value > 0) {
                        mList[i]['list'][j]['price_ti'] = parseFloat(parseFloat(mList[i]['list'][j]['price_te']) + ((mList[i]['list'][j]['price_te'] * value) / 100)).toFixed(2);
                    } else {
                        mList[i]['list'][j]['price_ti'] = parseFloat(mList[i]['list'][j]['price_te']).toFixed(2);
                    }
                }
            }
        }
        /*Group */
    }
    const handelChangePriceAfterTax = (e) => {
        let afterValue = e.target.value;
        setPriceAfterTax(afterValue);
        if (afterValue) {
            let index = priceTaxList.findIndex(x => parseInt(x.id) === parseInt(priceTax));
            if (index > -1) {
                let value = priceTaxList[index]['value'];
                if (value > 0) {
                    setPriceBeforeTax(parseFloat(afterValue * 100 / (parseInt(value) + 100)).toFixed(2));
                } else {
                    setPriceBeforeTax(parseFloat(afterValue).toFixed(2));
                }
            }
        } else {
            setPriceBeforeTax(0);
        }
    }

    const handleVariant = (e) => {
        setIsVariant(!isVariant);
        if (e.target.checked) {
            let list = {...scList};
            list.list[0]['is_default'] = true;
            setSingleGroupList([list]);
            setMultiGroupList([mcList]);
        } else {
            setSingleGroupList([]);
        }
    }
    const onAddChoice = (e, entity) => {
        e.preventDefault();
        let list = (entity === 1) ? [...singleGroupList] : [...multiGroupList];
        if (entity === 1) {
            let sList = {...scList};
            sList.list[0]['is_default'] = true;
            list.push(sList);
            setSingleGroupList(list);
        } else {
            list.push(mcList);
            setMultiGroupList(list);
        }
        let errorObj = {...errors};
        errorObj.group = '';
        setErrors(errorObj);
    }
    const handleGroupName = (e, index, entity) => {
        let list = (entity === 1) ? [...singleGroupList] : [...multiGroupList];
        list[index]['group_name'] = e.target.value;
        if (entity === 1) {
            setSingleGroupList(list);
        } else {
            setMultiGroupList(list);
        }
    };
    const handelEnableQuantity = (e, index, entity) => {
        let list = (entity === 1) ? [...singleGroupList] : [...multiGroupList];
        list[index]['enable_quantity'] = e.target.checked;
        for (let i = 0; i < list[index]['list'].length; i++) {
            list[index]['list'][i]['quantity'] = '';
        }
        if (entity === 1) {
            setSingleGroupList(list);
        } else {
            setMultiGroupList(list);
        }
    }

    const handleGroupRemove = (e, index, entity) => {
        e.preventDefault();
        let list = (entity === 1) ? [...singleGroupList] : [...multiGroupList];
        list.splice(index, 1);
        if (entity === 1) {
            setSingleGroupList(list);
        } else {
            setMultiGroupList(list);
        }
    };

    const handleOption = (e, index, idx, type, entity) => {
        let list = (entity === 1) ? [...singleGroupList] : [...multiGroupList];
        let currentValue = e.target.value;
        let indexTax = priceTaxList.findIndex(x => parseInt(x.id) === parseInt(priceTax));
        if (type === 'price_te') {
            if (currentValue) {
                if (indexTax > -1) {
                    let value = priceTaxList[indexTax]['value'];
                    if (value > 0) {
                        list[index]['list'][idx]['price_ti'] = parseFloat(parseFloat(currentValue) + ((currentValue * value) / 100)).toFixed(2);
                    } else {
                        list[index]['list'][idx]['price_ti'] = parseFloat(currentValue).toFixed(2);
                    }
                }
            } else {
                list[index]['list'][idx]['price_ti'] = 0;
            }
        } else if (type === 'price_ti') {
            if (currentValue) {
                if (indexTax > -1) {
                    let value = priceTaxList[indexTax]['value'];
                    if (value > 0) {
                        list[index]['list'][idx]['price_te'] = parseFloat(currentValue * 100 / (parseInt(value) + 100)).toFixed(2);
                    } else {
                        list[index]['list'][idx]['price_te'] = parseFloat(currentValue).toFixed(2);
                    }
                }
            } else {
                list[index]['list'][idx]['price_te'] = 0;
            }
        }
        list[index]['list'][idx][type] = currentValue;
        if (entity === 1) {
            setSingleGroupList(list);
        } else {
            setMultiGroupList(list);
        }
    }

    const handleSCDefault = (e, index, idx) => {
        let list = [...singleGroupList];
        let optionList = list[index]['list'];
        for (let i = 0; i < optionList.length; i++) {
            let isDefault = false;
            if (i === idx) {
                isDefault = true;
            }
            list[index]['list'][i]['is_default'] = isDefault;
        }
        setSingleGroupList(list);
    }

    const onAddOption = (e, index, entity) => {
        e.preventDefault();
        let list = (entity === 1) ? [...singleGroupList] : [...multiGroupList];
        let optionList = list[index]['list'];
        if (entity === 1) {
            list[index]['list'] = [...optionList, ...scItemList];
            setSingleGroupList(list);
        } else {
            list[index]['list'] = [...optionList, ...mcItemList];
            setMultiGroupList(list);
        }
    }

    const onRemoveOption = (e, index, idx, entity) => {
        e.preventDefault();
        let list = (entity === 1) ? [...singleGroupList] : [...multiGroupList];
        list[index]['list'].splice(idx, 1);
        if (entity === 1) {
            setSingleGroupList(list);
        } else {
            setMultiGroupList(list);
        }
    }
    const handleInventoryQuantity = (e, index) => {
        let list = [...branchList];
        list[index]['quantity'] = e.target.value;
        setBranchList(list);
    };
    const handleInvLevel = (e, index, field) => {
        let list = [...branchList];
        list[index][field] = e.target.value;
        setBranchList(list);
    };
    const checkValueIdeal = (e, index) => {
        let list = [...branchList];
        let value = e.target.value;
        let errorMsg = '';
        if (parseInt(list[index]['warning_stock_level']) > parseInt(value)) {
            value = '';
            errorMsg = props.t(`IdealStockGreater`);
        }
        list[index]['ideal_stock_level'] = value;
        list[index]['ideal_stock_error'] = errorMsg;
        setBranchList(list);
    };
    const checkValueWarning = (e, index) => {
        let list = [...branchList];
        let value = e.target.value;
        let errorMsg = '';
        if (parseInt(list[index]['ideal_stock_level']) < parseInt(value)) {
            value = '';
            errorMsg = props.t(`WarningStockLevelLess`);
        }
        list[index]['warning_stock_level'] = value;
        list[index]['warning_stock_level_error'] = errorMsg;
        setBranchList(list);
    };
    const onCreate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!name.trim()) {
            errorObj.name = props.t('RequiredProductName');
            error = true;
        }
        if (!sku.trim()) {
            errorObj.sku = props.t('RequiredSKU');
            error = true;
        }
        if (selectTag.length === 0) {
            errorObj.tag_id = props.t('RequiredTag');
            error = true;
        }
        if (!selectCategory) {
            errorObj.category_id = props.t('RequiredCategory');
            error = true;
        }
        if (!selectPrinter && !showService) {
            errorObj.printer = props.t('RequiredPrinter');
            error = true;
        }
        if (!description.trim()) {
            errorObj.description = props.t('RequiredProductDescription');
            error = true;
        }
        let bList = [...barcodeList];
        if (bList.length > 0) {
            errorObj.barcode = '';
            for (let b = 0; b < bList.length; b++) {
                bList[b]['error'] = '';
                let idx = bList.findIndex(x => x.barcode === bList[b]['barcode'].trim());
                if (!bList[b]['barcode'].trim()) {
                    bList[b]['error'] = props.t('RequiredBarcode');
                    error = true;
                } else if (idx > -1 && idx !== b) {
                    bList[b]['error'] = props.t(`RequiredUniqBarcode`);
                    error = true;
                }
            }
        }
        setBarcodeList(bList);
        let imageEList = [...imageList];
        if (imageEList.length > 0) {
            let ind = imageEList.findIndex(x => x.is_default === true);
            if (ind === -1) {
                errorObj.image = props.t('RequiredImageDefault');
                error = true;
            }
            for (let i = 0; i < imageEList.length; i++) {
                if (imageEList[i]['error']) {
                    error = true;
                } else if (!imageEList[i]['file']?.name) {
                    imageEList[i]['error'] = props.t('RequiredImage');
                    error = true;
                } else {
                    imageEList[i]['error'] = '';
                }
            }
        }
        setImageList(imageEList);
        if (!price) {
            errorObj.price = props.t('RequiredPrice');
            error = true;
        }
        if (!priceType) {
            errorObj.price_type = props.t('RequiredPriceType');
            error = true;
        } else {
            if (parseInt(priceType) !== 4) {
                if (!priceBeforeTax) {
                    errorObj.price_before_tax = props.t('RequiredPriceBeforeTax');
                    error = true;
                }
                if (!priceAfterTax) {
                    errorObj.price_after_tax = props.t('RequiredPriceAfterTax');
                    error = true;
                } else if (parseFloat(price) > parseFloat(priceAfterTax)) {
                    errorObj.price = props.t('PriceLessThenAfterTax');
                    error = true;
                }
                if (!priceTax) {
                    errorObj.tax_id = props.t('RequiredTax');
                    error = true;
                }
            }
            if (parseInt(priceType) === 2) {
                if (!unitId) {
                    errorObj.unit_id = props.t('RequiredUnit');
                    error = true;
                }

            } else if (parseInt(priceType) === 3) {
                if (!productPoint) {
                    errorObj.product_point = props.t('RequiredProductPoint');
                    error = true;
                }
                if (!productPointLimit) {
                    errorObj.product_point_limit = props.t('RequiredProductPointLimit');
                    error = true;
                }
            }

        }

        let singleEList = [...singleGroupList];
        let multiEList = [...multiGroupList];
        if (isVariant) {
            if (singleEList.length === 0 && multiEList.length === 0) {
                errorObj.group = props.t('RequiredGroup');
                error = true;
            } else {
                errorObj.group = '';
                for (let e = 0; e < singleEList.length; e++) {
                    singleEList[e]['error'] = '';
                    singleEList[e]['single_default_error'] = '';
                    if (!singleEList[e]['group_name'].trim()) {
                        singleEList[e]['error'] = props.t('RequiredGroupName');
                        error = true;
                    }
                    let ind = singleEList[e]['list'].findIndex(x => x.is_default === true);
                    if (ind === -1) {
                        singleEList[e]['single_default_error'] = props.t('RequiredDefaultOptions');
                        error = true;
                    }

                    for (let j = 0; j < singleEList[e]['list'].length; j++) {
                        let listObj = singleEList[e]['list'][j];
                        listObj['option_error'] = '';
                        listObj['price_te_error'] = '';
                        listObj['price_ti_error'] = '';
                        listObj['quantity_error'] = '';
                        if (!listObj['option_name'].trim()) {
                            listObj['option_error'] = props.t('RequiredOptionName');
                            error = true;
                        }
                        if (!listObj['price_te'].trim()) {
                            listObj['price_te_error'] = props.t('RequiredPriceExcluded');
                            error = true;
                        }
                        if (!listObj['price_ti'].trim()) {
                            listObj['price_ti_error'] = props.t('RequiredPriceIncluded');
                            error = true;
                        }
                        if (singleEList[e]['enable_quantity'] && !listObj['quantity'].trim()) {
                            listObj['quantity_error'] = props.t('RequiredQuantity');
                            error = true;
                        }
                    }

                }
                for (let e = 0; e < multiEList.length; e++) {
                    multiEList[e]['error'] = '';
                    if (!multiEList[e]['group_name'].trim()) {
                        multiEList[e]['error'] = props.t('RequiredGroupName');
                        error = true;
                    }
                    for (let j = 0; j < multiEList[e]['list'].length; j++) {
                        let listObj = multiEList[e]['list'][j];
                        listObj['option_error'] = '';
                        listObj['price_te_error'] = '';
                        listObj['price_ti_error'] = '';
                        listObj['quantity_error'] = '';
                        if (!listObj['option_name'].trim()) {
                            listObj['option_error'] = props.t('RequiredOptionName');
                            error = true;
                        }
                        if (!listObj['price_te'].trim()) {
                            listObj['price_te_error'] = props.t('RequiredPriceExcluded');
                            error = true;
                        }
                        if (!listObj['price_ti'].trim()) {
                            listObj['price_ti_error'] = props.t('RequiredPriceIncluded');
                            error = true;
                        }
                        if (multiEList[e]['enable_quantity'] && !listObj['quantity'].trim()) {
                            listObj['quantity_error'] = props.t('RequiredQuantity');
                            error = true;
                        }
                    }
                }
            }
        }
        setSingleGroupList(singleEList);
        setMultiGroupList(multiEList);

        let branchEList = [...branchList];
        if (isInventory) {
            if (branchEList.length > 0) {
                for (let b = 0; b < branchEList.length; b++) {
                    branchEList[b]['ideal_stock_error'] = '';
                    branchEList[b]['quantity_error'] = '';
                    branchEList[b]['warning_stock_level_error'] = '';
                    if (!branchEList[b]['ideal_stock_level'].trim()) {
                        branchEList[b]['ideal_stock_error'] = props.t('RequiredIdealStock');
                        error = true;
                    }
                    if (!branchEList[b]['quantity']) {
                        branchEList[b]['quantity_error'] = props.t('RequiredQuantity');
                        error = true;
                    }
                    if (!branchEList[b]['warning_stock_level'].trim()) {
                        branchEList[b]['warning_stock_level_error'] = props.t('RequiredWarningStock');
                        error = true;
                    }
                }
            }
        }
        setBranchList(branchEList);
        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        let tagIds = selectTag.map(e => e.value);
        tagIds = tagIds.join();

        const formData = new FormData();
        formData.append('language', getLanguage());
        formData.append('product_category_id', selectCategory);
        formData.append('name', name.trim());
        formData.append('sku', sku.trim());
        formData.append('quantity', quantity);
        if (selectSupplier?.value) {
            formData.append('supplier_id', selectSupplier?.value);
        }
        formData.append('description', description.trim());
        if (!showService) {
            formData.append('company_kitchen_printer', selectPrinter?.value);
        }
        formData.append('barcode_type_id', 1);
        formData.append('status_id', statusId);
        formData.append('is_service', isService);
        formData.append('barcode', JSON.stringify(bList));
        formData.append('tag_id', tagIds);
        for (let i = 0; i < imageEList.length; i++) {
            formData.append('images', imageEList[i]['file']);
            if (imageEList[i]['is_default'] === true) {
                formData.append('image_default', i);
            }
        }
        formData.append('price_type', priceType);
        formData.append('price', price);
        if (parseInt(priceType) === 2) {
            formData.append('unit_id', unitId?.value);
        } else if (parseInt(priceType) === 3) {
            formData.append('product_point', productPoint);
            formData.append('product_point_limit', productPointLimit);
        }
        if (parseInt(priceType) !== 4) {
            formData.append('price_before_tax', priceBeforeTax);
            formData.append('tax_id', priceTax);
            formData.append('price_after_tax', priceAfterTax);
        }
        formData.append('is_variant', isVariant);
        if (isVariant) {
            formData.append('single_group', JSON.stringify(singleEList));
            formData.append('multiple_group', JSON.stringify(multiEList));
        }
        formData.append('is_inventory', isInventory);
        if (isInventory) {
            formData.append('is_track_inventory', isTrackLevel);
            formData.append('inventory_list', JSON.stringify(branchEList));
        }
        actionStoreProduct(formData)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setIsLoading(false);
                navigate('/products');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            })
    }

    return (
        <>
            <Breadcrumbs parentLink={'/products'} parent={`Product`} title={`CreateProduct`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form method={`post`} onSubmit={onCreate} encType={`multipart/form-data`}>
                            <Card className="b-t-primary">
                                <CardBody>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Name`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`name`}
                                                       value={name} onChange={(e) => setName(e.target.value)}
                                                       placeholder={props.t(`EnterName`)} maxLength={75}/>
                                                {errors.name && (
                                                    <span className={`invalid-feedback`}>{errors.name}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`SKU`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`sku`}
                                                       value={sku} onChange={(e) => setSKU(e.target.value)}
                                                       placeholder={props.t(`EnterSKU`)} maxLength={75}/>
                                                {errors.sku && (
                                                    <span className={`invalid-feedback`}>{errors.sku}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ProductTags`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={tagList} isMulti value={selectTag}
                                                        onChange={(e) => setSelectTag(e)}
                                                        placeholder={props.t(`PleaseSelectTag`)}
                                                        name={`tag_id`}/>
                                                {errors.tag_id && (
                                                    <span className={`invalid-feedback`}>{errors.tag_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Category`)}<span
                                                    className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={categoryList}
                                                        onChange={(e) => setSelectCategory((e?.value) ? e.value : '')}
                                                        placeholder={props.t(`PleaseSelectCategory`)}
                                                        isClearable={true} name={`category_id`}/>
                                                {errors.category_id && (
                                                    <span className={`invalid-feedback`}>{errors.category_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Supplier`)}</Label>
                                                <Select options={supplierList} value={selectSupplier}
                                                        onChange={(e) => setSelectSupplier(e)}
                                                        placeholder={props.t(`PleaseSelectSupplier`)}
                                                        isClearable={true} name={`supplier_id`}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Quantity`)}</Label>
                                                <Input className="form-control" type="number" name={`quantity`} min={0}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                       value={quantity} onChange={(e) => setQuantity(e.target.value)}
                                                       placeholder={props.t(`EnterQuantity`)}/>
                                                {errors.quantity && (
                                                    <span className={`invalid-feedback`}>{errors.quantity}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {!showService && (
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Label className="col-form-label">{props.t(`Printer`)}<span
                                                        className={`text-danger ml-1`}>*</span></Label>
                                                    <Select options={printerList} value={selectPrinter}
                                                            onChange={(e) => setSelectPrinter(e)}
                                                            placeholder={props.t(`PleaseSelectPrinter`)}
                                                            isClearable={true} name={`printer`}/>
                                                    {errors.printer && (
                                                        <span className={`invalid-feedback`}>{errors.printer}</span>)}
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}<span
                                                    className={`text-danger ml-1`}>*</span></Label>
                                                <Input name="status_id" value={statusId}
                                                       onChange={(e) => setStatusId(e.target.value)}
                                                       type="select">
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{props.t(item.name)}</option>
                                                    )}</Input>
                                            </FormGroup>
                                        </Col>
                                        {showService && (
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Label
                                                        className="col-form-label">{props.t(`Service`)}</Label>
                                                    <br/>
                                                    <label className="custom-switch">
                                                        <input type="checkbox" checked={isService}
                                                               onChange={handleService}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </FormGroup>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label
                                                    className="col-form-label">{props.t(`ProductDescription`)}<span
                                                    className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="textarea" name={`description`}
                                                       value={description} rows={5}
                                                       onChange={(e) => setDescription(e.target.value)}
                                                       placeholder={props.t(`EnterDescription`)}/>
                                                {errors.description && (
                                                    <span className={`invalid-feedback`}>{errors.description}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {showServiceItems && (
                                        <>
                                            <Card className="b-t-primary mt-4">
                                                <CardHeader className={`py-4`}>
                                                    <h5>
                                                        <span>{props.t(`ProductBarcode`)}</span>
                                                        <Button color="primary" type={`button`} onClick={onAddBarcode}
                                                                className={`float-right btn-square`}>{props.t(`Add`)}</Button>
                                                    </h5>
                                                </CardHeader>
                                                <CardBody>
                                                    {barcodeList.map((item, i) => (
                                                        <Row key={i}>
                                                            <Col sm={4}>
                                                                <FormGroup>
                                                                    <Input className="form-control" type="text"
                                                                           value={item.barcode}
                                                                           onChange={(e) => handleBarcodeValue(e, i)}
                                                                           placeholder={props.t(`EnterBarcode`)}
                                                                           maxLength={75}/>
                                                                    {item.error && (
                                                                        <span
                                                                            className={`invalid-feedback`}>{item.error}</span>)}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <FormGroup>
                                                                    <Button color={`danger`} className={`btn-square`}
                                                                            onClick={(e) => handleBarcodeRemove(e, i)}
                                                                            type={`button`}>{props.t(`Remove`)}</Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    {errors.barcode && (
                                                        <span className={`invalid-feedback`}>{errors.barcode}</span>)}
                                                </CardBody>
                                            </Card>
                                        </>
                                    )}
                                    <Card className="b-t-primary">
                                        <CardHeader className={`py-4`}>
                                            <h5>
                                                <span>{props.t(`ProductImage`)}</span>
                                                <Button color="primary" type={`button`} onClick={onAddImage}
                                                        className={`float-right btn-square`}>{props.t(`Add`)}</Button>
                                            </h5>
                                        </CardHeader>
                                        <CardBody>
                                            {imageList.map((item, i) => (
                                                <Row key={i}>
                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            {i === 0 && (
                                                                <Label
                                                                    className="col-form-label">{props.t(`SelectImage`)}
                                                                    <span className={`text-danger ml-1`}>*</span>
                                                                </Label>
                                                            )}
                                                            <Input className="form-control" type="file"
                                                                   onChange={(e) => handleSelectImage(e, i)}
                                                                   accept="image/*"/>
                                                            {item.error && (
                                                                <span
                                                                    className={`invalid-feedback`}>{item.error}</span>)}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={1}>
                                                        <FormGroup>
                                                            {i === 0 && (
                                                                <>
                                                                    <Label
                                                                        className="col-form-label text-white">Remove</Label>
                                                                    <br/>
                                                                </>
                                                            )}
                                                            {item.image && (
                                                                <Media body style={{
                                                                    width: "35px",
                                                                    height: "35px",
                                                                    borderRadius: "50%"
                                                                }} src={item.image} alt=""/>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={1} className={`text-center`}>
                                                        <FormGroup>
                                                            {i === 0 && (
                                                                <>
                                                                    <Label
                                                                        className="col-form-label">{props.t(`Default`)}</Label>
                                                                    <br/>
                                                                </>
                                                            )}
                                                            <div className="radio radio-primary">
                                                                <Input name="file_default" type="radio"
                                                                       checked={item.is_default}
                                                                       id={`image_default_${i}`}
                                                                       onChange={(e) => handelImageDefault(e, i)}/>
                                                                <Label for={`image_default_${i}`}/>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <FormGroup>
                                                            {i === 0 && (
                                                                <>
                                                                    <Label
                                                                        className="col-form-label text-white">Remove</Label>
                                                                    <br/>
                                                                </>
                                                            )}
                                                            <Button color={`danger`} className={`btn-square`}
                                                                    onClick={(e) => handleImageRemove(e, i)}
                                                                    type={`button`}>{props.t(`Remove`)}</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            ))}
                                            {errors.image && (
                                                <span className={`invalid-feedback`}>{errors.image}</span>)}
                                        </CardBody>
                                    </Card>

                                    <Card className="b-t-primary">
                                        <CardHeader className={`py-4`}>
                                            <h5>{props.t(`Pricing`)}</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label className="col-form-label">{props.t(`PricingType`)}
                                                            <span className={`text-danger ml-1`}>*</span></Label>
                                                        <Input name="price_type" value={priceType}
                                                               onChange={handelChangePriceType}
                                                               disabled={isService}
                                                               type="select">
                                                            {priceTypeList.map((item, i) =>
                                                                <option key={i}
                                                                        value={item.id}>{item.name}</option>
                                                            )}
                                                        </Input>
                                                        {errors.price_type && (
                                                            <span
                                                                className={`invalid-feedback`}>{errors.price_type}</span>)}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label className="col-form-label">{props.t(`Cost`)}
                                                            <span className={`text-danger ml-1`}>*</span></Label>
                                                        <Input className="form-control" type="number" name={`cost`}
                                                               step="0.01" min={0}
                                                               onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                               value={price} onChange={(e) => setPrice(e.target.value)}
                                                               placeholder={props.t(`EnterCost`)}/>
                                                        {errors.price && (
                                                            <span
                                                                className={`invalid-feedback`}>{errors.price}</span>)}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {showOpenPrice && (
                                                <>
                                                    <Row>
                                                        {showUnits && (
                                                            <Col sm="4">
                                                                <FormGroup>
                                                                    <Label
                                                                        className="col-form-label">{props.t(`ProductUnit`)}
                                                                        <span
                                                                            className={`text-danger ml-1`}>*</span></Label>
                                                                    <Select options={unitList} value={unitId}
                                                                            onChange={(e) => setUnitId(e)}
                                                                            placeholder={props.t(`PleaseSelectUnit`)}
                                                                            name={`unit_id`}/>
                                                                    {errors.unit_id && (
                                                                        <span
                                                                            className={`invalid-feedback`}>{errors.unit_id}</span>)}
                                                                </FormGroup>
                                                            </Col>
                                                        )}
                                                        {showPoints && (
                                                            <>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="col-form-label">{props.t(`ProductPoint`)}
                                                                            <span
                                                                                className={`text-danger ml-1`}>*</span></Label>
                                                                        <Input className="form-control" type="number"
                                                                               min={0}
                                                                               name={`product_point`}
                                                                               value={productPoint}
                                                                               onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                               onChange={(e) => setProductPoint(e.target.value)}
                                                                               placeholder={props.t(`EnterProductPoint`)}
                                                                               maxLength={20}/>
                                                                        {errors.product_point && (
                                                                            <span
                                                                                className={`invalid-feedback`}>{errors.product_point}</span>)}
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="col-form-label">{props.t(`ProductLimit`)}
                                                                            <span
                                                                                className={`text-danger ml-1`}>*</span></Label>
                                                                        <Input className="form-control" type="number"
                                                                               min={0}
                                                                               name={`product_point_limit`}
                                                                               value={productPointLimit}
                                                                               onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                               onChange={(e) => setProductPointLimit(e.target.value)}
                                                                               placeholder={props.t(`EnterProductLimit`)}
                                                                               maxLength={20}/>
                                                                        {errors.product_point_limit && (
                                                                            <span
                                                                                className={`invalid-feedback`}>{errors.product_point_limit}</span>)}
                                                                    </FormGroup>
                                                                </Col>
                                                            </>
                                                        )}
                                                    </Row>
                                                    <Row>
                                                        <Col sm="4">
                                                            <FormGroup>
                                                                <Label
                                                                    className="col-form-label">{props.t(`PriceBeforeTax`)}
                                                                    <span
                                                                        className={`text-danger ml-1`}>*</span></Label>
                                                                <Input className="form-control" type="number"
                                                                       step="0.01" min={0}
                                                                       onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                                       name={`price_before_tax`}
                                                                       value={priceBeforeTax}
                                                                       onChange={handelChangePriceBeforeTax}
                                                                       placeholder={props.t(`EnterPriceBeforeTax`)}/>
                                                                {errors.price_before_tax && (
                                                                    <span
                                                                        className={`invalid-feedback`}>{errors.price_before_tax}</span>)}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="4">
                                                            <FormGroup>
                                                                <Label
                                                                    className="col-form-label">{props.t(`ProductTax`)}
                                                                    <span
                                                                        className={`text-danger ml-1`}>*</span></Label>
                                                                <Input name="tax_id" value={priceTax}
                                                                       onChange={handelChangeTax}
                                                                       type="select">
                                                                    <option value={``}>Select Tax</option>
                                                                    {priceTaxList.map((item, i) =>
                                                                        <option key={i}
                                                                                value={item.id}>{item.name} ({item.value}%)</option>
                                                                    )}</Input>
                                                                {errors.tax_id && (
                                                                    <span
                                                                        className={`invalid-feedback`}>{errors.tax_id}</span>)}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="4">
                                                            <FormGroup>
                                                                <Label
                                                                    className="col-form-label">{props.t(`PriceAfterTax`)}
                                                                    <span
                                                                        className={`text-danger ml-1`}>*</span></Label>
                                                                <Input className="form-control" type="number"
                                                                       step="0.01" min={0}
                                                                       onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                                       name={`price_after_tax`}
                                                                       value={priceAfterTax}
                                                                       onChange={handelChangePriceAfterTax}
                                                                       placeholder={props.t(`EnterPriceAfterTax`)}/>
                                                                {errors.price_after_tax && (
                                                                    <span
                                                                        className={`invalid-feedback`}>{errors.price_after_tax}</span>)}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </CardBody>
                                    </Card>
                                    {showServiceItems && (
                                        <>
                                            <Card className="b-t-primary">
                                                <CardHeader className={`py-4`}>
                                                    <h5>{props.t(`Variants`)}
                                                        <div className={`float-right`}>
                                                            <small className={`mr-3`}>{props.t(`HasVariant`)}</small>
                                                            <label className="custom-switch">
                                                                <input type="checkbox" checked={isVariant}
                                                                       onChange={handleVariant}/>
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </h5>
                                                </CardHeader>
                                                {isVariant && (
                                                    <CardBody>
                                                        {errors.group && (
                                                            <span
                                                                className={`invalid-feedback`}>{errors.group}</span>)}
                                                        <Card>
                                                            <CardBody>
                                                                <div>
                                                                    <h6>{props.t(`SingleChoice`)}
                                                                        <Button color="primary" type={`button`}
                                                                                onClick={(e) => onAddChoice(e, 1)}
                                                                                className={`ml-2 btn-sm btn-square`}>{props.t(`AddGroup`)}</Button>
                                                                    </h6>
                                                                </div>
                                                                {singleGroupList.map((item, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {i > 0 && (<hr/>)}
                                                                        <Row>
                                                                            <Col sm="4">
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        className="col-form-label">{props.t(`GroupName`)}
                                                                                        <span
                                                                                            className={`text-danger ml-1`}>*</span></Label>
                                                                                    <Input className="form-control"
                                                                                           type="text"
                                                                                           value={item.group_name}
                                                                                           onChange={(e) => handleGroupName(e, i, 1)}
                                                                                           placeholder={props.t(`EnterGroupName`)}
                                                                                           maxLength={75}/>
                                                                                    {item.error && (
                                                                                        <span
                                                                                            className={`invalid-feedback`}>{item.error}</span>)}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm="2">
                                                                                <FormGroup>
                                                                                    <Label className="col-form-label">Enable
                                                                                        Quantity</Label>
                                                                                    <br/>
                                                                                    <label className="custom-switch">
                                                                                        <input type="checkbox"
                                                                                               checked={item.enable_quantity}
                                                                                               onChange={(e) => handelEnableQuantity(e, i, 1)}/>
                                                                                        <span
                                                                                            className="slider round"></span>
                                                                                    </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm={2}>
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        className="col-form-label text-white">Remove</Label>
                                                                                    <br/>
                                                                                    <Button color={`danger`}
                                                                                            className={`btn-sm px-3 btn-square`}
                                                                                            onClick={(e) => handleGroupRemove(e, i, 1)}
                                                                                            type={`button`}>{props.t(`X`)}</Button>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        {item.list.map((listItem, j) => (
                                                                            <div key={j}>
                                                                                <Row>
                                                                                    <Col sm="2">
                                                                                        <FormGroup>
                                                                                            <Label
                                                                                                className="col-form-label">{props.t(`OptionName`)}
                                                                                                <span
                                                                                                    className={`text-danger ml-1`}>*</span></Label>
                                                                                            <Input
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                onChange={(e) => handleOption(e, i, j, 'option_name', 1)}
                                                                                                value={listItem.option_name}
                                                                                                maxLength={75}/>
                                                                                            {listItem.option_error && (
                                                                                                <span
                                                                                                    className={`invalid-feedback`}>{listItem.option_error}</span>)}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    {item.enable_quantity && (
                                                                                        <Col sm="2">
                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-form-label">{props.t(`Quantity`)}
                                                                                                    <span
                                                                                                        className={`text-danger ml-1`}>*</span></Label>
                                                                                                <Input
                                                                                                    className="form-control"
                                                                                                    min={0}
                                                                                                    type="number"
                                                                                                    onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                                                    onChange={(e) => handleOption(e, i, j, 'quantity', 1)}
                                                                                                    value={listItem.quantity}
                                                                                                    maxLength={75}/>
                                                                                                {listItem.quantity_error && (
                                                                                                    <span
                                                                                                        className={`invalid-feedback`}>{listItem.quantity_error}</span>)}
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    )}
                                                                                    <Col sm="3">
                                                                                        <FormGroup>
                                                                                            <Label
                                                                                                className="col-form-label">{props.t(`PriceTaxExcluded`)}
                                                                                                <span
                                                                                                    className={`text-danger ml-1`}>*</span></Label>
                                                                                            <Input
                                                                                                className="form-control"
                                                                                                type="number"
                                                                                                step="0.01" min={0}
                                                                                                onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                                                                onChange={(e) => handleOption(e, i, j, 'price_te', 1)}
                                                                                                value={listItem.price_te}
                                                                                                maxLength={75}/>
                                                                                            {listItem.price_te_error && (
                                                                                                <span
                                                                                                    className={`invalid-feedback`}>{listItem.price_te_error}</span>)}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col sm="3">
                                                                                        <FormGroup>
                                                                                            <Label
                                                                                                className="col-form-label">{props.t(`PriceTaxIncluded`)}
                                                                                                <span
                                                                                                    className={`text-danger ml-1`}>*</span></Label>
                                                                                            <Input
                                                                                                className="form-control"
                                                                                                type="number"
                                                                                                step="0.01" min={0}
                                                                                                onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                                                                onChange={(e) => handleOption(e, i, j, 'price_ti', 1)}
                                                                                                value={listItem.price_ti}
                                                                                                maxLength={75}/>
                                                                                            {listItem.price_ti_error && (
                                                                                                <span
                                                                                                    className={`invalid-feedback`}>{listItem.price_ti_error}</span>)}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col sm="1"
                                                                                         className={`text-center`}>
                                                                                        <FormGroup>
                                                                                            <Label
                                                                                                className="col-form-label">{props.t(`Default`)}</Label>
                                                                                            <br/>
                                                                                            <div
                                                                                                className="radio radio-primary">
                                                                                                <Input type="radio"
                                                                                                       id={`option_default_${i}_${j}`}
                                                                                                       onChange={(e) => handleSCDefault(e, i, j)}
                                                                                                       checked={listItem.is_default}/>
                                                                                                <Label
                                                                                                    for={`option_default_${i}_${j}`}/>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col sm="1">
                                                                                        <Label
                                                                                            className="col-form-label text-white">Remove</Label>
                                                                                        <br/>
                                                                                        {j === 0 ?
                                                                                            <Button color={`primary`}
                                                                                                    onClick={(e) => onAddOption(e, i, 1)}
                                                                                                    className={`btn-sm px-3 btn-square`}
                                                                                                    type={`button`}>{props.t(`+`)}</Button>
                                                                                            :
                                                                                            <Button color={`danger`}
                                                                                                    onClick={(e) => onRemoveOption(e, i, j, 1)}
                                                                                                    className={`btn-sm  px-3 btn-square`}
                                                                                                    type={`button`}>{props.t(`X`)}</Button>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))}
                                                                        {item.single_default_error && (
                                                                            <span
                                                                                className={`invalid-feedback`}>{item.single_default_error}</span>)}
                                                                    </React.Fragment>
                                                                ))}
                                                            </CardBody>
                                                        </Card>
                                                        <Card>
                                                            <CardBody>
                                                                <h6>{props.t(`MultipleChoice`)}
                                                                    <Button color="primary" type={`button`}
                                                                            onClick={(e) => onAddChoice(e, 2)}
                                                                            className={`ml-2 btn-sm btn-square`}>{props.t(`AddGroup`)}</Button>
                                                                </h6>
                                                                {multiGroupList.map((item, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {i > 0 && (<hr/>)}
                                                                        <Row>
                                                                            <Col sm="4">
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        className="col-form-label">{props.t(`GroupName`)}
                                                                                        <span
                                                                                            className={`text-danger ml-1`}>*</span></Label>
                                                                                    <Input className="form-control"
                                                                                           type="text"
                                                                                           value={item.group_name}
                                                                                           onChange={(e) => handleGroupName(e, i, 2)}
                                                                                           placeholder={props.t(`EnterGroupName`)}
                                                                                           maxLength={75}/>
                                                                                    {item.error && (
                                                                                        <span
                                                                                            className={`invalid-feedback`}>{item.error}</span>)}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm="2">
                                                                                <FormGroup>
                                                                                    <Label className="col-form-label">Enable
                                                                                        Quantity</Label>
                                                                                    <br/>
                                                                                    <label className="custom-switch">
                                                                                        <input type="checkbox"
                                                                                               checked={item.enable_quantity}
                                                                                               onChange={(e) => handelEnableQuantity(e, i, 2)}/>
                                                                                        <span
                                                                                            className="slider round"></span>
                                                                                    </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm={2}>
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        className="col-form-label text-white">Remove</Label>
                                                                                    <br/>
                                                                                    <Button color={`danger`}
                                                                                            className={`btn-sm btn-square px-3`}
                                                                                            onClick={(e) => handleGroupRemove(e, i, 2)}
                                                                                            type={`button`}>{props.t(`X`)}</Button>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        {item.list.map((listItem, j) => (
                                                                            <div key={j}>
                                                                                <Row>
                                                                                    <Col sm="2">
                                                                                        <FormGroup>
                                                                                            <Label
                                                                                                className="col-form-label">{props.t(`OptionName`)}
                                                                                                <span
                                                                                                    className={`text-danger ml-1`}>*</span></Label>
                                                                                            <Input
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                onChange={(e) => handleOption(e, i, j, 'option_name', 2)}
                                                                                                value={listItem.option_name}
                                                                                                maxLength={75}/>
                                                                                            {listItem.option_error && (
                                                                                                <span
                                                                                                    className={`invalid-feedback`}>{listItem.option_error}</span>)}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    {item.enable_quantity && (
                                                                                        <Col sm="2">
                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-form-label">{props.t(`Quantity`)}
                                                                                                    <span
                                                                                                        className={`text-danger ml-1`}>*</span></Label>
                                                                                                <Input
                                                                                                    className="form-control"
                                                                                                    type="number"
                                                                                                    min={0}
                                                                                                    onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                                                    onChange={(e) => handleOption(e, i, j, 'quantity', 2)}
                                                                                                    value={listItem.quantity}
                                                                                                    maxLength={75}/>
                                                                                                {listItem.quantity_error && (
                                                                                                    <span
                                                                                                        className={`invalid-feedback`}>{listItem.quantity_error}</span>)}
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    )}
                                                                                    <Col sm="3">
                                                                                        <FormGroup>
                                                                                            <Label
                                                                                                className="col-form-label">{props.t(`PriceTaxExcluded`)}
                                                                                                <span
                                                                                                    className={`text-danger ml-1`}>*</span></Label>
                                                                                            <Input
                                                                                                className="form-control"
                                                                                                type="number"
                                                                                                min={0}
                                                                                                step="0.01"
                                                                                                onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                                                                onChange={(e) => handleOption(e, i, j, 'price_te', 2)}
                                                                                                value={listItem.price_te}
                                                                                                maxLength={75}/>
                                                                                            {listItem.price_te_error && (
                                                                                                <span
                                                                                                    className={`invalid-feedback`}>{listItem.price_te_error}</span>)}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col sm="3">
                                                                                        <FormGroup>
                                                                                            <Label
                                                                                                className="col-form-label">{props.t(`PriceTaxIncluded`)}
                                                                                                <span
                                                                                                    className={`text-danger ml-1`}>*</span></Label>
                                                                                            <Input type="number"
                                                                                                   min={0}
                                                                                                   step="0.01"
                                                                                                   onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                                                                   onChange={(e) => handleOption(e, i, j, 'price_ti', 2)}
                                                                                                   value={listItem.price_ti}
                                                                                                   maxLength={75}/>
                                                                                            {listItem.price_ti_error && (
                                                                                                <span
                                                                                                    className={`invalid-feedback`}>{listItem.price_ti_error}</span>)}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col sm="1">
                                                                                        <Label
                                                                                            className="col-form-label text-white">Remove</Label>
                                                                                        <br/>
                                                                                        {j === 0 ?
                                                                                            <Button color={`primary`}
                                                                                                    onClick={(e) => onAddOption(e, i, 2)}
                                                                                                    className={`btn-sm btn-square px-3`}
                                                                                                    type={`button`}>{props.t(`+`)}</Button>
                                                                                            :
                                                                                            <Button color={`danger`}
                                                                                                    onClick={(e) => onRemoveOption(e, i, j, 2)}
                                                                                                    className={`btn-sm btn-square px-3`}
                                                                                                    type={`button`}>{props.t(`X`)}</Button>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))}
                                                                    </React.Fragment>
                                                                ))}
                                                            </CardBody>
                                                        </Card>
                                                    </CardBody>
                                                )}
                                            </Card>
                                            <Card className="b-t-primary">
                                                <CardHeader className={`py-4`}>
                                                    <h5>{props.t(`Inventory`)}
                                                        <div className={`float-right`}>
                                                            <small className={`mr-3`}>{props.t(`HasInventory`)}</small>
                                                            <label className="custom-switch">
                                                                <input type="checkbox" checked={isInventory}
                                                                       onChange={(e) => setIsInventory(!isInventory)}/>
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </h5>
                                                </CardHeader>
                                                {(isInventory && branchList.length > 0 &&
                                                    <CardBody>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <div className="checkbox checkbox-primary ml-3">
                                                                    <Input id="track_stock_level" checked={isTrackLevel}
                                                                           onChange={(e) => setIsTrackLevel(e.target.checked)}
                                                                           type="checkbox"/>
                                                                    <Label
                                                                        for="track_stock_level">{props.t(`TrackStockLevel`)}</Label>
                                                                </div>
                                                            </Col>
                                                            <Col sm={12}>
                                                                {branchList.map((item, i) =>
                                                                    <fieldset className={`mb-4`} key={i}>
                                                                        <legend>{item.display_name}</legend>
                                                                        <Row>
                                                                            <Col sm={3}>
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        className="col-form-label">{props.t(`Quantity`)}
                                                                                        <span
                                                                                            className={`text-danger ml-1`}>*</span></Label>
                                                                                    <Input type="number"
                                                                                           min={0}
                                                                                           onChange={(e) => handleInventoryQuantity(e, i)}
                                                                                           onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                                           value={item.quantity}
                                                                                           maxLength={75}/>
                                                                                    {item.quantity_error && (
                                                                                        <span
                                                                                            className={`invalid-feedback`}>{item.quantity_error}</span>)}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm={3}>
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        className="col-form-label">{props.t(`IdealStockLevel`)}
                                                                                        <span
                                                                                            className={`text-danger ml-1`}>*</span></Label>
                                                                                    <Input type="number"
                                                                                           min={0}
                                                                                           onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                                           onBlur={(e) => checkValueIdeal(e, i)}
                                                                                           onChange={(e) => handleInvLevel(e, i, 'ideal_stock_level')}
                                                                                           value={item.ideal_stock_level}/>
                                                                                    {item.ideal_stock_error && (
                                                                                        <span
                                                                                            className={`invalid-feedback`}>{item.ideal_stock_error}</span>)}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm={3}>
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        className="col-form-label">{props.t(`WarningStockLevel`)}
                                                                                        <span
                                                                                            className={`text-danger ml-1`}>*</span></Label>
                                                                                    <Input type="number"
                                                                                           min={0}
                                                                                           onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                                           onBlur={(e) => checkValueWarning(e, i)}
                                                                                           onChange={(e) => handleInvLevel(e, i, 'warning_stock_level')}
                                                                                           value={item.warning_stock_level}/>
                                                                                    {item.warning_stock_level_error && (
                                                                                        <span
                                                                                            className={`invalid-feedback`}>{item.warning_stock_level_error}</span>)}
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </fieldset>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                )}
                                            </Card>
                                        </>
                                    )}
                                </CardBody>
                                <CardFooter className={`py-4`}>
                                    <Row>
                                        <Col sm={12} className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={'/products'} end role={`button`}
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" className={`btn-square`}
                                                            type={`submit`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(CreateProduct);