import React, {useState, useEffect} from "react";
import {translate, getLanguage} from "react-switch-lang";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Button, Media} from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import validator from 'validator';
import {toast} from "react-toastify";

import Utils from "../utils";
import Breadcrumbs from "./partials/Breadcrumbs";
import useDocumentTitle from '../utils/useDocumentTitle';
import {actionCountryList, actionTimeZoneList} from "../services/Common";
import {decryptData, encryptData} from "../utils/crypto";
import {updateProfile} from "../services/Users";
import configDb from "../config";
import {authActions} from "../store";

function Profile(props) {
    const dispatch = useDispatch();
    const auth = useSelector(x => x.auth.value);
    const [countryList, setCountryList] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [selectTimezone, setSelectTimezone] = useState(null);
    const [selectCountry, setSelectCountry] = useState(null);
    const [timezoneId, setTimezoneId] = useState('');
    const [isTimezoneLoad, setIsTimezoneLoad] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postcode, setPostcode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [image, setImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [keyFile, setKeyFile] = useState(Date.now);
    const [imageFile, setImageFile] = useState([]);
    const [branchId, setBranchId] = useState('');
    const [branchList, setBranchList] = useState([]);
    const errorsObj = {
        fullName: "",
        email: "",
        mobile: "",
        birthDate: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        countryId: "",
        timezone_id: "",
        postcode: "",
        profile: "",
        password: "",
        confirmPassword: ""
    };
    const [errors, setErrors] = useState(errorsObj);
    useDocumentTitle(props.t('Profile'));
    useEffect(function () {
        setFullName(auth.full_name);
        setEmail(auth.email);
        setImage(auth.profile);
        setMobile(auth.mobile ? auth.mobile : '');
        setPostcode(auth.postcode ? auth.postcode : '');
        setBirthDate(auth.birth_date ? auth.birth_date : '');
        setAddress1(auth.address_1 ? auth.address_1 : '');
        setAddress2(auth.address_2 ? auth.address_2 : '');
        setCity(auth.city ? auth.city : '');
        setState(auth.state ? auth.state : '');
        if (auth?.branch_list.length > 1 && auth?.main_branch) {
            setBranchList(auth.branch_list);
            setBranchId(auth.main_branch);
        }
    }, [auth]);

    useEffect(function () {
        actionCountryList()
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    list.push({
                        value: response.data[i].id,
                        label: response.data[i].name
                    });
                }
                setCountryList(list);
                let index = list.findIndex(x => x.value === auth.country_id);
                if (index > -1) {
                    setSelectCountry(list[index]);
                    setCountryId(auth.country_id);
                }
            })
            .catch(err => {
            });
    }, [auth]);

    useEffect(() => {
        setTimeZoneList([]);
        setSelectTimezone(null);
        setTimezoneId('');
        setIsTimezoneLoad(true);
        if (countryId) {
            let data = encryptData({country_id: countryId});
            actionTimeZoneList(data)
                .then(response => {
                    response = decryptData(response.data);
                    let list = [];
                    for (let i = 0; i < response.data.length; i++) {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].name
                        });
                    }
                    setTimeZoneList(list);
                    setIsTimezoneLoad(false);
                    let index = list.findIndex(x => x.value === auth.timezone_id);
                    if (index > -1) {
                        setSelectTimezone(list[index]);
                        setTimezoneId(auth.timezone_id);
                    }
                })
                .catch(err => {
                    setIsTimezoneLoad(false);
                });
        }
    }, [countryId, auth?.timezone_id]);

    const showDate = (date) => {
        let dateFormat = '';
        if (date) {
            dateFormat = new Date(date);
        }
        return dateFormat;
    };
    const onUpdateTimezone = (e) => {
        setCountryId(e.value);
        setSelectCountry(e);
    }

    const handleTimezone = (e) => {
        setSelectTimezone(e)
        setTimezoneId((e?.value) ? e.value : '');
    }
    const handleFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setImage(URL.createObjectURL(e.target.files[0]));
            setImageFile([e.target.files[0]]);
        } else {
            let data = Utils.loginDetail();
            setImage(data.profile);
            setImageFile([]);
        }
    };
    const onProfile = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!fullName) {
            errorObj.fullName = props.t('RequiredFullName');
            error = true;
        }
        if (!email) {
            errorObj.email = props.t('RequiredEmail');
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = props.t('InValidEmail');
            error = true;
        }
        if (!mobile) {
            errorObj.mobile = props.t('RequiredMobile');
            error = true;
        } else if (!validator.isMobilePhone(mobile)) {
            errorObj.mobile = props.t('InValidMobile');
            error = true;
        } else if (mobile && mobile.length !== 10) {
            errorObj.mobile = props.t('InValidMobile');
            error = true;
        }
        if (!password && confirmPassword) {
            errorObj.password = props.t('RequiredPassword');
            error = true;
        } else if (password && !validator.isStrongPassword(password, configDb.data.strongPassword)) {
            errorObj.password = props.t('InValidPassword');
            error = true;
        }
        if (password && !confirmPassword) {
            errorObj.confirmPassword = props.t('RequiredConfirmPassword');
            error = true;
        } else if (password !== confirmPassword) {
            errorObj.confirmPassword = props.t('PasswordNotMath');
            error = true;
        }
        if (imageFile.length > 0) {
            if (!imageFile[0].name.match(configDb.data.imageExt)) {
                errorObj.profile = props.t('InvalidImage');
                error = true;
            }
        }
        if (!postcode) {
            errorObj.postcode = props.t('RequiredPostcode');
            error = true;
        }
        if (!birthDate) {
            errorObj.birthDate = props.t('RequiredBirthDate');
            error = true;
        }
        if (!countryId) {
            errorObj.countryId = props.t('RequiredCountry');
            error = true;
        }
        if (!city) {
            errorObj.city = props.t('RequiredCity');
            error = true;
        }
        if (!state) {
            errorObj.state = props.t('RequiredState');
            error = true;
        }
        if (!address1) {
            errorObj.address1 = props.t('RequiredAddress1');
            error = true;
        }
        if (!timezoneId) {
            errorObj.timezone_id = props.t('RequiredTimeZone');
            error = true;
        }
        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        let birthDateFormat = Utils.dateFormatText(new Date(birthDate));
        const formData = new FormData();
        formData.append('language', getLanguage());
        formData.append('full_name', fullName);
        formData.append('email', email);
        formData.append('mobile', mobile);
        formData.append('birth_date', birthDateFormat);
        formData.append('address_1', address1);
        formData.append('address_2', address2);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('country_id', countryId);
        formData.append('timezone_id', timezoneId);
        formData.append('postcode', postcode);
        if (password && confirmPassword) {
            formData.append('password', password);
        }
        if (imageFile.length > 0) {
            formData.append('profile', imageFile[0]);
        }
        if (branchList.length > 1 && branchId) {
            formData.append('branch_id', branchId);
        }
        updateProfile(formData)
            .then(response => {
                let data = decryptData(response.data);
                toast.success(data.message);
                dispatch(authActions.login(data.data));
                Utils.setDetail(response.data);
                setIsLoading(false);
                setKeyFile(Date.now);
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });

    };
    return (
        <>
            <Breadcrumbs title={`Profile`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onProfile} encType={`multipart/form-data`}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`FullName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`full_name`}
                                                       value={fullName} onChange={(e) => setFullName(e.target.value)}
                                                       placeholder={props.t(`EnterFullName`)} maxLength={75}/>
                                                {errors.fullName && (
                                                    <span className={`invalid-feedback`}>{errors.fullName}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`EmailAddress`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`email`}
                                                       value={email} onChange={(e) => setEmail(e.target.value)}
                                                       placeholder={props.t(`EnterEmailAddress`)} maxLength={75}/>
                                                {errors.email && (
                                                    <span className={`invalid-feedback`}>{errors.email}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`MobileNumber`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="number" name={`mobile`}
                                                       value={mobile} min={0}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                       onChange={(e) => setMobile(e.target.value)}
                                                       placeholder={props.t(`EnterMobileNumber`)} maxLength={10}/>
                                                {errors.mobile && (
                                                    <span className={`invalid-feedback`}>{errors.mobile}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Password`)}</Label>
                                                <Input className="form-control"
                                                       type={togglePassword ? "text" : "password"} name={`password`}
                                                       value={password} onChange={(e) => setPassword(e.target.value)}
                                                       placeholder="*******"/>
                                                <div className="show-hide"
                                                     onClick={() => setTogglePassword(!togglePassword)}>
                                                    <span className={togglePassword ? "" : "show"}></span>
                                                </div>
                                                {errors.password && (
                                                    <span className={`invalid-feedback`}>{errors.password}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ConfirmPassword`)}</Label>
                                                <Input className="form-control" type="password"
                                                       name={`confirm_password`}
                                                       value={confirmPassword}
                                                       onChange={(e) => setConfirmPassword(e.target.value)}
                                                       placeholder="*******"/>
                                                {errors.confirmPassword && (
                                                    <span className={`invalid-feedback`}>{errors.confirmPassword}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`BirthDate`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <DatePicker className="form-control" type="text"
                                                            selected={showDate(birthDate)}
                                                            onChange={(date) => setBirthDate(date)}
                                                            dateFormat="dd-MM-yyyy"
                                                            maxDate={Utils.blockFutureDate()}
                                                            placeholderText={props.t(`EnterBirthDate`)}/>
                                                {errors.birthDate && (
                                                    <span className={`invalid-feedback`}>{errors.birthDate}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address1`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`address1`}
                                                       value={address1} onChange={(e) => setAddress1(e.target.value)}
                                                       placeholder={props.t(`EnterAddress1`)} maxLength={75}/>
                                                {errors.address1 && (
                                                    <span className={`invalid-feedback`}>{errors.address1}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address2`)}</Label>
                                                <Input className="form-control" type="text" name={`address2`}
                                                       value={address2} onChange={(e) => setAddress2(e.target.value)}
                                                       placeholder={props.t(`EnterAddress2`)} maxLength={75}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`city`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`city`}
                                                       value={city} onChange={(e) => setCity(e.target.value)}
                                                       placeholder={props.t(`EnterCity`)} maxLength={10}/>
                                                {errors.city && (
                                                    <span className={`invalid-feedback`}>{errors.city}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`state`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`state`}
                                                       value={state} onChange={(e) => setState(e.target.value)}
                                                       placeholder={props.t(`EnterState`)} maxLength={10}/>
                                                {errors.state && (
                                                    <span className={`invalid-feedback`}>{errors.state}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Country`)} <span
                                                    className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={countryList} onChange={onUpdateTimezone}
                                                        value={selectCountry}
                                                        placeholder={props.t(`PleaseSelectCountry`)}
                                                        name={`country_id`}/>
                                                {errors.countryId && (
                                                    <span className={`invalid-feedback`}>{errors.countryId}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`TimeZone`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select name={`time_zone`} options={timeZoneList}
                                                        value={selectTimezone} isLoading={isTimezoneLoad}
                                                        placeholder={props.t(`PleaseSelectTimezone`)}
                                                        onChange={handleTimezone}/>
                                                {errors.timezone_id && (
                                                    <span className={`invalid-feedback`}>{errors.timezone_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`postcode`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`postcode`}
                                                       value={postcode} onChange={(e) => setPostcode(e.target.value)}
                                                       placeholder={props.t(`EnterPostCode`)} maxLength={10}/>
                                                {errors.postcode && (
                                                    <span className={`invalid-feedback`}>{errors.postcode}</span>)}
                                            </FormGroup>
                                        </Col>
                                        {branchList.length > 0 && (
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Label className="col-form-label">{props.t(`Branch`)}
                                                        <span className={`text-danger ml-1`}>*</span></Label>
                                                    <Input type="select" name={`branch_id`} value={branchId}
                                                           onChange={(e) => setBranchId(e.target.value)}>
                                                        {branchList.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.display_name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Profile`)}</Label>
                                                <Input className="form-control" type="file" onChange={handleFile}
                                                       key={keyFile} accept="image/*"/>
                                                {errors.profile && (
                                                    <span className={`invalid-feedback`}>{errors.profile}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup>
                                                {image && (
                                                    <div className="avatars">
                                                        <div className="avatar mt-3">
                                                            <Media body className="img-50 b-r-15" src={image} alt=""/>
                                                        </div>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <Button color="primary" className={`btn-square`}
                                                        type={`submit`}>{props.t(`Submit`)}</Button>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(Profile);