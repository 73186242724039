import axiosInstance from "./AxiosInstance";
import Utils from "../utils";
const hostName = Utils.getDomain();

export function getReservationSetting(data){
    return axiosInstance.post(`${hostName}/reservation/setting-detail`, data);
}

export function actionModifyReservationSetting(data){
    return axiosInstance.post(`${hostName}/reservation/setting-update`, data);
}