import React, {useState, useLayoutEffect, useEffect} from "react";
import {Col} from 'reactstrap';
import {Sliders} from 'react-feather';
import {NavLink} from 'react-router-dom';

import logo from '../../assets/images/logo/logo.png';
import darkLogo from '../../assets/images/logo/logo_dark.png';

function LeftHeader() {
    const [sidebarToggle, setSidebarToggle] = useState(true)
    const width = useWindowSize();

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
            }

            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }


    useEffect(() => {

        var ignoreClick_On_Out_side_Element = document.getElementById('out_side_click');
        var ignoreClick_On_Main_Nav_Element = document.getElementById('sidebar-menu');
        document.addEventListener('click', function (event) {
            var isClickOutSideElement = ignoreClick_On_Out_side_Element.contains(event.target);
            var isClickMainNavElement = ignoreClick_On_Main_Nav_Element.contains(event.target);
            if (window.innerWidth <= 991 && !isClickOutSideElement && !isClickMainNavElement && document.getElementById("sidebar-wrapper")) {
                //Do something click is outside specified element
                document.querySelector(".page-header").className = "page-header close_icon";
                document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
            }
        });

    }, [width])


    const responsive_openCloseSidebar = (toggle) => {
        if (width <= 991) {
            document.querySelector(".page-header").className = "page-header";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
        } else {
            if (toggle) {
                setSidebarToggle(!toggle);
                document.querySelector(".page-header").className = "page-header close_icon";
                document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
                document.querySelector(".mega-menu-container").classList.remove("d-block")
            } else {
                setSidebarToggle(!toggle);
                document.querySelector(".page-header").className = "page-header";
                document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
            }
        }
    };

    return (
        <>
            <div className="header-logo-wrapper" id="out_side_click">
                <div className="logo-wrapper">
                    <NavLink to={`/dashboard`}>
                        <img className="img-fluid for-light" src={logo} alt=""/>
                        <img className="img-fluid for-dark" src={darkLogo}
                             alt=""/>
                    </NavLink>
                </div>
                <div className="toggle-sidebar" onClick={() => responsive_openCloseSidebar(sidebarToggle)}
                     style={window.innerWidth <= 991 ? {display: "block"} : {display: "none"}}>
                    <Sliders className="status_toggle middle sidebar-toggle" id="sidebar-toggle"/>
                </div>
            </div>
            <Col className="left-header horizontal-wrapper pl-0">
            </Col>
        </>
    );
}

export default LeftHeader;