import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getEmployeeList(data) {
    return axiosInstance.post(`${hostName}/employee/list`, data);
}

export function actionStoreEmployee(data) {
    return axiosInstance.post(`${hostName}/employee/create`, data);
}
export function actionUpdateEmployee(data) {
    return axiosInstance.post(`${hostName}/employee/update`, data);
}
export function actionDeleteEmployee(data) {
    return axiosInstance.post(`${hostName}/employee/delete`, data);
}
export function actionGetEmployee(data) {
    return axiosInstance.post(`${hostName}/employee/detail`, data);
}
