import {configureStore} from '@reduxjs/toolkit';

import {authReducer} from "./auth.slice";
import {companyReducer} from "./company.slice";
import {loaderReducer} from "./loader.slice";
import {permissionReducer} from "./permission.slice";

export * from './auth.slice';
export * from './company.slice';
export * from './loader.slice';
export * from './permission.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        company: companyReducer,
        load: loaderReducer,
        permission: permissionReducer
    }
});