import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getCategoryList(data) {
    return axiosInstance.post(`${hostName}/product-category/list`, data);
}

export function getAllCategory(data) {
    return axiosInstance.post(`${hostName}/product-category/list-all`,data);
}

export function actionStoreCategory(data) {
    return axiosInstance.post(`${hostName}/product-category/create`, data);
}

export function actionUpdateCategory(data) {
    return axiosInstance.post(`${hostName}/product-category/update`, data);
}

export function actionDeleteCategory(data) {
    return axiosInstance.post(`${hostName}/product-category/delete`, data);
}

export function actionGetCategory(data) {
    return axiosInstance.post(`${hostName}/product-category/detail`, data);
}
