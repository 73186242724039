import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import {NavLink, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";

import loginImg from "../../assets/images/login/2.jpg";
import loginLogo from "../../assets/images/logo/login.png";
import loginLogo1 from "../../assets/images/logo/logo_dark.png";

function AuthLayout() {
    const company = useSelector(x => x.company.value);
    const [logo, setLogo] = useState(loginLogo);
    const [logoDark, setLogoDark] = useState(loginLogo1);

    useEffect(() => {
        setLogo((company?.logo) ? company?.logo : loginLogo);
        setLogoDark((company?.dark_logo) ? company?.dark_logo : loginLogo1);
    }, [company]);

    return (
        <>
            <Container fluid={true}>
                <Row>
                    <Col xl="7" className="b-center bg-size" style={{
                        backgroundImage: `url(${loginImg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "block"
                    }}>
                        <img className="bg-img-cover bg-center" style={{display: "none"}} src={loginImg}
                             alt="looginpage"/>
                    </Col>
                    <Col xl="5" className="p-0">
                        <div className="login-card">
                            <div>
                                <div>
                                    <NavLink className="logo text-left" to={'/login'}>
                                        <img className="img-fluid for-light" src={logo} alt="looginpage"/>
                                        <img className="img-fluid for-dark" src={logoDark} alt="looginpage"/>
                                    </NavLink>
                                </div>
                                <div className="login-main login-tab">
                                    <Outlet/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AuthLayout;