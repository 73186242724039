import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {useSelector} from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup, Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import {NavLink} from "react-router-dom";
import Select from "react-select";
import Pagination from "react-js-pagination";

import useDocumentTitle from "../../utils/useDocumentTitle";
import configDb from "../../config";
import Breadcrumbs from "../partials/Breadcrumbs";
import {decryptData, encryptData} from "../../utils/crypto";
import {getAllBranchList} from "../../services/Branch";
import {actionDeletePaymentMethod, getPaymentList} from "../../services/PaymentMethod";
import {toast} from "react-toastify";
import Utils from "../../utils";

const tableLengthList = configDb.data.dataTableLength;
const applicationForList = configDb.data.paymentPortalList;

function PaymentMethod(props) {
    const allPermissionsList = useSelector(x => x.permission.value);
    const loginAuth = useSelector(x => x.auth.value);
    const [branchList, setBranchList] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [delModal, setDelModal] = useState(false);
    const [delPaymentMethod, setDelPaymentMethod] = useState('');
    const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState({
        limit: tableLengthList[0],
        page: 1,
        search: "",
        is_reload: false,
        branch_id: '',
    });

    useDocumentTitle(props.t(`PaymentMethod`));

    useEffect(() => {
        let data = encryptData({category_id: ''});
        getAllBranchList(data)
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (loginAuth?.main_branch) {
                        if (parseInt(response.data[i].id) === parseInt(loginAuth.main_branch)) {
                            list.push({
                                value: response.data[i].id,
                                label: response.data[i].display_name
                            });
                        }
                    } else {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].display_name
                        });
                    }
                }
                setBranchList(list);
            })
            .catch(err => {

            });
    }, [loginAuth]);

    useEffect(() => {
        let data = encryptData(params);
        setIsLoading(true);
        getPaymentList(data)
            .then(response => {
                response = decryptData(response.data);
                setTotalRecords(response.total);
                setPaymentList(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
            });
    }, [params]);

    const handleParams = (e, type) => {
        let paramsObj = {...params};
        if (type === 'length') {
            paramsObj.limit = e.target.value;
        } else if (type === 'application_for') {
            paramsObj.page = 1;
            paramsObj.application_for = (e?.value) ? e?.value : '';
        } else if (type === 'pagination') {
            paramsObj.page = e;
        } else if (type === 'branch') {
            paramsObj.page = 1;
            paramsObj.branch_id = (e?.value) ? e?.value : '';
        }
        setParams(paramsObj);
    }

    const showApplicationName = (applicationFor) => {
        let nameList = [];
        let split = applicationFor.split(',');
        for (let i = 0; i < split.length; i++) {
            let index = applicationForList.findIndex(x => x.value === parseInt(split[i]));
            if (index > -1) {
                nameList.push(applicationForList[index]['label']);
            }
        }
        return nameList.join(', ');
    };

    const onDeletePaymentMethod = (e, item) => {
        e.preventDefault();
        setDelPaymentMethod(item.uuid);
        setDelModal(true);
    };

    const onDeleteModalClose = (e) => {
        e.preventDefault();
        setDelPaymentMethod('');
        setDelModal(false);
    };

    const onDestroyPaymentMethod = (e) => {
        e.preventDefault();
        setIsDelButtonLoad(true);

        let data = encryptData({uuid: delPaymentMethod, language: getLanguage()});
        actionDeletePaymentMethod(data)
            .then(response => {
                let paramsObj = {...params};
                paramsObj.page = 1;
                paramsObj.is_reload = !paramsObj.is_reload;
                setParams(paramsObj);
                response = decryptData(response.data);
                toast.success(response.message);
                setDelPaymentMethod('');
                setDelModal(false);
                setIsDelButtonLoad(false);
            })
            .catch(err => {
                setIsDelButtonLoad(false);
                toast.error(Utils.getErrorMessage(err));
            });
    }

    return (
        <>
            <Breadcrumbs title={`PaymentMethod`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardHeader className={`py-4`}>
                                <h5>
                                    <span>{props.t(`ListPaymentMethod`)}</span>
                                    {allPermissionsList.indexOf('modify_payment_method') > -1 && (
                                        <NavLink to={`/payment-method/create`} role={`button`}
                                                 className={`btn btn-sm float-right btn-square btn-primary`}>{props.t(`AddPaymentMethod`)}</NavLink>
                                    )}
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={1}>
                                        <FormGroup>
                                            <select name="length" className="form-control" value={params.limit}
                                                    onChange={(e) => handleParams(e, 'length')}>
                                                {tableLengthList && tableLengthList.map((item, i) =>
                                                    <option value={item} key={i}>{item}</option>
                                                )}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className={`offset-sm-6`}>
                                        <FormGroup>
                                            <Select options={branchList} onChange={(e) => handleParams(e, 'branch')}
                                                    placeholder={props.t(`PleaseSelectBranch`)}
                                                    isClearable={true} name={`branch_id`}/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Select options={applicationForList}
                                                    onChange={(e) => handleParams(e, 'application_for')}
                                                    placeholder={props.t(`SelectApplicationFor`)}
                                                    isClearable={true} name={`application_for`}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <Table className={`table-bordered ${isLoading ? `loading` : ``}`}>
                                        <thead>
                                        <tr>
                                            <th scope={`col`}>#</th>
                                            <th scope={`col`}>{props.t(`PaymentName`)}</th>
                                            <th scope={`col`}>{props.t(`BranchName`)}</th>
                                            <th scope={`col`}>{props.t(`ApplicationFor`)}</th>
                                            {(allPermissionsList.indexOf('modify_payment_method') > -1 || allPermissionsList.indexOf('delete_payment_method') > -1) && (
                                                <th scope={`col`}>{props.t(`Action`)}</th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {paymentList && paymentList.map((item, i) =>
                                            <tr key={i}>
                                                <td>{item.index}</td>
                                                <td>{item.entity_name}</td>
                                                <td>{item.branch_name}</td>
                                                <td>{showApplicationName(item.application_for)}</td>
                                                {(allPermissionsList.indexOf('modify_payment_method') > -1 || allPermissionsList.indexOf('delete_payment_method') > -1) && (
                                                    <td>
                                                        {allPermissionsList.indexOf('modify_payment_method') > -1 && (
                                                            <NavLink to={`/payment-method/${item.uuid}/edit`}
                                                                     role={`button`}
                                                                     className={`btn btn-outline-primary btn-square btn-sm mr-1`}>{props.t(`Edit`)}</NavLink>
                                                        )}
                                                        {allPermissionsList.indexOf('delete_payment_method') > -1 && (
                                                            <Button outline color={`danger`} type={`button`}
                                                                    onClick={(e) => onDeletePaymentMethod(e, item)}
                                                                    className={`btn-sm btn-square`}>{props.t(`Delete`)}</Button>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                        {paymentList && paymentList.length === 0 && (
                                            <tr>
                                                <td colSpan={(allPermissionsList.indexOf('modify_payment_method') > -1 || allPermissionsList.indexOf('delete_payment_method') > -1) ? 5 : 4}
                                                    className={`text-center`}>{props.t(`RecordNotFound`)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                                <Row>
                                    <Col sm={12} className={`mt-3`}>
                                        <div className={`float-right`}>
                                            <Pagination
                                                activePage={params.page}
                                                itemsCountPerPage={parseInt(params.limit)}
                                                totalItemsCount={totalRecords}
                                                pageRangeDisplayed={5}
                                                itemClass={`page-item`}
                                                linkClass={`page-link`}
                                                onChange={(e) => handleParams(e, 'pagination')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={delModal}>
                <ModalHeader>{props.t(`DeletePaymentMethod`)}</ModalHeader>
                <ModalBody>
                    <p className={`mb-0`}>{props.t(`DeleteWarn`)}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={`button`} disabled={isDelButtonLoad} outline
                            onClick={onDeleteModalClose} className={`btn-square`}>{props.t(`Close`)}</Button>
                    {!isDelButtonLoad && (
                        <Button color="secondary" type={`button`} className={`btn-square`}
                                onClick={onDestroyPaymentMethod}>{props.t(`Delete`)}</Button>
                    )}
                    {isDelButtonLoad && (
                        <Button color="secondary" type={`button`} className={`btn-square`} disabled={true}>
                            <i className="fa fa-circle-o-notch fa-spin"/> {props.t(`Loading`)}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default translate(PaymentMethod);