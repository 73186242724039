import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";

import useDocumentTitle from "../../utils/useDocumentTitle";
import Breadcrumbs from "../partials/Breadcrumbs";
import {decryptData, encryptData} from "../../utils/crypto";
import {actionGetRolePermission, actionModifyRolePermission} from "../../services/Role";
import Utils from "../../utils";
import {loaderActions} from "../../store";

function Permission(props) {
    const {uuid} = useParams();
    const navigate = useNavigate();
    const [roleName, setRoleName] = useState('');
    const [permissionList, setPermissionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    useDocumentTitle(props.t('Permission'));

    useEffect(() => {
        dispatch(loaderActions.loader(true));
        let data = encryptData({language: getLanguage(), uuid: uuid});
        actionGetRolePermission(data)
            .then(response => {
                response = decryptData(response.data);
                setRoleName(response.data.name);
                setPermissionList(response.data.permission);
                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/roles');
            });
    }, [uuid, navigate, dispatch]);

    const handlePermissionItem = (e, index, idx) => {
        let list = [...permissionList];
        list[index]['list'][idx]['is_checked'] = e.target.checked;
        setPermissionList(list);
    };

    const modifyPermission = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let list = [...permissionList];
        let data = encryptData({uuid: uuid, permissions: list, language: getLanguage()});
        actionModifyRolePermission(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                toast.error(Utils.getErrorMessage(err));
            });
    };

    return (
        <>
            <Breadcrumbs parentLink={'/roles'} parent={`Roles`} title={`Permission`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardHeader className={`py-4`}>
                                <h5>
                                    <span>{roleName + ` - ` + props.t(`Permission`)}</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                {permissionList.map((item, i) => (
                                    <fieldset key={i} className={`pb-3 pt-2 mb-4`}>
                                        <legend>{item.name}</legend>
                                        <Row>
                                            {item.list.map((permission, p) => (
                                                <Col sm={2} key={p} className={`mb-2`}>
                                                    <FormGroup check>
                                                        <div className="checkbox checkbox-primary">
                                                            <Input type="checkbox" checked={permission.is_checked}
                                                                   onChange={(e) => handlePermissionItem(e, i, p)}
                                                                   id={`permission_${permission.permission_id}`}/>
                                                            <Label for={`permission_${permission.permission_id}`}
                                                                   check>{permission.display}</Label>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            ))}
                                        </Row>
                                    </fieldset>
                                ))}
                                <Row>
                                    <Col sm={12} className={`text-right`}>
                                        {!isLoading && (
                                            <>
                                                <NavLink to={`/roles`} role={`button`} end
                                                         className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                <Button color="primary" onClick={modifyPermission}
                                                        type={`button`}
                                                        className={`btn-square`}>{props.t(`Submit`)}</Button>
                                            </>
                                        )}
                                        {isLoading && (
                                            <Button color="primary" type={`button`} className={`btn-square`}
                                                    disabled={true}>
                                                <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(Permission);