import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getOrderList(data) {
    return axiosInstance.post(`${hostName}/orders/list`, data);
}
export function getOrderDetails(data) {
    return axiosInstance.post(`${hostName}/orders/detail`, data);
}
export function updateOrderStatus(data) {
    return axiosInstance.post(`${hostName}/orders/update`, data);
}
export function getAvailableTables(data) {
    return axiosInstance.post(`${hostName}/orders/available-table`, data);
}
