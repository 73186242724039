import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function login(data) {
    return axiosInstance.post(`${hostName}/login`, data);
}

export function forgotPassword(data) {
    return axiosInstance.post(`${hostName}/forgot-password`, data);
}

export function resetPassword(data) {
    return axiosInstance.post(`${hostName}/reset-password`, data);
}

export function actionResetPasswordCheckToken(data) {
    return axiosInstance.post(`${hostName}/reset-password/check-token`, data);
}

export function checkDomain(data) {
    return axiosInstance.post(`check-domain`, data);
}
