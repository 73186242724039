import React, {useState} from "react";
import {translate} from "react-switch-lang";

import Breadcrumbs from "../partials/Breadcrumbs";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import configDb from "../../config";
import {actionStoreUnits} from "../../services/Units";
import {decryptData, encryptData} from "../../utils/crypto";
import {toast} from "react-toastify";
import Utils from "../../utils";
import {NavLink, useNavigate} from "react-router-dom";

const statusList = configDb.data.statusList;

function CreateUnits(props) {
    const navigate = useNavigate();
    useDocumentTitle(props.t(`CreateUnits`))
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const errorsObj = {
        name: "",
        status_id: ""
    };
    const [errors, setErrors] = useState(errorsObj);
    const onCreate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {errorsObj};
        if (!name) {
            errorObj.name = props.t('RequiredUnitName');
            error = true;
        }
        setErrors(errorObj);
        if (error) return;
        setIsLoading(true)
        let obj = encryptData({
            name: name,
            description: description,
            status_id: statusId
        });
        actionStoreUnits(obj)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/units');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }
    return (
        <>
            <Breadcrumbs parentLink={'/units'} parent={`Units`} title={`CreateUnits`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onCreate}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`UnitName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`code`}
                                                       value={name} onChange={(e) => setName(e.target.value)}
                                                       placeholder={props.t(`EnterUnitName`)} maxLength={75}/>
                                                {errors.name && (
                                                    <span className={`invalid-feedback`}>{errors.name}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input
                                                    value={statusId}
                                                    onChange={(e) => setStatusId(e.target.value)}
                                                    type="select">
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                                {errors.status_id && (
                                                    <span className={`invalid-feedback`}>{errors.status_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Description`)}</Label>
                                                <Input className="form-control" type="textarea" name={`description`}
                                                       value={description} rows={5}
                                                       onChange={(e) => setDescription(e.target.value)}
                                                       placeholder={props.t(`EnterDescription`)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={`/units`} role={`button`} end
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} className={`btn-square`}
                                                        disabled={true}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(CreateUnits);