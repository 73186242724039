import React from "react";
import {Container, Row, Col, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Home} from 'react-feather';
import {NavLink} from 'react-router-dom';
import {translate} from "react-switch-lang";

function Breadcrumbs(props) {
    return (
        <Container fluid={true}>
            <div className="page-title">
                <Row>
                    <Col xs="6">
                        <h3>{props.subtitle && (
                            props.subtitle +' '
                        )}
                            {props.t(props.title)}</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem><NavLink to={`/dashboard`}><Home/></NavLink></BreadcrumbItem>
                            {props.parentLink && (
                                <BreadcrumbItem>
                                    <NavLink to={props.parentLink}>{props.t(props.parent)}</NavLink>
                                </BreadcrumbItem>
                            )}
                            {props.childLink && (
                                <BreadcrumbItem>
                                    <NavLink to={props.childLink}>{props.t(props.child)}</NavLink>
                                </BreadcrumbItem>
                            )}
                            {props.title && (<BreadcrumbItem active>{props.t(props.title)}</BreadcrumbItem>)}
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default translate(Breadcrumbs);