import React, {useEffect, useState} from "react";
import {LogIn, Minimize, User} from 'react-feather';
import {useNavigate} from 'react-router-dom';
import {setTranslations, setDefaultLanguage, setLanguageCookie, setLanguage, translate} from "react-switch-lang";
import {useSelector, useDispatch} from 'react-redux';

import {authActions, permissionActions} from "../../store";
import man from '../../assets/images/user.png';
import configDb from "../../config";

import en from "../../assets/i18n/en.json";
import du from "../../assets/i18n/du.json";

setTranslations({en, du});
setDefaultLanguage(configDb.data.settings.language);
setLanguageCookie();

let languageList = configDb.data.languages;

function RightHeader(props) {
    const dispatch = useDispatch();
    const auth = useSelector(x => x.auth.value);
    const navigate = useNavigate();
    const [profile, setProfile] = useState(man);
    const [name, setName] = useState('Guest');
    const [langDropDown, setLangDropDown] = useState(false);
    const [moonlight, setMoonlight] = useState(false);
    const [langSelected, setLangSelected] = useState(configDb.data.settings.language);
    const [langIcon, setLangIcon] = useState(configDb.data.settings.language_icon);

    const handleSetLanguage = (data) => {

        let layoutVersion = localStorage.getItem("layout_version");
        layoutVersion = (layoutVersion) ? layoutVersion : '';
        document.body.className = `${layoutVersion} ${data.dir}`
        document.documentElement.dir = data.dir;
        localStorage.setItem('layout_type', data.dir);

        setLangSelected(data.lang);
        setLangIcon(data.icon);
        setLangDropDown(false);
        setLanguage(data.lang);
        localStorage.setItem('theme_lang', data.lang);
    };

    useEffect(() => {
        setProfile(auth.profile);
        setName(auth.full_name);
        let layoutVersion = localStorage.getItem("layout_version");
        layoutVersion = (layoutVersion) ? layoutVersion : '';

        let layoutType = localStorage.getItem("layout_type");
        layoutType = (layoutType) ? layoutType : '';
        if (layoutVersion === "dark-only") {
            setMoonlight(true);
        }
        document.body.className = `${layoutVersion} ${layoutType}`;
        if (layoutType === "rtl") {
            document.documentElement.dir = 'rtl';
        }

        window.process = {
            ...window.process,
        };

        if (localStorage.getItem('theme_lang')) {
            let language = localStorage.getItem('theme_lang');
            let index = languageList.findIndex(x => x.lang === language);
            if (index > -1) {
                setLangSelected(language);
                setLanguage(language);
                setLangIcon(languageList[index]['icon']);
            }
        }
    }, [auth]);

    const onLogout = () => {
        dispatch(authActions.logout());
        dispatch(permissionActions.permission([]));
        navigate(`/`);
    }
    const onProfile = () => {
        navigate(`/profile`);
    }

    //full screen function
    function goFull(e) {
        e.preventDefault();
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    const LanguageSelection = (language) => {
        if (language) {
            setLangDropDown(!language);
        } else {
            setLangDropDown(!language);
        }
    }

    const MoonlightToggle = (light) => {
        if (light) {
            setMoonlight(!light)
            document.body.className = "light";
            localStorage.setItem('layout_version', 'light');
        } else {
            setMoonlight(!light)
            document.body.className = "dark-only";
            localStorage.setItem('layout_version', 'dark-only');
        }
    }

    return (
        <div className="nav-right col-8 pull-right right-header p-0">
            <ul className="nav-menus">
                <li className="language-nav">
                    <div className={`translate_wrapper ${langDropDown ? 'active' : ''}`}>
                        <div className="current_lang">
                            <div className="lang" onClick={() => LanguageSelection(langDropDown)}>
                                <i className={`flag-icon flag-icon-${langIcon}`}></i>
                                <span className="lang-txt">{langSelected}</span>
                            </div>
                        </div>
                        <div className={`more_lang ${langDropDown ? 'active' : ''}`}>
                            {languageList.map((item, i) =>
                                <div key={i} className="lang" onClick={() => handleSetLanguage(item)}>
                                    <i className={`flag-icon flag-icon-${item.icon}`}></i>
                                    <span className="lang-txt">{item.name}
                                        {item.sub_name && (<span>{item.sub_name}</span>)}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
                        <i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i>
                    </div>
                </li>
                <li className="maximize">
                    <a className="text-dark" href="#javascript" onClick={goFull}><Minimize/></a>
                </li>
                <li className="profile-nav onhover-dropdown p-0">
                    <div className="media profile-media">
                        <img className="b-r-10" src={profile} alt=""/>
                        <div className="media-body mt-2">
                            <span>{name} <i className="middle fa fa-angle-down ml-1"></i></span>
                        </div>
                    </div>
                    <ul className="profile-dropdown onhover-show-div">
                        <li onClick={onProfile}><User/><span>{props.t(`Profile`)} </span></li>
                        {auth?.current_branch_name && (
                            <li>
                                <div>Current Branch :</div>
                                <div>{auth.current_branch_name}</div>
                            </li>
                        )}
                        <li onClick={onLogout}><LogIn/><span>{props.t(`LogOut`)}</span></li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}

export default translate(RightHeader);