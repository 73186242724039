import React, {useState, useEffect} from "react";
import {translate, getLanguage} from "react-switch-lang";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Media, Row} from "reactstrap";
import validator from 'validator';
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import TagsInput from "react-tagsinput";

import Breadcrumbs from "../partials/Breadcrumbs";
import useDocumentTitle from "../../utils/useDocumentTitle";
import Utils from "../../utils";
import configDb from "../../config";
import {updateCompanyProfile} from "../../services/Users";
import {decryptData} from "../../utils/crypto";
import {companyActions} from "../../store";
import {getTaxAllList} from "../../services/Tax";

let taxTypeList = configDb.data.taxList;
let kitchenOrderFormatList = configDb.data.kitchenOrderFormat;
let businessModeList = configDb.data.businessModeList;
let yesNoList = configDb.data.yesNoList;

function Settings(props) {
    const dispatch = useDispatch();
    const company = useSelector(x => x.company.value);
    const [businessCategoryName, setBusinessCategoryName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [countryName, setCountryName] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [email, setEmail] = useState('');
    const [taxList, setTaxList] = useState([]);
    const [defaultTax, setDefaultTax] = useState('');
    const [taxType, setTaxType] = useState('');
    const [timezoneName, setTimezoneName] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [whatsappUrl, setWhatsappUrl] = useState('');
    const [businessMode, setBusinessMode] = useState(1);
    const [enableOrderNumber, setEnableOrderNumber] = useState(0);
    const [enablePax, setEnablePax] = useState(1);
    const [maximumPax, setMaximumPax] = useState(1);
    const [kitchenOrderFormat, setKitchenOrderFormat] = useState(0);
    const [kitchenPrinter, setKitchenPrinter] = useState([]);
    const [kitchenPrinterList, setKitchenPrinterList] = useState([]);
    const [defaultKitchenPrinter, setDefaultKitchenPrinter] = useState('');
    const [logo, setLogo] = useState('');
    const [logoFile, setLogoFile] = useState([]);
    const [keyFile, setKeyFile] = useState(Date.now);
    const [darkLogo, setDarkLogo] = useState('');
    const [darkLogoFile, setDarkLogoFile] = useState([]);
    const [keyDarkLogoFile, setKeyDarkLogoFile] = useState(Date.now);
    const [favicon, setFavicon] = useState('');
    const [faviconFile, setFaviconFile] = useState([]);
    const [tcFile, setTcFile] = useState([]);
    const [termsCondition, setTermsCondition] = useState('');
    const [keyFaviconFile, setKeyFaviconFile] = useState(Date.now);
    const [keyTcFile, setKeyTcFile] = useState(Date.now);
    const [isLoading, setIsLoading] = useState(false);

    const errorsObj = {
        business_name: "",
        contact_number: "",
        dark_logo: "",
        default_tax: "",
        email: "",
        favicon: "",
        logo: "",
        tax_type: "",
        whatsapp_url: "",
        whatsapp_number: "",
        maximum_pax: ""
    };
    const [errors, setErrors] = useState(errorsObj);

    useEffect(() => {
        getTaxAllList()
            .then(response => {
                response = decryptData(response.data);
                setTaxList(response.data);
            })
            .catch(err => {

            });
    }, []);

    useEffect(function () {
        setBusinessCategoryName(company?.business_category_name);
        setLogo(company?.logo);
        setDarkLogo(company?.dark_logo);
        setFavicon(company?.favicon);
        setTermsCondition(company?.term_condition);
        setBusinessName(company?.business_name);
        setContactNumber((company?.contact_number) ? company.contact_number : '');
        setCountryName(company?.country_name);
        setCurrencySymbol(company?.currency_symbol ? company.currency_symbol : '');
        setEmail(company?.email);
        setDefaultTax((company?.default_tax) ? company.default_tax : '');
        setTaxType((company?.tax_type) ? company.tax_type : '');
        setTimezoneName(company?.timezone_name);
        setWhatsappNumber((company?.whatsapp_number) ? company.whatsapp_number : '');
        setWhatsappUrl((company?.whatsapp_url) ? company.whatsapp_url : '');
        setMaximumPax((company?.maximum_pax) ? company.maximum_pax : 1);

        if (company?.business_category_name && company.business_category_name === configDb.data.restaurant) {
            setBusinessMode((company?.business_mode) ? company.business_mode : 1);
            setEnableOrderNumber((company?.enable_order_number) ? company.enable_order_number : 0);
            setKitchenOrderFormat((company?.kitchen_order_format) ? company.kitchen_order_format : 0);
            if (company?.kitchen_printer) {
                let printList = company?.kitchen_printer.split(',');
                setKitchenPrinterList(printList);
                setKitchenPrinter(printList);
            }
            setDefaultKitchenPrinter((company?.default_kitchen_printer) ? company.default_kitchen_printer : '');
        }
    }, [company]);
    useDocumentTitle(props.t('CompanyProfile'));

    const handleLogoFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setLogo(URL.createObjectURL(e.target.files[0]));
            setLogoFile([e.target.files[0]]);
        } else {
            let data = Utils.loginCompanyDetail();
            setLogo(data.logo);
            setLogoFile([]);
        }
    };
    const handleDarkLogoFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setDarkLogo(URL.createObjectURL(e.target.files[0]));
            setDarkLogoFile([e.target.files[0]]);
        } else {
            let data = Utils.loginCompanyDetail();
            setDarkLogo(data.dark_logo);
            setDarkLogoFile([]);
        }
    };
    const handleFaviconFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setFavicon(URL.createObjectURL(e.target.files[0]));
            setFaviconFile([e.target.files[0]]);
        } else {
            let data = Utils.loginCompanyDetail();
            setFavicon(data.favicon);
            setFaviconFile([]);
        }
    };

    const handleKitchenPrinter = (tags) => {
        let list = [...kitchenPrinter];
        let tag = tags[tags.length - 1];
        if (tag && list.indexOf(tag.trim()) > -1 && tags.length > list.length) {
            toast.error('Tag name already exist.');
            return false;
        } else {
            setKitchenPrinter(tags);
            setKitchenPrinterList(tags);
            if (tags.findIndex(x => x === defaultKitchenPrinter) === -1) {
                setDefaultKitchenPrinter('');
            }
        }
    };

    const handleTcFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setTcFile([e.target.files[0]]);
        }
    };

    const onRemoveFile = (e) => {
        e.preventDefault();
        setTcFile([]);
        setTermsCondition('');
        setKeyTcFile(Date.now);
    };

    const onProfile = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!businessName) {
            errorObj.business_name = props.t('RequiredBusinessName');
            error = true;
        }
        if (!defaultTax) {
            errorObj.default_tax = props.t('RequiredDefaultTax');
            error = true;
        }
        if (!taxType) {
            errorObj.tax_type = props.t('RequiredTaxType');
            error = true;
        }
        if (!email) {
            errorObj.email = props.t('RequiredEmail');
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = props.t('InValidEmail');
            error = true;
        }
        if (!contactNumber) {
            errorObj.contact_number = props.t('RequiredContactNumber');
            error = true;
        } else if (!validator.isMobilePhone(contactNumber)) {
            errorObj.contact_number = props.t('InValidNumber');
            error = true;
        } else if (contactNumber.length !== 10) {
            errorObj.contact_number = props.t('InValidNumber');
            error = true;
        }
        if (logoFile.length > 0) {
            if (!logoFile[0].name.match(configDb.data.imageExt)) {
                errorObj.logo = props.t('InvalidImage');
                error = true;
            }
        }
        if (darkLogoFile.length > 0) {
            if (!darkLogoFile[0].name.match(configDb.data.imageExt)) {
                errorObj.dark_logo = props.t('InvalidImage');
                error = true;
            }
        }
        if (faviconFile.length > 0) {
            if (!faviconFile[0].name.match(configDb.data.imageExt)) {
                errorObj.favicon = props.t('InvalidImage');
                error = true;
            }
        }
        if (tcFile.length > 0) {
            if (!tcFile[0].name.match(configDb.data.filePDFExt)) {
                errorObj.term_condition = props.t('InvalidPDFFile');
                error = true;
            }
        }
        if (whatsappUrl && !validator.isURL(whatsappUrl)) {
            errorObj.whatsapp_url = props.t('InValidURL');
            error = true;
        }
        if (whatsappNumber && !validator.isMobilePhone(whatsappNumber)) {
            errorObj.whatsapp_number = props.t('InValidNumber');
            error = true;
        } else if (whatsappNumber && whatsappNumber.length !== 10) {
            errorObj.whatsapp_number = props.t('InValidNumber');
            error = true;
        }
        if (parseInt(enablePax) === 1 && company?.business_category_name && company.business_category_name === configDb.data.restaurant) {
            if (!maximumPax) {
                errorObj.maximum_pax = props.t('RequiredMaximumPax');
                error = true;
            } else if (parseInt(maximumPax) < 1) {
                errorObj.maximum_pax = props.t('MaxLength1');
                error = true;
            }
        }

        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('language', getLanguage());
        formData.append('uuid', company.uuid);
        formData.append('business_name', businessName);
        formData.append('email', email);
        formData.append('contact_number', contactNumber);
        formData.append('default_tax', defaultTax);
        formData.append('tax_type', taxType);
        formData.append('whatsapp_number', whatsappNumber);
        formData.append('whatsapp_url', whatsappUrl);

        if (company?.business_category_name && company.business_category_name === configDb.data.restaurant) {
            formData.append('enable_pax', enablePax);
            formData.append('maximum_pax', maximumPax);
            formData.append('business_mode', businessMode);
            formData.append('kitchen_order_format', kitchenOrderFormat);
            formData.append('kitchen_printer', kitchenPrinter.join());
            formData.append('default_kitchen_printer', defaultKitchenPrinter);
            if (parseInt(businessMode) === 3) {
                formData.append('enable_order_number', enableOrderNumber);
            }
        }

        if (logoFile.length > 0) {
            formData.append('logo', logoFile[0]);
        }
        if (darkLogoFile.length > 0) {
            formData.append('dark_logo', darkLogoFile[0]);
        }
        if (faviconFile.length > 0) {
            formData.append('favicon', faviconFile[0]);
        }
        if (tcFile.length > 0) {
            formData.append('term_condition', tcFile[0]);
        }
        updateCompanyProfile(formData)
            .then(response => {
                let data = decryptData(response.data);
                toast.success(data.message);
                dispatch(companyActions.login(data.data));
                Utils.setCompanyDetail(response.data);
                setIsLoading(false);
                setKeyFile(Date.now);
                setKeyFaviconFile(Date.now);
                setKeyDarkLogoFile(Date.now);
                setKeyTcFile(Date.now);
            })
            .catch(err => {
                dispatch(companyActions.logout());
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    };

    return (
        <>
            <Breadcrumbs title={`CompanyProfile`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onProfile} encType={`multipart/form-data`}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Category`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`business_category_name`} disabled
                                                       value={businessCategoryName}
                                                       onChange={(e) => console.log('business_category_name')}
                                                       placeholder={props.t(`EnterCategory`)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`CountryName`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`country_name`} disabled
                                                       value={countryName}
                                                       onChange={(e) => console.log('country_name')}
                                                       placeholder={props.t(`EnterCountryName`)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`TimeZone`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`timezone_name`} disabled
                                                       value={timezoneName}
                                                       onChange={(e) => console.log('timezone_name')}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`CurrencySymbol`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`currency_symbol`} disabled
                                                       value={currencySymbol}
                                                       onChange={(e) => console.log('currency_symbol')}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`BusinessName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text"
                                                       name={`business_name`}
                                                       value={businessName}
                                                       onChange={(e) => setBusinessName(e.target.value)}
                                                       placeholder={props.t(`EnterBusinessName`)}/>
                                                {errors.business_name && (
                                                    <span className={`invalid-feedback`}>{errors.business_name}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`EmailAddress`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text"
                                                       name={`email`} value={email}
                                                       onChange={(e) => setEmail(e.target.value)}
                                                       placeholder={props.t(`EnterEmailAddress`)}/>
                                                {errors.email && (
                                                    <span className={`invalid-feedback`}>{errors.email}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ContactNumber`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="number" name={`contact_number`}
                                                       min={0} value={contactNumber}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                       onChange={(e) => setContactNumber(e.target.value)}
                                                       placeholder={props.t(`EnterContactNumber`)} maxLength={10}/>
                                                {errors.contact_number && (
                                                    <span
                                                        className={`invalid-feedback`}>{errors.contact_number}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`defaultTax`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input type="select" name={`default_tax`} value={defaultTax}
                                                       onChange={(e) => setDefaultTax(e.target.value)}>
                                                    <option value="">Select Tax</option>
                                                    {taxList.map((item, i) =>
                                                        <option key={i}
                                                                value={item.id}>{item.name} ({item.value}%)</option>
                                                    )}
                                                </Input>
                                                {errors.default_tax && (
                                                    <span className={`invalid-feedback`}>{errors.default_tax}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`taxType`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input name="select" type="select" value={taxType}
                                                       onChange={(e) => setTaxType(e.target.value)}>
                                                    {taxTypeList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}
                                                </Input>
                                                {errors.tax_type && (
                                                    <span className={`invalid-feedback`}>{errors.tax_type}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`whatsappNumber`)}</Label>
                                                <Input className="form-control" type="number" name={`whatsapp_number`}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                       value={whatsappNumber} maxLength={10}
                                                       onChange={(e) => setWhatsappNumber(e.target.value)}
                                                       placeholder={props.t(`EnterWhatsappNumber`)}/>
                                                {errors.whatsapp_number && (
                                                    <span className={`invalid-feedback`}>{errors.whatsapp_number}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`whatsappUrl`)}</Label>
                                                <Input className="form-control" type="text" name={`whatsapp_url`}
                                                       value={whatsappUrl}
                                                       onChange={(e) => setWhatsappUrl(e.target.value)}
                                                       placeholder={props.t(`EnterWhatsappUrl`)}/>
                                                {errors.whatsapp_url && (
                                                    <span className={`invalid-feedback`}>{errors.whatsapp_url}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        {(company?.business_category_name && company.business_category_name === configDb.data.restaurant) && (
                                            <>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="col-form-label">{props.t(`BusinessMode`)}</Label>
                                                        <Input className="form-control" type="select"
                                                               name={`business_mode`} value={businessMode}
                                                               onChange={(e) => setBusinessMode(e.target.value)}>
                                                            {businessModeList.map((item, i) =>
                                                                <option value={item.id} key={i}>{item.name}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                {parseInt(businessMode) === 3 && (
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Label
                                                                className="col-form-label">{props.t(`EnableOrderNumber`)}</Label>
                                                            <Input className={`form-control`} value={enableOrderNumber}
                                                                   onChange={(e) => setEnableOrderNumber(e.target.value)}
                                                                   name="enable_order_number" type="select">
                                                                {yesNoList.map((item, i) =>
                                                                    <option value={item.id} key={i}>{item.name}</option>
                                                                )}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="col-form-label">{props.t(`EnablePax`)}</Label>
                                                        <Input className={`form-control`} value={enablePax}
                                                               onChange={(e) => setEnablePax(e.target.value)}
                                                               name="enable_pax" type="select">
                                                            {yesNoList.map((item, i) =>
                                                                <option value={item.id} key={i}>{item.name}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                {parseInt(enablePax) === 1 && (
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Label
                                                                className="col-form-label">{props.t(`MaximumPax`)}</Label>
                                                            <Input className={`form-control`} value={maximumPax}
                                                                   onChange={(e) => setMaximumPax(e.target.value)}
                                                                   name="maximum_pax" type="number"/>
                                                            {errors.maximum_pax && (
                                                                <span
                                                                    className={`invalid-feedback`}>{errors.maximum_pax}</span>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="col-form-label">{props.t(`KitchenOrderFormat`)}</Label>
                                                        <Input className={`form-control`} value={kitchenOrderFormat}
                                                               onChange={(e) => setKitchenOrderFormat(e.target.value)}
                                                               name="kitchen_order_format" type="select">
                                                            {kitchenOrderFormatList.map((item, i) =>
                                                                <option value={item.id} key={i}>{item.name}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="col-form-label">{props.t(`KitchenPrinter`)}</Label>
                                                        <TagsInput value={kitchenPrinter}
                                                                   inputProps={{placeholder: ""}}
                                                                   onChange={handleKitchenPrinter}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="col-form-label">{props.t(`DefaultKitchenPrinter`)}</Label>
                                                        <Input className={`form-control`} value={defaultKitchenPrinter}
                                                               onChange={(e) => setDefaultKitchenPrinter(e.target.value)}
                                                               name="select" type="select">
                                                            <option value="">Select printer</option>
                                                            {kitchenPrinterList.map((item, i) =>
                                                                <option value={item} key={i}>{item}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Logo`)}</Label>
                                                <Input className="form-control" type="file" onChange={handleLogoFile}
                                                       key={keyFile} accept="image/*"/>
                                                {errors.logo && (
                                                    <span className={`invalid-feedback`}>{errors.logo}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup>
                                                {logo && (
                                                    <div className="avatars">
                                                        <div className="avatar mt-3">
                                                            <Media body className="img-50 b-r-15" src={logo} alt=""/>
                                                        </div>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`DarkLogo`)}</Label>
                                                <Input className="form-control" type="file"
                                                       onChange={handleDarkLogoFile}
                                                       key={keyDarkLogoFile} accept="image/*"/>
                                                {errors.dark_logo && (
                                                    <span className={`invalid-feedback`}>{errors.dark_logo}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup>
                                                {darkLogo && (
                                                    <div className="avatars">
                                                        <div className="avatar mt-3">
                                                            <Media body className="img-50 b-r-15" src={darkLogo}
                                                                   alt=""/>
                                                        </div>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Favicon`)}</Label>
                                                <Input className="form-control" type="file" onChange={handleFaviconFile}
                                                       key={keyFaviconFile} accept="image/*"/>
                                                {errors.favicon && (
                                                    <span className={`invalid-feedback`}>{errors.favicon}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup>
                                                {favicon && (
                                                    <div className="avatars">
                                                        <div className="avatar mt-3">
                                                            <Media body className="img-50 b-r-15" src={favicon}
                                                                   alt=""/>
                                                        </div>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`TermsConditions`)}</Label>
                                                <Input className="form-control" type="file" onChange={handleTcFile}
                                                       key={keyTcFile} accept="application/pdf"/>
                                                {errors.term_condition && (
                                                    <span className={`invalid-feedback`}>{errors.term_condition}</span>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        {termsCondition && (
                                            <>
                                                <Col sm={1}>
                                                    <Label className="col-form-label"></Label>
                                                    <div className="mt-3">
                                                        <a className={`btn btn-primary btn-sm`}
                                                           target={`_blank`} href={termsCondition}>View</a>
                                                    </div>
                                                </Col>
                                                <Col sm={1}>
                                                    <Label className="col-form-label"></Label>
                                                    <div className="mt-3">
                                                        <Button type={`button`} className={`btn btn-danger btn-sm`}
                                                                onClick={onRemoveFile}>Remove</Button>
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <Button color="primary" type={`submit`}
                                                        className={`btn-square`}>{props.t(`Submit`)}</Button>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(Settings);