import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getCustomerList(data) {
    return axiosInstance.post(`${hostName}/customer/list`, data);
}

export function getCustomerDetails(data) {
    return axiosInstance.post(`${hostName}/customer/detail`, data);
}

export function actionStoreCustomer(data) {
    return axiosInstance.post(`${hostName}/customer/create`, data);
}

export function actionUpdateCustomer(data) {
    return axiosInstance.post(`${hostName}/customer/update`, data);
}

export function actionDeleteCustomer(data) {
    return axiosInstance.post(`${hostName}/customer/delete`, data);
}