import axiosInstance from "./AxiosInstance";
import Utils from "../utils";
const hostName = Utils.getDomain();

export function getProductList(data){
    return axiosInstance.post(`${hostName}/products/list`, data);
}
export function actionStoreProduct(data) {
    return axiosInstance.post(`${hostName}/products/create`, data);
}
export function actionUpdateProduct(data) {
    return axiosInstance.post(`${hostName}/products/update`, data);
}
export function actionDeleteProduct(data) {
    return axiosInstance.post(`${hostName}/products/delete`, data);
}
export function getDetailProduct(data) {
    return axiosInstance.post(`${hostName}/products/detail`, data);
}