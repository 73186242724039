import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getBranchList(data) {
    return axiosInstance.post(`${hostName}/branch/list`, data);
}

export function getAllBranchList(data) {
    return axiosInstance.post(`${hostName}/branch/list-all`, data);
}

export function actionStoreBranch(data) {
    return axiosInstance.post(`${hostName}/branch/create`, data);
}

export function actionUpdateBranch(data) {
    return axiosInstance.post(`${hostName}/branch/update`, data);
}

export function actionGetBranch(data) {
    return axiosInstance.post(`${hostName}/branch/detail`, data);
}

export function actionDeleteBranch(data) {
    return axiosInstance.post(`${hostName}/branch/delete`, data);
}

export function getCheckBranch(data) {
    return axiosInstance.post(`${hostName}/branch/check`, data);
}

export function getBranchTables(data) {
    return axiosInstance.post(`${hostName}/branch-table/list`, data);
}

export function actionUpdateTables(data) {
    return axiosInstance.post(`${hostName}/branch-table/update`, data);
}

export function actionStoreTables(data) {
    return axiosInstance.post(`${hostName}/branch-table/create`, data);
}

export function actionDeleteTables(data) {
    return axiosInstance.post(`${hostName}/branch-table/delete`, data);
}

export function getAllTables(data) {
    return axiosInstance.post(`${hostName}/branch-table/list-all`, data);
}

export function getUpdateTableLayout(data) {
    return axiosInstance.post(`${hostName}/branch-table/layout-update`, data);
}

export function actionUpdateBranchQrMenu(data) {
    return axiosInstance.post(`${hostName}/branch/qr-menu-update`, data);
}
export function actionGetBranchQrMenu(data) {
    return axiosInstance.post(`${hostName}/branch/qr-menu-details`, data);
}