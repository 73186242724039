import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {toast} from "react-toastify";

import useDocumentTitle from "../../utils/useDocumentTitle";
import Breadcrumbs from "../partials/Breadcrumbs";
import {loaderActions} from "../../store";
import {decryptData, encryptData} from "../../utils/crypto";
import {actionModifyReservationSetting, getReservationSetting} from "../../services/Reservation";
import Utils from "../../utils";

const bookIntervalList = [
    {value: '', label: 'Select Interval'},
    {value: 30, label: '30 Minutes'},
    {value: 60, label: '60 Minutes'},
    {value: 90, label: '90 Minutes'}
];

const durationList = [
    {value: '', label: 'Select duration'},
    {value: 30, label: '30 Minutes'},
    {value: 60, label: '60 Minutes'},
    {value: 90, label: '90 Minutes'},
    {value: 120, label: '120 Minutes'},
    {value: 180, label: '180 Minutes'}
];

const assignByList = [
    {value: 1, label: 'Admin'},
    {value: 2, label: 'Customer'}
];

function Reservation(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [shareLink, setShareLink] = useState('');
    const [description, setDescription] = useState('');
    const [policy, setPolicy] = useState('');
    const [bookingInterval, setBookingInterval] = useState('');
    const [assignBy, setAssignBy] = useState(1);
    const [assignDuration, setAssignDuration] = useState('');
    const [advanceReservation, setAdvanceReservation] = useState(0);
    const [lastMinReservation, setLastMinReservation] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const errorsObj = {
        booking_interval: '',
        description: '',
        policy: '',
        assign_duration: ''
    };
    const [errors, setErrors] = useState(errorsObj);
    useDocumentTitle(props.t('ReservationSetting'));

    useEffect(() => {
        dispatch(loaderActions.loader(true));
        let data = encryptData({language: getLanguage()});
        getReservationSetting(data)
            .then(response => {
                response = decryptData(response.data);
                setShareLink(response.data?.link);
                setDescription(response.data?.description ? response.data?.description : '');
                setPolicy(response.data?.policy ? response.data?.policy : '');
                setBookingInterval(response.data?.booking_interval ? response.data?.booking_interval : '');
                setAssignBy(response.data?.assign_by ? response.data?.assign_by : 1);
                setAssignDuration(response.data?.assign_duration ? response.data?.assign_duration : '');
                setAdvanceReservation(response.data?.adv_resv_day ? response.data?.adv_resv_day : 0);
                setLastMinReservation(response.data?.last_min_resv ? response.data?.last_min_resv : 0);
                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/dashboard');
            });
    }, [dispatch, navigate]);

    const handleCopyLink = (e) => {
        e.preventDefault();
        navigator.clipboard?.writeText(shareLink);
    };

    const onChangeAssignBy = (e) => {
        setAssignDuration('');
        setAssignBy(e.target.value);
    }

    const handleReservationForm = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!bookingInterval) {
            errorObj.booking_interval = props.t(`RequiredBookingInterval`);
            error = true;
        }
        if (!description.trim()) {
            errorObj.description = props.t(`RequiredDescription`);
            error = true;
        }
        if (!policy.trim()) {
            errorObj.policy = props.t(`RequiredPolicy`);
            error = true;
        }
        if (parseInt(assignBy) === 1 && !assignDuration) {
            errorObj.assign_duration = props.t(`RequiredDuration`);
            error = true;
        }

        setErrors(errorObj);
        Utils.focusError();
        if (error) return;

        setIsLoading(true);
        let obj = {
            language: getLanguage(),
            booking_interval: bookingInterval,
            description: description,
            policy: policy,
            assign_by: assignBy,
            assign_duration: assignDuration,
            adv_resv_day: advanceReservation,
            last_min_resv: lastMinReservation
        };
        if (parseInt(assignBy) === 1) {
            obj['assign_duration'] = assignDuration;
        }
        let data = encryptData(obj);
        actionModifyReservationSetting(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setIsLoading(false);
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }

    return (
        <>
            <Breadcrumbs title={`ReservationSetting`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={handleReservationForm}>
                                    <Row>
                                        <Col sm={8}>
                                            <FormGroup>
                                                <Label
                                                    className="col-form-label">{props.t(`CustomerPortalOpenLink`)}</Label>
                                                <Input type={`text`} value={shareLink} disabled={true}
                                                       onChange={(e) => setShareLink(e.target.value)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup>
                                                <Button outline type={`button`} onClick={handleCopyLink}
                                                        color={`primary`} className={`px-3 mt-35`}>
                                                    <i className={`fa fa-copy`}/></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`BookingSlotInterval`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input type={`select`} value={bookingInterval}
                                                       onChange={(e) => setBookingInterval(e.target.value)}>
                                                    {bookIntervalList && bookIntervalList.map((item, index) => (
                                                        <option value={item.value} key={index}>{item.label}</option>
                                                    ))}
                                                </Input>
                                                {errors.booking_interval && (
                                                    <span
                                                        className={`invalid-feedback`}>{errors.booking_interval}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Description`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={description}
                                                    onReady={editor => {
                                                        editor.editing.view.change((writer) => {
                                                            writer.setStyle(
                                                                "height",
                                                                "200px",
                                                                editor.editing.view.document.getRoot()
                                                            );
                                                        });
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setDescription(data);
                                                    }}
                                                    onBlur={(event, editor) => {
                                                    }}
                                                    onFocus={(event, editor) => {
                                                    }}
                                                />
                                                {errors.description && (
                                                    <span className={`invalid-feedback`}>{errors.description}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ReservationPolicy`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={policy}
                                                    onReady={editor => {
                                                        editor.editing.view.change((writer) => {
                                                            writer.setStyle(
                                                                "height",
                                                                "200px",
                                                                editor.editing.view.document.getRoot()
                                                            );
                                                        });
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setPolicy(data);
                                                    }}
                                                    onBlur={(event, editor) => {
                                                    }}
                                                    onFocus={(event, editor) => {
                                                    }}
                                                />
                                                {errors.policy && (
                                                    <span className={`invalid-feedback`}>{errors.policy}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <fieldset>
                                        <legend>{props.t(`TableReservationDuration`)}</legend>
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="col-form-label">{props.t(`AssignedBy`)}
                                                        <span className={`text-danger ml-1`}>*</span></Label>
                                                    <Input type={`select`} value={assignBy}
                                                           onChange={onChangeAssignBy}>
                                                        {assignByList && assignByList.map((item, index) => (
                                                            <option value={item.value} key={index}>{item.label}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            {parseInt(assignBy) === 1 && (
                                                <Col sm={4}>
                                                    <FormGroup>
                                                        <Label className="col-form-label">{props.t(`Duration`)}
                                                            <span className={`text-danger ml-1`}>*</span></Label>
                                                        <Input type={`select`} value={assignDuration}
                                                               onChange={(e) => setAssignDuration(e.target.value)}>
                                                            {durationList && durationList.map((item, index) => (
                                                                <option value={item.value}
                                                                        key={index}>{item.label}</option>
                                                            ))}
                                                        </Input>
                                                        {errors.assign_duration && (
                                                            <span
                                                                className={`invalid-feedback`}>{errors.assign_duration}</span>)}
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="col-form-label">{props.t(`AdvanceReservation`)}
                                                        <span className={`text-danger ml-1`}>*</span></Label>
                                                    <Input type={`select`} value={advanceReservation}
                                                           onChange={(e) => setAdvanceReservation(e.target.value)}>
                                                        {Array.from(Array(31)).map((_, index) => (
                                                            <option value={index} key={index}>{index}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="col-form-label">{props.t(`LastMinReservation`)}
                                                        <span className={`text-danger ml-1`}>*</span></Label>
                                                    <Input type={`select`} value={lastMinReservation}
                                                           onChange={(e) => setLastMinReservation(e.target.value)}>
                                                        {Array.from(Array(25)).map((_, index) => (
                                                            <option value={index} key={index}>{index}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <Row>
                                        <Col sm="12" className={`text-right mt-4`}>
                                            {!isLoading && (
                                                <Button color="primary" type={`submit`}
                                                        className={`btn-square`}>{props.t(`Submit`)}</Button>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(Reservation);