import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import Breadcrumbs from "../partials/Breadcrumbs";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {actionUpdateTax, getTaxDetails} from "../../services/Tax";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {decryptData, encryptData} from "../../utils/crypto";
import configDb from "../../config";
import {toast} from "react-toastify";
import Utils from "../../utils";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {loaderActions} from "../../store";
import {useDispatch} from "react-redux";

const statusList = configDb.data.statusList;

function EditTax(props) {
    const navigate = useNavigate();
    const {uuid} = useParams();
    const [code, setCode] = useState('');
    const [rate, setRate] = useState('');
    const [description, setDescription] = useState('');
    const [statusId, setStatusId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useDocumentTitle(props.t('EditTax'));
    const errorsObj = {
        code: "",
        rate: "",
        description: ""
    };
    const [errors, setErrors] = useState(errorsObj);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loaderActions.loader(true));
        let data = encryptData({uuid: uuid, language: getLanguage()});
        getTaxDetails(data)
            .then(response => {
                response = decryptData(response.data);
                setCode(response.data.code);
                setRate(response.data.rate.toString());
                setStatusId(response.data.status_id);
                setDescription(response.data.description);
                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/tax')
            });
    }, [uuid, navigate,dispatch]);
    const onUpdate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {errorsObj};
        if (!code) {
            errorObj.code = props.t('RequiredCode');
            error = true;
        }
        if (!rate) {
            errorObj.rate = props.t('RequiredRate');
            error = true;
        } else if (rate < 0) {
            errorObj.rate = props.t('zeroGreaterValue');
            error = true;
        } else if (rate >= 100) {
            errorObj.rate = props.t('100LessValue');
            error = true;
        }
        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        let obj = encryptData({
            uuid: uuid,
            language: getLanguage(),
            code: code,
            rate: rate,
            description: description,
            status_id: statusId
        });
        actionUpdateTax(obj)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/tax');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                navigate('/tax');
            });

    }
    return (
        <>
            <Breadcrumbs parentLink={'/tax'} parent={`Tax`} title={`EditTax`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onUpdate}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Code`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`code`}
                                                       value={code} onChange={(e) => setCode(e.target.value)}
                                                       placeholder={props.t(`EnterCode`)} maxLength={75}/>
                                                {errors.code && (
                                                    <span className={`invalid-feedback`}>{errors.code}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Rate`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="number" name={`rate`}
                                                       step="0.01" min={0}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                       value={rate} onChange={(e) => setRate(e.target.value)}
                                                       placeholder={props.t(`EnterRate`)} maxLength={10}/>
                                                {errors.rate && (
                                                    <span className={`invalid-feedback`}>{errors.rate}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input name="status_id" value={statusId} type="select"
                                                       onChange={(e) => setStatusId(e.target.value)}>
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                                {errors.status_id && (
                                                    <span className={`invalid-feedback`}>{errors.status_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Description`)}</Label>
                                                <Input className="form-control" type="textarea" name={`description`}
                                                       value={description} rows={5}
                                                       onChange={(e) => setDescription(e.target.value)}
                                                       placeholder={props.t(`EnterDescription`)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={`/tax`} role={`button`} end
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(EditTax);