import React, {useState, useEffect} from "react";
import {translate, getLanguage} from "react-switch-lang";
import Pagination from "react-js-pagination";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Form,
    FormGroup,
    Input, Label, Modal, ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import {NavLink} from "react-router-dom";

import Breadcrumbs from "../partials/Breadcrumbs";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {decryptData, encryptData} from "../../utils/crypto";
import {actionDelRole, actionStoreRole, actionUpdateRole, getRoleList} from "../../services/Role";
import configDb from "../../config";
import {toast} from "react-toastify";
import Utils from "../../utils";
import {useSelector} from "react-redux";

const tableLengthList = configDb.data.dataTableLength;

function Roles(props) {
    const allPermissionsList = useSelector(x => x.permission.value);
    const [roleList, setRoleList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [modifyModal, setModifyModal] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [delRole, setDelRole] = useState('');
    const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
    const [roleData, setRoleData] = useState({uuid: '', name: ''});
    const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let errorsObj = {name: ''};
    const [errors, setErrors] = useState(errorsObj);
    useDocumentTitle(props.t('Roles'));

    const [params, setParams] = useState({
        limit: tableLengthList[0],
        page: 1,
        search: "",
        is_reload: false
    });
    useEffect(function () {
        let data = encryptData(params);
        setIsLoading(true);
        getRoleList(data)
            .then(response => {
                response = decryptData(response.data);
                setTotalRecords(response.total);
                setRoleList(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
            });
    }, [params]);
    const handleParams = (e, type) => {
        let paramsObj = {...params};
        if (type === 'length') {
            paramsObj.limit = e.target.value;
        } else if (type === 'search') {
            paramsObj.page = 1;
            paramsObj.search = e.target.value;
        } else if (type === 'pagination') {
            paramsObj.page = e;
        }
        setParams(paramsObj);
    }
    const onDeleteRole = (e, item) => {
        e.preventDefault();
        setDelRole(item.uuid);
        setDelModal(true);
    };

    const onDeleteModalClose = (e) => {
        e.preventDefault();
        setDelRole('');
        setDelModal(false);
    };

    const onDestroyRole = (e) => {
        e.preventDefault();
        setIsDelButtonLoad(true);
        let data = encryptData({uuid: delRole, language: getLanguage()});
        actionDelRole(data)
            .then(response => {
                let paramsObj = {...params};
                paramsObj.is_reload = !paramsObj.is_reload;
                setParams(paramsObj);
                response = decryptData(response.data);
                toast.success(response.message);
                setDelRole('');
                setDelModal(false);
                setIsDelButtonLoad(false);
            })
            .catch(err => {
                setIsDelButtonLoad(false);
                toast.error(Utils.getErrorMessage(err));
            });
    };
    const onAddRole = (e) => {
        e.preventDefault();
        setRoleData({uuid: '', name: ''});
        setModifyModal(true);
    };
    const onEditRole = (e, item) => {
        e.preventDefault();
        setRoleData({uuid: item.uuid, name: item.name});
        setModifyModal(true);
    };
    const onModifyModalClose = (e) => {
        setRoleData({uuid: '', name: ''});
        setModifyModal(false);
        setErrors(errorsObj);
    };
    const handleRoleName = (e) => {
        let data = {...roleData};
        data.name = e.target.value;
        setRoleData(data);
    };
    const handleRoleForm = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!roleData.name) {
            errorObj.name = props.t(`RequiredRoleName`);
            error = true;
        }
        setErrors(errorObj);

        if (error) return;

        let dataParams = {...params};

        setIsModifyButtonLoad(true);
        if (roleData.uuid) {
            let data = encryptData({uuid: roleData.uuid, name: roleData.name, language: getLanguage()});
            actionUpdateRole(data)
                .then(response => {
                    response = decryptData(response.data);
                    toast.success(response.message);
                    dataParams.page = 1;
                    dataParams.is_reload = !dataParams.is_reload;
                    setParams(dataParams);
                    setRoleData({uuid: '', name: ''});
                    setModifyModal(false);
                    setIsModifyButtonLoad(false);
                })
                .catch(err => {
                    toast.error(Utils.getErrorMessage(err));
                    setIsModifyButtonLoad(false);
                });
        } else {
            let data = encryptData({name: roleData.name, language: getLanguage()});
            actionStoreRole(data)
                .then(response => {
                    response = decryptData(response.data);
                    toast.success(response.message);
                    dataParams.page = 1;
                    dataParams.is_reload = !dataParams.is_reload;
                    setParams(dataParams);
                    setRoleData({uuid: '', name: ''});
                    setModifyModal(false);
                    setIsModifyButtonLoad(false);
                })
                .catch(err => {
                    toast.error(Utils.getErrorMessage(err));
                    setIsModifyButtonLoad(false);
                });
        }
    }
    return (
        <>
            <Breadcrumbs title={`Roles`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <Card className="b-t-primary">
                            <CardHeader className={`py-4`}>
                                <h5>
                                    <span>{props.t(`ListRole`)}</span>
                                    {allPermissionsList.indexOf('modify_roles') > -1 && (
                                        <Button color={`primary`} type={`button`}
                                                onClick={onAddRole}
                                                className={`btn-sm btn-square float-right`}>{props.t(`AddRole`)}</Button>
                                    )}
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={1}>
                                        <FormGroup>
                                            <select name="length" className="form-control" value={params.limit}
                                                    onChange={(e) => handleParams(e, 'length')}>
                                                {tableLengthList && tableLengthList.map((item, i) =>
                                                    <option value={item} key={i}>{item}</option>
                                                )}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2} className={`offset-sm-9`}>
                                        <FormGroup>
                                            <Input type={`text`} value={params.search}
                                                   onChange={(e) => handleParams(e, 'search')}
                                                   className={`form-control`}
                                                   placeholder={props.t(`Search`)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <Table className={`table-bordered ${isLoading ? `loading` : ``}`}>
                                        <thead>
                                        <tr>
                                            <th scope={`col`}>#</th>
                                            <th scope={`col`}>{props.t(`RoleName`)}</th>
                                            {(allPermissionsList.indexOf('modify_roles') > -1 || allPermissionsList.indexOf('delete_roles') > -1) && (
                                                <th scope={`col`}>{props.t(`Action`)}</th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {roleList && roleList.map((item, i) =>
                                            <tr key={i}>
                                                <td>{item.index}</td>
                                                <td>{item.name}</td>
                                                {(allPermissionsList.indexOf('modify_roles') > -1 || allPermissionsList.indexOf('delete_roles') > -1) && (
                                                    <td>
                                                        {allPermissionsList.indexOf('modify_roles') > -1 && (
                                                            <>
                                                                <Button outline color={`primary`} type={`button`}
                                                                        onClick={(e) => onEditRole(e, item)}
                                                                        className={`btn-sm btn-square mr-1`}>{props.t(`Edit`)}</Button>
                                                                <NavLink to={`/roles/${item.uuid}/permissions`}
                                                                         role={`button`}
                                                                         className={`btn btn-outline-success btn-square btn-sm mr-1`}>{props.t(`Permission`)}</NavLink>
                                                            </>
                                                        )}
                                                        {allPermissionsList.indexOf('delete_roles') > -1 && (
                                                            <Button outline color={`danger`} type={`button`}
                                                                    onClick={(e) => onDeleteRole(e, item)}
                                                                    className={`btn-sm btn-square`}>{props.t(`Delete`)}</Button>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                        {roleList && roleList.length === 0 && (
                                            <tr>
                                                <td colSpan={(allPermissionsList.indexOf('modify_roles') > -1 || allPermissionsList.indexOf('delete_roles') > -1) ? 3 : 2}
                                                    className={`text-center`}>{props.t(`RecordNotFound`)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                                <Row>
                                    <Col sm={12} className={`mt-3`}>
                                        <div className={`float-right`}>
                                            <Pagination
                                                activePage={params.page}
                                                itemsCountPerPage={parseInt(params.limit)}
                                                totalItemsCount={totalRecords}
                                                pageRangeDisplayed={5}
                                                itemClass={`page-item`}
                                                linkClass={`page-link`}
                                                onChange={(e) => handleParams(e, 'pagination')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={delModal}>
                <ModalHeader>{props.t(`DeleteRole`)}</ModalHeader>
                <ModalBody>
                    <p className={`mb-0`}>{props.t(`DeleteWarn`)}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={`button`} disabled={isDelButtonLoad} outline
                            onClick={onDeleteModalClose} className={`btn-square`}>{props.t(`Close`)}</Button>
                    {!isDelButtonLoad && (
                        <Button color="secondary" type={`button`} className={`btn-square`}
                                onClick={onDestroyRole}>{props.t(`Delete`)}</Button>
                    )}
                    {isDelButtonLoad && (
                        <Button color="secondary" type={`button`} disabled={true} className={`btn-square`}>
                            <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
            <Modal isOpen={modifyModal}>
                <ModalHeader>{roleData.uuid ? props.t(`EditRole`) : props.t(`CreateRole`)}</ModalHeader>
                <Form method={`post`} onSubmit={handleRoleForm}>
                    <ModalBody>
                        <FormGroup>
                            <Label className="col-form-label">{props.t('RoleName')}
                                <span className={`text-danger ml-1`}>*</span></Label>
                            <Input type={`text`} name={`role_name`} value={roleData.name} onChange={handleRoleName}
                                   placeholder={props.t(`EnterRoleName`)} maxLength={50}/>
                            {errors.name && (
                                <span className={`invalid-feedback`}>{errors.name}</span>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type={`button`} disabled={isModifyButtonLoad} outline
                                onClick={onModifyModalClose} className={`btn-square`}>{props.t(`Close`)}</Button>
                        {!isModifyButtonLoad && (
                            <Button color="success" type={`submit`}
                                    className={`btn-square`}>{props.t(`Submit`)}</Button>
                        )}
                        {isModifyButtonLoad && (
                            <Button color="success" type={`button`} disabled={true} className={`btn-square`}>
                                <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                            </Button>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default translate(Roles);