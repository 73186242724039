import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {translate, getLanguage} from "react-switch-lang";
import DatePicker from "react-datepicker";
import Select from "react-select";
import validator from "validator";
import {toast} from "react-toastify";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Breadcrumbs from "../partials/Breadcrumbs";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {actionCountryList, actionTimeZoneList} from "../../services/Common";
import {decryptData, encryptData} from "../../utils/crypto";
import configDb from "../../config";
import {getAllRoleList} from "../../services/Role";
import {getAllBranchList} from "../../services/Branch";
import Utils from "../../utils";
import {actionUpdateEmployee, actionGetEmployee} from "../../services/Employee";
import {loaderActions} from "../../store";

let statusList = configDb.data.statusList;

function EditEmployee(props) {
    const {uuid} = useParams();
    const loginAuth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [roleId, setRoleId] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [countryId, setCountryId] = useState('');
    const [timezoneId, setTimezoneId] = useState('');
    const [postCode, setPostCode] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [countryList, setCountryList] = useState([]);
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [selectTimezone, setSelectTimezone] = useState(null);
    const [selectCountry, setSelectCountry] = useState(null);
    const [selectRole, setSelectRole] = useState(null);
    const [selectBranch, setSelectBranch] = useState(null);
    const [isTimezoneLoad, setIsTimezoneLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [employeeData, setEmployeeData] = useState({});
    useDocumentTitle(props.t('EditEmployee'));
    const errorsObj = {
        full_name: "",
        email: "",
        mobile: "",
        role_id: "",
        branch_id: "",
        birth_date: "",
        address_1: "",
        city: "",
        state: "",
        country_id: "",
        timezone_id: "",
        postcode: "",
        status_id: ""

    };
    const [errors, setErrors] = useState(errorsObj);

    useEffect(function () {
        dispatch(loaderActions.loader(true));
        let data = encryptData({uuid: uuid, language: getLanguage()});
        actionGetEmployee(data)
            .then(response => {
                response = decryptData(response.data);
                setEmployeeData(response.data);
                setFullName(response.data.full_name);
                setEmail(response.data.email);
                setMobile(response.data.mobile ? response.data.mobile : '');
                setAddress1(response.data.address_1);
                setAddress2(response.data.address_2);
                setCity(response.data.city);
                setState(response.data.state);
                setTimezoneId(response.data.timezone_id);
                setPostCode(response.data.postcode);
                setStatusId(response.data.status_id);
                setBirthDate(response.data.birth_date ? response.data.birth_date : '');
                actionCountryList()
                    .then(result => {
                        result = decryptData(result.data);
                        let list = [];
                        for (let i = 0; i < result.data.length; i++) {
                            list.push({
                                value: result.data[i].id,
                                label: result.data[i].name
                            });
                        }
                        setCountryList(list);
                        let index = list.findIndex(x => parseInt(x.value) === parseInt(response.data.country_id));
                        if (index > -1) {
                            setSelectCountry(list[index]);
                            setCountryId(response.data.country_id);
                        }
                    })
                    .catch(err => {
                    });

                getAllRoleList()
                    .then(responseRole => {
                        responseRole = decryptData(responseRole.data);
                        let list = [];
                        for (let i = 0; i < responseRole.data.length; i++) {
                            list.push({
                                value: responseRole.data[i].id,
                                label: responseRole.data[i].name
                            });
                        }
                        setRoleList(list);
                        let index = list.findIndex(x => parseInt(x.value) === parseInt(response.data.role_id));
                        if (index > -1) {
                            setSelectRole(list[index]);
                            setRoleId(response.data.role_id);
                        }
                    })
                    .catch(err => {
                    });

                let bData = encryptData({category_id: ''});
                getAllBranchList(bData)
                    .then(resultBranch => {
                        resultBranch = decryptData(resultBranch.data);
                        let list = [];
                        let selectedBranch = [];
                        let branchIds = response.data.branch_id.split(',');
                        for (let i = 0; i < resultBranch.data.length; i++) {
                            if (loginAuth?.branch_id) {
                                if (branchIds.findIndex(x => parseInt(x) === parseInt(resultBranch.data[i].id)) > -1) {
                                    list.push({
                                        value: resultBranch.data[i].id,
                                        label: resultBranch.data[i].display_name
                                    });
                                } else {
                                    let loginBranchIds = loginAuth?.branch_id.split(',');
                                    loginBranchIds = loginBranchIds.map(x => parseInt(x));
                                    if (loginBranchIds.indexOf(parseInt(resultBranch.data[i].id)) > -1) {
                                        list.push({
                                            value: resultBranch.data[i].id,
                                            label: resultBranch.data[i].display_name
                                        });
                                    }
                                }
                            } else {
                                list.push({
                                    value: resultBranch.data[i].id,
                                    label: resultBranch.data[i].display_name
                                });
                            }
                            if (branchIds.findIndex(x => parseInt(x) === parseInt(resultBranch.data[i].id)) > -1) {
                                selectedBranch.push({
                                    value: resultBranch.data[i].id,
                                    label: resultBranch.data[i].display_name
                                });
                            }
                        }
                        setSelectBranch(selectedBranch);
                        setBranchList(list);
                    })
                    .catch(err => {

                    });

                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/employee');
            });
    }, [uuid, navigate, dispatch, loginAuth]);

    useEffect(() => {
        if (countryId) {
            setIsTimezoneLoad(true);
            let data = encryptData({country_id: countryId});
            actionTimeZoneList(data)
                .then(response => {
                    response = decryptData(response.data);
                    let list = [];
                    for (let i = 0; i < response.data.length; i++) {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].name
                        });
                    }
                    setTimeZoneList(list);
                    setIsTimezoneLoad(false);
                    let index = list.findIndex(x => parseInt(x.value) === parseInt(timezoneId));
                    if (index > -1) {
                        setSelectTimezone(list[index]);
                        setTimezoneId(timezoneId);
                    }
                })
                .catch(err => {
                    setIsTimezoneLoad(false);
                });
        }
    }, [countryId, timezoneId]);

    const onUpdateTimezone = (e) => {
        setSelectTimezone(null);
        setTimezoneId('');
        setCountryId(e.value);
        setSelectCountry(e);
    }
    const handleTimezone = (e) => {
        setSelectTimezone(e)
        setTimezoneId((e?.value) ? e.value : '');
    }
    const handleRole = (e) => {
        setSelectRole(e)
        setRoleId((e?.value) ? e.value : '');
    }

    const showDate = (date) => {
        let dateFormat = '';
        if (date) {
            dateFormat = new Date(date);
        }
        return dateFormat;
    };
    const onUpdate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!fullName) {
            errorObj.full_name = props.t('RequiredFullName');
            error = true;
        }
        if (!email) {
            errorObj.email = props.t('RequiredEmail');
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = props.t('InValidEmail');
            error = true;
        }
        if (!mobile) {
            errorObj.mobile = props.t('RequiredMobile');
            error = true;
        } else if (!validator.isMobilePhone(mobile)) {
            errorObj.mobile = props.t('InValidMobile');
            error = true;
        } else if (mobile && mobile.length !== 10) {
            errorObj.mobile = props.t('InValidMobile');
            error = true;
        }
        if (!address1) {
            errorObj.address_1 = props.t('RequiredAddress1');
            error = true;
        }
        if (!timezoneId) {
            errorObj.timezone_id = props.t('RequiredTimeZone');
            error = true;
        }
        if (!countryId) {
            errorObj.countryId = props.t('RequiredCountry');
            error = true;
        }
        if (!roleId) {
            errorObj.role_id = props.t('RequiredRole');
            error = true;
        }
        if (selectBranch.length === 0) {
            errorObj.branch_id = props.t('RequiredBranch');
            error = true;
        }
        if (!city) {
            errorObj.city = props.t('RequiredCity');
            error = true;
        }
        if (!state) {
            errorObj.state = props.t('RequiredState');
            error = true;
        }
        if (!postCode) {
            errorObj.postcode = props.t('RequiredPostcode');
            error = true;
        }
        Utils.focusError();
        setErrors(errorObj);
        if (error) return;
        setIsLoading(true);
        let branchIds = selectBranch.map(e => e.value);
        branchIds = branchIds.join();

        if (loginAuth?.branch_id) {
            let ids = Utils.getBranchDiffIds(employeeData.branch_id, loginAuth.branch_id, branchIds);
            if (ids) {
                branchIds = branchIds + ',' + ids;
            }
        }

        let objectInsert = {
            uuid: uuid,
            language: getLanguage(),
            full_name: fullName,
            email: email,
            mobile: mobile,
            role_id: roleId,
            branch_id: branchIds,
            birth_date: birthDate,
            address_1: address1,
            address_2: address2,
            city: city,
            state: state,
            country_id: countryId,
            timezone_id: timezoneId,
            postcode: postCode,
            status_id: statusId
        }
        actionUpdateEmployee(encryptData(objectInsert))
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/employee');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }
    return (
        <>
            <Breadcrumbs parentLink={'/employee'} parent={`Employee`} title={`EditEmployee`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onUpdate} encType={`multipart/form-data`}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`FullName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`full_name`}
                                                       value={fullName} onChange={(e) => setFullName(e.target.value)}
                                                       placeholder={props.t(`EnterFullName`)} maxLength={75}/>
                                                {errors.full_name && (
                                                    <span className={`invalid-feedback`}>{errors.full_name}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`EmailAddress`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`email`}
                                                       value={email} onChange={(e) => setEmail(e.target.value)}
                                                       placeholder={props.t(`EnterEmailAddress`)} maxLength={75}/>
                                                {errors.email && (
                                                    <span className={`invalid-feedback`}>{errors.email}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`MobileNumber`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="number" name={`mobile`} min={0}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                       value={mobile} onChange={(e) => setMobile(e.target.value)}
                                                       placeholder={props.t(`EnterMobileNumber`)} maxLength={10}/>
                                                {errors.mobile && (
                                                    <span className={`invalid-feedback`}>{errors.mobile}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Role`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={roleList}
                                                        onChange={handleRole}
                                                        placeholder={props.t(`PleaseSelectRole`)} value={selectRole}
                                                        name={`role_id`}/>
                                                {errors.role_id && (
                                                    <span className={`invalid-feedback`}>{errors.role_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Branch`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={branchList} isMulti
                                                        onChange={(e) => setSelectBranch(e)}
                                                        placeholder={props.t(`PleaseSelectBranch`)} value={selectBranch}
                                                        name={`branch_id`}/>
                                                {errors.branch_id && (
                                                    <span className={`invalid-feedback`}>{errors.branch_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`BirthDate`)}</Label>
                                                <DatePicker className="form-control" type="text"
                                                            selected={showDate(birthDate)}
                                                            onChange={(date) => setBirthDate(date)}
                                                            dateFormat="dd-MM-yyyy"
                                                            maxDate={Utils.blockFutureDate()}
                                                            placeholderText={props.t(`EnterBirthDate`)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address1`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`address1`}
                                                       value={address1} onChange={(e) => setAddress1(e.target.value)}
                                                       placeholder={props.t(`EnterAddress1`)} maxLength={75}/>
                                                {errors.address_1 && (
                                                    <span className={`invalid-feedback`}>{errors.address_1}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address2`)}</Label>
                                                <Input className="form-control" type="text" name={`address2`}
                                                       value={address2} onChange={(e) => setAddress2(e.target.value)}
                                                       placeholder={props.t(`EnterAddress2`)} maxLength={75}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`city`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`city`}
                                                       value={city} onChange={(e) => setCity(e.target.value)}
                                                       placeholder={props.t(`EnterCity`)} maxLength={10}/>
                                                {errors.city && (
                                                    <span className={`invalid-feedback`}>{errors.city}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`state`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`state`}
                                                       value={state} onChange={(e) => setState(e.target.value)}
                                                       placeholder={props.t(`EnterState`)} maxLength={10}/>
                                                {errors.state && (
                                                    <span className={`invalid-feedback`}>{errors.state}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Country`)} <span
                                                    className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={countryList} onChange={onUpdateTimezone}
                                                        value={selectCountry}
                                                        placeholder={props.t(`PleaseSelectCountry`)}
                                                        name={`country_id`}/>
                                                {errors.countryId && (
                                                    <span className={`invalid-feedback`}>{errors.countryId}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`TimeZone`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select name={`time_zone`} options={timeZoneList}
                                                        value={selectTimezone} isLoading={isTimezoneLoad}
                                                        placeholder={props.t(`PleaseSelectTimezone`)}
                                                        onChange={handleTimezone}/>
                                                {errors.timezone_id && (
                                                    <span className={`invalid-feedback`}>{errors.timezone_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`postcode`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`postcode`}
                                                       value={postCode} onChange={(e) => setPostCode(e.target.value)}
                                                       placeholder={props.t(`EnterPostCode`)} maxLength={10}/>
                                                {errors.postcode && (
                                                    <span className={`invalid-feedback`}>{errors.postcode}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input name="status_id" value={statusId} type="select"
                                                       onChange={(e) => setStatusId(e.target.value)}>
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}
                                                </Input>
                                                {errors.status_id && (
                                                    <span className={`invalid-feedback`}>{errors.status_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={'/employee'} role={`button`} end
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default translate(EditEmployee);