import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";

import Breadcrumbs from "../partials/Breadcrumbs";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {loaderActions} from "../../store";
import {decryptData, encryptData} from "../../utils/crypto";
import {actionGetBranchQrMenu, actionUpdateBranchQrMenu, getCheckBranch} from "../../services/Branch";
import Utils from "../../utils";
import configDb from "../../config";
import {getAllPaymentList} from "../../services/PaymentMethod";

let yesNoList = configDb.data.yesNoList;
let applicableForList = configDb.data.applicableForList;

function BranchQrMenu(props) {
    const {uuid} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const company = useSelector(x => x.company.value);
    const [branchData, setBranchData] = useState({id: '', display_name: ''});
    const [branchId, setBranchId] = useState(branchData.id);
    const [enableQrMenu, setEnableQrMenu] = useState(1);
    const [applicableFor, setApplicableFor] = useState([]);
    const [skipCustomerInfo, setSkipCustomerInfo] = useState(0);
    const [isSkip, setIsSkip] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isQrOptions, setIsQrOptions] = useState(true);

    const [deliveryOptions, setDeliveryOptions] = useState(false);
    const [deliveryList, setDeliveryList] = useState([]);
    const [removeDeliveryList, setRemoveDeliveryList] = useState([]);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [selectPaymentMethod, setSelectPaymentMethod] = useState([]);
    const errorsObj = {
        applicable_for: "",
        ccCharge: "",
        payment_method: ""
    };
    const [errors, setErrors] = useState(errorsObj);

    useDocumentTitle(branchData.display_name + ' ' + props.t(`QrMenu`));

    useEffect(() => {
        if (company.business_category_name === configDb.data.restaurant) {
            if (!branchId) {
                dispatch(loaderActions.loader(true));
                let data = encryptData({uuid: uuid, language: getLanguage()});
                getCheckBranch(data)
                    .then(response => {
                        response = decryptData(response.data);
                        setBranchData(response.data);
                        setBranchId(response.data.id);
                        dispatch(loaderActions.loader(false));
                    })
                    .catch(err => {
                        dispatch(loaderActions.loader(false));
                        toast.error(Utils.getErrorMessage(err));
                        navigate('/branch');
                    });
            }
        } else {
            navigate('/403');
        }
    }, [uuid, navigate, company, dispatch, branchId]);

    useEffect(() => {
        dispatch(loaderActions.loader(true));
        let data = encryptData({uuid: uuid, language: getLanguage()});
        actionGetBranchQrMenu(data)
            .then(response => {
                response = decryptData(response.data);
                setEnableQrMenu(response.data.enable_qr_menu);
                setIsQrOptions((response.data.enable_qr_menu === 1) ? true : false);
                let list = [];
                let applicableForValues = response.data.applicable_for.split(',');
                for (let i = 0; i < applicableForList.length; i++) {
                    if (applicableForValues.findIndex(x => parseInt(x) === parseInt(applicableForList[i].value)) > -1) {
                        list.push({
                            value: applicableForList[i].value,
                            label: applicableForList[i].label
                        });
                    }
                }

                let pData = encryptData({branch_id: response.data.id});
                getAllPaymentList(pData)
                    .then(result => {
                        result = decryptData(result.data);
                        let methodList = [{value: 0, label: 'Pay by cash'}];
                        for (let i = 0; i < result.data.length; i++) {
                            methodList.push({
                                value: result.data[i]['id'],
                                label: result.data[i]['entity_name']
                            });
                        }
                        setPaymentMethodList(methodList);
                        let selectedMethodList = [];
                        for (let i = 0; i < methodList.length; i++) {
                            if (response.data?.payment_method_id) {
                                let paymentMethodId = response.data.payment_method_id.split(',');
                                let index = paymentMethodId.findIndex(x => parseInt(x) === parseInt(methodList[i]['value']));
                                if (index > -1) {
                                    selectedMethodList.push(methodList[index]);
                                }
                            }
                        }
                        setSelectPaymentMethod(selectedMethodList);
                    })
                    .catch(err => {

                    });

                let checkAll = applicableForValues.findIndex(x => parseInt(x) === 0);
                let checkDelivery = applicableForValues.findIndex(x => parseInt(x) === 2);
                if (checkAll > -1 || checkDelivery > -1) {
                    setDeliveryOptions(true);
                    setIsSkip(true);
                } else {
                    setIsSkip(false);
                }
                setApplicableFor(list);
                setSkipCustomerInfo(response.data.skip_customer_info);
                if (response.data?.deliveryList) {
                    setDeliveryList(response.data.deliveryList);
                }

                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/branch');
            });
    }, [uuid, navigate, dispatch]);

    const handleEnableQrMenu = (e) => {
        let val = e.target.value;
        setEnableQrMenu(val);
        setIsQrOptions((parseInt(val) === 1) ? true : false);
        if ((parseInt(val) !== 1)) {
            setApplicableFor([]);
            setSkipCustomerInfo(0);
            setSelectPaymentMethod([]);
            setDeliveryList([]);
            setDeliveryOptions(false);
        }
    }

    const handleChangeApplicableFor = (e) => {
        let list = e;
        setDeliveryOptions(false);
        if (list.length > 0) {
            let index = list.findIndex(x => parseInt(x.value) !== 0);
            if (index > -1) {
                let checkLastObj = list[list.length - 1];
                if (parseInt(checkLastObj.value) === 0) {
                    list = [{value: 0, label: 'All'}];
                } else {
                    let checkAll = list.findIndex(x => parseInt(x.value) === 0);
                    if (checkAll > -1) {
                        list.splice(checkAll, 1);
                    }
                    if (list.length === 3) {
                        list = [{value: 0, label: 'All'}];
                    }
                }
            } else {
                list = [{value: 0, label: 'All'}];
            }
            let checkDelivery = list.findIndex(x => parseInt(x.value) === 2);
            let checkAll = list.findIndex(x => parseInt(x.value) === 0);
            if (checkDelivery > -1 || checkAll > -1) {
                setDeliveryOptions(true);
                setIsSkip(true);
                setSkipCustomerInfo(0);
            } else {
                setIsSkip(false);
                setDeliveryList([]);
            }
        }
        setApplicableFor(list);
    }

    const onAddDelivery = (e) => {
        e.preventDefault();
        let list = [...deliveryList];
        list.push({
            from_distance: '',
            from_distance_error: '',
            to_distance: '',
            to_distance_error: '',
            rate: '',
            rate_error: '',
            uuid: ''
        });
        setDeliveryList(list);
    }
    const handleDeliveryremove = (e, index) => {
        e.preventDefault();
        let list = [...deliveryList];
        let removeList = [...removeDeliveryList];
        if (list[index]['uuid']) {
            removeList.push(list[index]['uuid']);
        }
        list.splice(index, 1);
        setDeliveryList(list);
        setRemoveDeliveryList(removeList);
    };
    const handleDistance = (e, index, field) => {
        let list = [...deliveryList];
        list[index][field] = e.target.value;
        setDeliveryList(list);
    };
    const onUpdate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        let deliveryEList = [...deliveryList];
        if (parseInt(enableQrMenu) === 1) {
            if (applicableFor.length === 0) {
                errorObj.applicable_for = props.t('RequiredApplicableFor');
                error = true;
            }
            if (selectPaymentMethod.length === 0) {
                errorObj.payment_method = props.t('RequiredPaymentMethod');
                error = true;
            }

            if (deliveryEList.length > 0 && deliveryOptions) {
                for (let i = 0; i < deliveryEList.length; i++) {
                    deliveryEList[i]['from_distance_error'] = '';
                    deliveryEList[i]['to_distance_error'] = '';
                    deliveryEList[i]['rate_error'] = '';
                    if (deliveryEList[i]['from_distance'] === '') {
                        deliveryEList[i]['from_distance_error'] = props.t('RequiredFromDistance');
                        error = true;
                    }
                    if (deliveryEList[i]['to_distance'] === '') {
                        deliveryEList[i]['to_distance_error'] = props.t('RequiredToDistance');
                        error = true;
                    } else if (parseInt(deliveryEList[i]['to_distance']) <= parseInt(deliveryEList[i]['from_distance'])) {
                        deliveryEList[i]['to_distance_error'] = props.t('ToDistanceValidate');
                        error = true;
                    }
                    if (deliveryEList[i]['rate'] === '') {
                        deliveryEList[i]['rate_error'] = props.t('RequiredToRate');
                        error = true;
                    }
                    if (deliveryEList[i]['from_distance']) {
                        let isFromDist = false;
                        let isRateDuplicate = false;
                        for (let j = 0; j < deliveryEList.length; j++) {
                            if (j !== i) {
                                if ((parseInt(deliveryEList[i]['from_distance']) >= parseInt(deliveryEList[j]['from_distance'])) && (parseInt(deliveryEList[i]['from_distance']) < parseInt(deliveryEList[j]['to_distance']))) {
                                    isFromDist = true;
                                }
                                if (parseInt(deliveryEList[i]['rate']) === parseInt(deliveryEList[j]['rate'])) {
                                    isRateDuplicate = true;
                                }
                            }
                        }
                        if (isFromDist) {
                            deliveryEList[i]['from_distance_error'] = props.t('AlreadyExist');
                            error = true;
                        }
                        if (isRateDuplicate) {
                            deliveryEList[i]['rate_error'] = props.t('DuplicateRate');
                            error = true;
                        }
                    }
                }
                setDeliveryList(deliveryEList);
            }
        }
        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        let applicableForIds = applicableFor.map(e => e.value);
        applicableForIds = applicableForIds.join();

        let methodId = selectPaymentMethod.map(x => x.value);
        methodId = methodId.join();

        let obj = {
            branch_id: branchId,
            enable_qr_menu: enableQrMenu,
            applicable_for: applicableForIds,
            skip_customer_info: skipCustomerInfo,
            payment_method_id: methodId
        };
        if (deliveryEList.length > 0) {
            obj.delivery_rates = JSON.stringify(deliveryEList);
        }
        if (removeDeliveryList.length > 0) {
            obj.removeRateUuid = removeDeliveryList.join();
        }
        let data = encryptData(obj);
        actionUpdateBranchQrMenu(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/branch');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }
    return (
        <>
            <Breadcrumbs title={`QrMenu`} parentLink={'/branch'} parent={`Branch`} subtitle={branchData.display_name}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onUpdate} encType={`multipart/form-data`}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`EnableQRMenu`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input name="enable_qr_menu" value={enableQrMenu}
                                                       onChange={handleEnableQrMenu} type="select">
                                                    {yesNoList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        {isQrOptions && (
                                            <>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="col-form-label">{props.t(`ApplicableFor`)}
                                                            <span
                                                                className={`text-danger ml-1`}>*</span></Label>
                                                        <Select options={applicableForList} isMulti
                                                                value={applicableFor}
                                                                onChange={handleChangeApplicableFor}
                                                                name={`applicable_for`}/>
                                                        {errors.applicable_for && (
                                                            <span
                                                                className={`invalid-feedback`}>{errors.applicable_for}</span>)}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="col-form-label">{props.t(`SkipInformationPage`)}
                                                            <span
                                                                className={`text-danger ml-1`}>*</span></Label>
                                                        <Input
                                                            name="skip_customer_info"
                                                            value={skipCustomerInfo} disabled={isSkip}
                                                            onChange={(e) => setSkipCustomerInfo(e.target.value)}
                                                            type="select">
                                                            {yesNoList.map((item, i) =>
                                                                <option key={i}
                                                                        value={item.id}>{item.name}</option>
                                                            )}</Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label className="col-form-label">{props.t(`PaymentMethod`)}
                                                            <span
                                                                className={`text-danger ml-1`}>*</span></Label>
                                                        <Select options={paymentMethodList} isMulti
                                                                value={selectPaymentMethod}
                                                                placeholder={props.t(`PleaseSelectOption`)}
                                                                onChange={(e) => setSelectPaymentMethod(e)}
                                                                name={`payment_method`}/>
                                                        {errors.payment_method && (
                                                            <span
                                                                className={`invalid-feedback`}>{errors.payment_method}</span>)}
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                    </Row>

                                    {deliveryOptions && (
                                        <Card className="b-t-primary mt-4">
                                            <CardHeader className={`py-4`}>
                                                <h5>
                                                    <span>{props.t(`AddDeliveryRate`)}</span>
                                                    <Button color="primary" type={`button`} onClick={onAddDelivery}
                                                            className={`float-right btn-square`}>{props.t(`Add`)}</Button>
                                                </h5>
                                            </CardHeader>
                                            <CardBody>
                                                {deliveryList.map((item, i) => (
                                                    <Row key={i}>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                {i === 0 && (
                                                                    <Label
                                                                        className="col-form-label">{props.t(`FromDistance`)}
                                                                        <span
                                                                            className={`text-danger ml-1`}>*</span>
                                                                    </Label>
                                                                )}
                                                                <Input className="form-control" type="number"
                                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                       value={item.from_distance}
                                                                       onChange={(e) => handleDistance(e, i, 'from_distance')}
                                                                />
                                                                {item.from_distance_error && (
                                                                    <span
                                                                        className={`invalid-feedback`}>{item.from_distance_error}</span>)}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                {i === 0 && (
                                                                    <Label
                                                                        className="col-form-label">{props.t(`ToDistance`)}
                                                                        <span
                                                                            className={`text-danger ml-1`}>*</span>
                                                                    </Label>
                                                                )}
                                                                <Input className="form-control" type="number"
                                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                                       value={item.to_distance}
                                                                       onChange={(e) => handleDistance(e, i, 'to_distance')}/>
                                                                {item.to_distance_error && (
                                                                    <span
                                                                        className={`invalid-feedback`}>{item.to_distance_error}</span>)}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                {i === 0 && (
                                                                    <Label
                                                                        className="col-form-label">{props.t(`Rate`)}
                                                                        <span
                                                                            className={`text-danger ml-1`}>*</span>
                                                                    </Label>
                                                                )}
                                                                <Input className="form-control" type="number"
                                                                       step="0.01" min={0}
                                                                       onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                                       value={item.rate}
                                                                       onChange={(e) => handleDistance(e, i, 'rate')}/>
                                                                {item.rate_error && (
                                                                    <span
                                                                        className={`invalid-feedback`}>{item.rate_error}</span>)}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <FormGroup>
                                                                {i === 0 && (
                                                                    <>
                                                                        <Label
                                                                            className="col-form-label text-white">Remove</Label>
                                                                        <br/>
                                                                    </>
                                                                )}
                                                                <Button color={`danger`} className={`btn-square`}
                                                                        onClick={(e) => handleDeliveryremove(e, i)}
                                                                        type={`button`}>{props.t(`Remove`)}</Button>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </CardBody>
                                        </Card>
                                    )}
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={`/branch`} role={`button`} end
                                                             className={`btn btn-square btn-outline-danger mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(BranchQrMenu);