import React from "react";
import {Outlet} from "react-router-dom";
import Loader from "../partials/Loader";
import TapTop from "../partials/TapTop";
import Header from "../partials/Header";
import Sidebar from "../partials/Sidebar";
import PageLoader from "../partials/PageLoader";

function Layouts() {
    return (
        <>
            <Loader/>
            <PageLoader/>
            <TapTop/>
            <div className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header/>
                <div className="page-body-wrapper">
                    <Sidebar/>
                    <div className="page-body">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Layouts;