import React from "react";
import {useSelector} from "react-redux";

function PageLoader() {
    const pageLoader = useSelector(x => x.load.value);

    return (
        <div className={`loader-wrapper ${pageLoader ? `` : `loderhide`}`} style={{backgroundColor: '#ffffff94'}}>
            <div className="loader-index"><span></span></div>
            <svg>
                <defs></defs>
                <filter id="goo">
                    <fegaussianblur in="SourceGraphic" stdDeviation="11" result="blur"></fegaussianblur>
                    <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                   result="goo"></fecolormatrix>
                </filter>
            </svg>
        </div>
    );
}

export default PageLoader;