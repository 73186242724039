export class ConfigDB {
    static data = {
        app_name: 'POS',
        signUpDomain: 'pos',
        domainName: '.divysoft.in',
        api_url: 'https://pos.neutroveg.com/api/v1/',
        api_local_storage: '@secure.p.a.f.data',
        api_c_local_storage: '@secure.p.c.f.data',
        decrypt_key: 'r5u8x/A?D(G+KbPeShVmYq3s6v9y$B&E',
        decrypt_iv: '5fgf5HJ5g2752157',
        is_json: false,
        settings: {
            layout_type: 'ltr',
            language: 'en',
            language_icon: 'us',
            sidebar: {
                type: 'compact-wrapper',
            },
            sidebar_setting: 'default-sidebar',
        },
        color: {
            primary_color: '#7366ff',
            secondary_color: '#f73164',
            mix_background_layout: 'light-only',
        },
        router_animation: 'fadeIn',
        languages: [
            {name: 'English', sub_name: '(US)', icon: 'us', lang: 'en', dir: 'ltr'},
            {name: 'Dutch', sub_name: '', icon: 'de', lang: 'du', dir: 'ltr'}
        ],
        strongPassword: {
            minLength: 6,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1
        },
        serviceLine: 'Service Line',
        restaurant: 'Restaurant',
        imageExt: /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/,
        filePDFExt: /\.(pdf|PDF)$/,
        alphaNumericSpace: /^[a-z\d\s]+$/i,
        dataTableLength: [10, 25, 75, 100],
        statusList: [
            {id: 1, name: 'Active'},
            {id: 2, name: 'Inactive'}
        ],
        yesNoList: [
            {id: 1, name: 'Yes'},
            {id: 0, name: 'No'}
        ],
        statusSelect2List: [
            {value: 1, label: 'Active'},
            {value: 2, label: 'Inactive'}
        ],
        taxList: [
            {id: 2, name: 'Tax Exclusive'},
            {id: 1, name: 'Tax Inclusive'}
        ],
        kitchenOrderFormat: [
            {id: 0, name: 'Select order format'},
            {id: 1, name: 'All items in one sheet'},
            {id: 2, name: 'One item per sheet'}
        ],
        priceTypeList: [
            {id: 1, name: 'Fixed'},
            {id: 2, name: 'By Unit'},
            {id: 3, name: 'By Point'},
            {id: 4, name: 'Open Price'}
        ],
        businessModeList: [
            {id: 1, name: 'Auto generate queue number'},
            {id: 2, name: 'Assign table number'},
            {id: 3, name: 'Assign tag number'}
        ],
        fileExt: /\.(csv|xlsx)$/,
        bannerForList: [
            {value: 1, label: 'QR Menu Portal', size: '640 X 360PX'},
            {value: 2, label: 'Customer Portal', size: '780 X 280px'}
        ],
        applicableForList: [
            {value: 0, label: 'All'},
            {value: 1, label: 'Self Pickup'},
            {value: 2, label: 'Delivery'},
            {value: 3, label: 'Dine in'}
        ],
        promotionApplyOnList: [
            {value: 0, label: 'All'},
            {value: 1, label: 'Ecommerce'},
            {value: 2, label: 'QR Menu'}
        ],
        promotionCodeForList: [
            {id: 0, name: 'All'},
            {id: 1, name: 'Product'},
            {id: 2, name: 'Price'}
        ],
        paymentPortalList: [
            {value: 1, label: 'QR Menu'},
            {value: 2, label: 'Ecommerce'}
        ],
        paymentEntityTypeList: [
            {id: 1, name: "Razorpay"}
        ],
        deliveryStatusList: [
            {id: 0, name: "New"},
            {id: 1, name: "Cancelled"},
            {id: 2, name: "Accepted"},
            {id: 3, name: "Cooking"},
            {id: 4, name: "Ready"},
            {id: 5, name: "On a way"},
            {id: 6, name: "Delivered"}
        ],
        selfPickUpStatusList: [
            {id: 0, name: "New"},
            {id: 1, name: "Cancelled"},
            {id: 2, name: "Accepted"},
            {id: 3, name: "Cooking"},
            {id: 4, name: "Ready"},
            {id: 6, name: "Completed"}
        ],
        dineInStatusList: [
            {id: 0, name: "New"},
            {id: 1, name: "Cancelled"},
            {id: 2, name: "Accepted"},
            {id: 3, name: "Cooking"},
            {id: 4, name: "Ready"},
            {id: 6, name: "Completed"}
        ]
    }
}

export default ConfigDB;




