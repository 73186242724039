import axios from 'axios';

import configDb from "../config";
import {store} from "../store";

let API_URL = configDb.data.api_url;

let contentType = "text/plain";
if (configDb.data.is_json) {
    contentType = "application/json";
}

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": contentType,
        accept: "*/*",
    },
});

axiosInstance.interceptors.request.use((config) => {
    let auth = store.getState().auth.value;
    if (auth?.token) {
        config.headers.token = auth.token;
        if (auth?.main_branch && auth?.main_branch > 0) {
            config.headers.branch = auth.main_branch;
        }
    }
    return config;
});

export default axiosInstance;