import React from "react";
import {translate} from "react-switch-lang";

import Breadcrumbs from "./partials/Breadcrumbs";
import useDocumentTitle from '../utils/useDocumentTitle';

function Dashboard(props) {

    useDocumentTitle(props.t('Dashboard'));

    return (
        <>
            <Breadcrumbs title={`Dashboard`}/>
        </>
    );
}

export default translate(Dashboard);