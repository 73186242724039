import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {getLanguage, translate} from "react-switch-lang";
import {useDispatch, useSelector} from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Form,
    FormGroup,
    Input, Label, Modal, ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";

import Breadcrumbs from "../partials/Breadcrumbs";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {
    actionDeleteTables,
    actionStoreTables,
    actionUpdateTables,
    getBranchTables,
    getCheckBranch
} from "../../services/Branch";
import {decryptData, encryptData} from "../../utils/crypto";
import {loaderActions} from "../../store";
import Utils from "../../utils";
import configDb from "../../config";

let tableLengthList = configDb.data.dataTableLength;

function BranchTable(props) {
    const {uuid} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const company = useSelector(x => x.company.value);
    const [branchData, setBranchData] = useState({id: '', display_name: ''});
    const [tableList, setTableList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [modifyModal, setModifyModal] = useState(false);
    const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
    const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [delTables, setDelTables] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [tablesData, setTablesData] = useState({uuid: '', name: '', seating: ''});
    let errorsObj = {name: '', seating: ''};
    const [errors, setErrors] = useState(errorsObj);
    const [params, setParams] = useState({
        limit: tableLengthList[0],
        page: 1,
        search: "",
        branch_id: branchData.id,
        is_reload: false
    });
    useDocumentTitle(branchData.display_name + ' ' + props.t(`Tables`));

    useEffect(() => {
        if (company.business_category_name === configDb.data.restaurant && company.business_mode === 2) {
            if (!params.branch_id) {
                dispatch(loaderActions.loader(true));
                let data = encryptData({uuid: uuid, language: getLanguage()});
                getCheckBranch(data)
                    .then(response => {
                        response = decryptData(response.data);
                        setBranchData(response.data);
                        let obj = {...params};
                        obj.branch_id = response.data.id;
                        setParams(obj);
                        dispatch(loaderActions.loader(false));
                    })
                    .catch(err => {
                        dispatch(loaderActions.loader(false));
                        toast.error(Utils.getErrorMessage(err));
                        navigate('/branch');
                    });
            }
        } else {
            navigate('/403');
        }
    }, [uuid, navigate, company, dispatch, params]);

    useEffect(() => {
        if (params.branch_id) {
            let data = encryptData(params);
            setIsLoading(true);
            getBranchTables(data)
                .then(response => {
                    response = decryptData(response.data);
                    setTableList(response.data);
                    setTotalRecords(response.total);
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                });
        }
    }, [params]);

    const handleParams = (e, type) => {
        let paramsObj = {...params};
        if (type === 'length') {
            paramsObj.limit = e.target.value;
        } else if (type === 'search') {
            paramsObj.page = 1;
            paramsObj.search = e.target.value;
        } else if (type === 'pagination') {
            paramsObj.page = e;
        }
        setParams(paramsObj);
    }
    const onAddTable = (e) => {
        e.preventDefault();
        setTablesData({uuid: '', name: '', seating: ''});
        setModifyModal(true);
    }
    const onEditTable = (e, item) => {
        e.preventDefault();
        setTablesData({uuid: item.uuid, name: item.name, seating: item.seating});
        setModifyModal(true);
    };
    const onModifyModalClose = (e) => {
        setTablesData({uuid: '', name: '', seating: ''});
        setModifyModal(false);
        setErrors(errorsObj);
    };
    const handleTableName = (e) => {
        let data = {...tablesData};
        data.name = e.target.value;
        setTablesData(data);
    };
    const handleSeatingNumber = (e) => {
        let data = {...tablesData};
        let value = e.target.value;
        if (value.toString().length > 2) {
            return false;
        }
        data.seating = value;
        setTablesData(data);
    };
    const handleTableForm = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!tablesData.name.trim()) {
            errorObj.name = props.t(`RequiredTableName`);
            error = true;
        } else if (!tablesData.name.trim().match(configDb.data.alphaNumericSpace)) {
            errorObj.name = props.t(`OnlyAlphaNumeric`);
            error = true;
        }
        if (!tablesData.seating) {
            errorObj.seating = props.t(`RequiredSeating`);
            error = true;
        }
        setErrors(errorObj);
        if (error) return;
        let dataParams = {...params};
        setIsModifyButtonLoad(true);
        if (tablesData.uuid) {
            let data = encryptData({
                uuid: tablesData.uuid,
                name: tablesData.name.trim(),
                seating: tablesData.seating,
                branch_id: branchData.id,
                language: getLanguage()
            });
            actionUpdateTables(data)
                .then(response => {
                    response = decryptData(response.data);
                    toast.success(response.message);
                    dataParams.page = 1;
                    dataParams.is_reload = !dataParams.is_reload;
                    setParams(dataParams);
                    setTablesData({uuid: '', name: '', seating: ''});
                    setModifyModal(false);
                    setIsModifyButtonLoad(false);
                })
                .catch(err => {
                    toast.error(Utils.getErrorMessage(err));
                    setIsModifyButtonLoad(false);
                });
        } else {
            let data = encryptData({
                name: tablesData.name.trim(),
                seating: tablesData.seating,
                branch_id: branchData.id,
                language: getLanguage()
            });
            actionStoreTables(data)
                .then(response => {
                    response = decryptData(response.data);
                    toast.success(response.message);
                    dataParams.page = 1;
                    dataParams.is_reload = !dataParams.is_reload;
                    setParams(dataParams);
                    setTablesData({uuid: '', name: '', seating: ''});
                    setModifyModal(false);
                    setIsModifyButtonLoad(false);
                })
                .catch(err => {
                    toast.error(Utils.getErrorMessage(err));
                    setIsModifyButtonLoad(false);
                });
        }
    }
    const onDeleteTable = (e, item) => {
        e.preventDefault();
        setDelTables(item.uuid);
        setDelModal(true);
    };
    const onDeleteModalClose = (e) => {
        e.preventDefault();
        setDelTables('');
        setDelModal(false);
    };
    const onDestroyTables = (e) => {
        e.preventDefault();
        setIsDelButtonLoad(true);
        let data = encryptData({uuid: delTables});
        actionDeleteTables(data)
            .then(response => {
                let paramsObj = {...params};
                paramsObj.is_reload = !paramsObj.is_reload;
                setParams(paramsObj);
                response = decryptData(response.data);
                toast.success(response.message);
                setDelTables('');
                setDelModal(false);
                setIsDelButtonLoad(false);
            })
            .catch(err => {
                setIsDelButtonLoad(false);
                toast.error(Utils.getErrorMessage(err));
            });
    };
    return (
        <>
            <Breadcrumbs title={`Tables`} parentLink={'/branch'} parent={`Branch`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <Card className="b-t-primary">
                            <CardHeader className={`py-4`}>
                                <h5>
                                    <span>{props.t(`ListOfBranchTable`, {branch: branchData.display_name})}</span>
                                    <NavLink to={`/branch/${uuid}/table-layout`}
                                             role={`button`}
                                             className={`btn btn-square btn-primary float-right btn-sm ml-1`}>{props.t(`TableLayout`)}</NavLink>
                                    <Button color={`primary`} type={`button`}
                                            onClick={onAddTable}
                                            className={`btn-sm float-right btn-square`}>{props.t(`AddTable`)}</Button>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={1}>
                                        <FormGroup>
                                            <select name="length" className="form-control" value={params.limit}
                                                    onChange={(e) => handleParams(e, 'length')}>
                                                {tableLengthList && tableLengthList.map((item, i) =>
                                                    <option value={item} key={i}>{item}</option>
                                                )}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2} className={`offset-sm-9`}>
                                        <FormGroup>
                                            <Input type={`text`} value={params.search}
                                                   onChange={(e) => handleParams(e, 'search')}
                                                   className={`form-control`}
                                                   placeholder={props.t(`Search`)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <Table className={`table-bordered ${isLoading ? `loading` : ``}`}>
                                        <thead>
                                        <tr>
                                            <th scope={`col`}>#</th>
                                            <th scope={`col`}>{props.t(`TableName`)}</th>
                                            <th scope={`col`}>{props.t(`SeatingNumber`)}</th>
                                            <th scope={`col`}>{props.t(`IsUsed`)}</th>
                                            <th scope={`col`}>{props.t(`Action`)}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tableList && tableList.map((item, i) => (
                                            <tr key={i}>
                                                <td>{item.index}</td>
                                                <td>{item.name}</td>
                                                <td>{item.seating}</td>
                                                <td>
                                                    {item.is_used === 1 ?
                                                        <Badge color={`success`}>{item.used}</Badge> :
                                                        <Badge color={`danger`}>{item.used}</Badge>}
                                                </td>
                                                <td>
                                                    <Button outline color={`primary`} type={`button`}
                                                            onClick={(e) => onEditTable(e, item)}
                                                            className={`btn-sm mr-1 btn-square`}>{props.t(`Edit`)}</Button>

                                                    <Button outline color={`danger`} type={`button`}
                                                            onClick={(e) => onDeleteTable(e, item)}
                                                            className={`btn-sm btn-square`}>{props.t(`Delete`)}</Button>
                                                </td>
                                            </tr>
                                        ))}
                                        {tableList && tableList.length === 0 && (
                                            <tr>
                                                <td colSpan={5}
                                                    className={`text-center`}>{props.t(`RecordNotFound`)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                                <Row>
                                    <Col sm={12} className={`mt-3`}>
                                        <div className={`float-right`}>
                                            <Pagination
                                                activePage={params.page}
                                                itemsCountPerPage={parseInt(params.limit)}
                                                totalItemsCount={totalRecords}
                                                pageRangeDisplayed={5}
                                                itemClass={`page-item`}
                                                linkClass={`page-link`}
                                                onChange={(e) => handleParams(e, 'pagination')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={modifyModal}>
                <ModalHeader>{tablesData.uuid ? props.t(`Edit`) : props.t(`Create`)}</ModalHeader>
                <Form method={`post`} onSubmit={handleTableForm}>
                    <ModalBody>
                        <FormGroup>
                            <Label className="col-form-label">{props.t('TableName')}
                                <span className={`text-danger ml-1`}>*</span></Label>
                            <Input type={`text`} name={`table_name`} value={tablesData.name} onChange={handleTableName}
                                   placeholder={props.t(`EnterTableName`)} maxLength={20}/>
                            {errors.name && (
                                <span className={`invalid-feedback`}>{errors.name}</span>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label className="col-form-label">{props.t('SeatingNumber')}
                                <span className={`text-danger ml-1`}>*</span></Label>
                            <Input type="number" name={`seating_number`} value={tablesData.seating}
                                   onChange={handleSeatingNumber} min={0}
                                   onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                   placeholder={props.t(`EnterSeatingNumber`)} maxLength={2}/>
                            {errors.seating && (
                                <span className={`invalid-feedback`}>{errors.seating}</span>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type={`button`} disabled={isModifyButtonLoad} outline
                                onClick={onModifyModalClose} className={`btn-square`}>{props.t(`Close`)}</Button>
                        {!isModifyButtonLoad && (
                            <Button color="success" type={`submit`}
                                    className={`btn-square`}>{props.t(`Submit`)}</Button>
                        )}
                        {isModifyButtonLoad && (
                            <Button color="success" type={`button`} disabled={true} className={`btn-square`}>
                                <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                            </Button>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
            <Modal isOpen={delModal}>
                <ModalHeader>{props.t(`DeleteTables`)}</ModalHeader>
                <ModalBody>
                    <p className={`mb-0`}>{props.t(`DeleteWarn`)}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={`button`} disabled={isDelButtonLoad} outline className={`btn-square`}
                            onClick={onDeleteModalClose}>{props.t(`Close`)}</Button>
                    {!isDelButtonLoad && (
                        <Button color="secondary" type={`button`} className={`btn-square`}
                                onClick={onDestroyTables}>{props.t(`Delete`)}</Button>
                    )}
                    {isDelButtonLoad && (
                        <Button color="secondary" type={`button`} disabled={true} className={`btn-square`}>
                            <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </>
    )
}

export default translate(BranchTable);