import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getPaymentList(data) {
    return axiosInstance.post(`${hostName}/payment-method/list`, data);
}

export function getAllPaymentList(data) {
    return axiosInstance.post(`${hostName}/payment-method/list-all`, data);
}

export function actionStorePayment(data) {
    return axiosInstance.post(`${hostName}/payment-method/create`, data);
}

export function getPaymentDetail(data) {
    return axiosInstance.post(`${hostName}/payment-method/detail`, data);
}

export function actionUpdatePayment(data) {
    return axiosInstance.post(`${hostName}/payment-method/update`, data);
}

export function actionDeletePaymentMethod(data) {
    return axiosInstance.post(`${hostName}/payment-method/delete`, data);
}