import React from "react";
import {Routes, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {Provider} from 'react-redux';

import {store} from "./store";
import {PrivateRoute, PublicRoute} from "./utils/functions";

import Layouts from "./views/layouts";
import Login from "./views/auth/Login";
import ForgotPassword from "./views/auth/ForgotPassword";
import ResetPassword from "./views/auth/ResetPassword";
import SetPassword from "./views/auth/SetPassword";

import AuthLayout from "./views/layouts/AuthLayout";
import Dashboard from "./views/Dashboard";
import Profile from "./views/Profile";
import Roles from "./views/roles";
import Branch from "./views/branch";
import CreateBranch from "./views/branch/Create";
import EditBranch from "./views/branch/Edit";
import BranchTable from "./views/branch-table";
import BranchTableLayout from "./views/branch-table/Layout";
import BranchQrMenu from "./views/branch-qr-menu";
import Employee from "./views/employee";
import CreateEmployee from "./views/employee/Create";
import EditEmployee from "./views/employee/Edit";
import Tax from "./views/tax";
import CreateTax from "./views/tax/Create";
import EditTax from "./views/tax/Edit";
import Supplier from "./views/supplier";
import CreateSupplier from "./views/supplier/Create";
import EditSupplier from "./views/supplier/Edit";
import Units from "./views/units";
import CreateUnits from "./views/units/Create";
import EditUnits from "./views/units/Edit";
import Customers from "./views/customers";
import CreateCustomers from "./views/customers/Create";
import EditCustomers from "./views/customers/Edit";
import Category from "./views/category";
import CreateCategory from "./views/category/Create";
import EditCategory from "./views/category/Edit";
import Tags from "./views/tags";
import Product from "./views/product";
import CreateProduct from "./views/product/Create";
import EditProduct from "./views/product/Edit";
import Error404 from "./views/errors/Error404";
import Error500 from "./views/errors/Error500";
import SecureRoute from "./utils/SecureRoute";
import Settings from "./views/settings";
import Permission from "./views/roles/Permission";
import Error403 from "./views/errors/Error403";
import CheckPermission from "./utils/CheckPermission";
import DisplayBanner from "./views/display-banner";
import CreateDisplayBanner from "./views/display-banner/Create";
import EditDisplayBanner from "./views/display-banner/Edit";
import Reservation from "./views/reservation";
import PromoCode from "./views/promocode";
import CreatePromoCode from "./views/promocode/Create";
import EditPromoCode from "./views/promocode/Edit";
import PaymentMethod from "./views/payment-method";
import CreatePaymentMethod from "./views/payment-method/Create";
import EditPaymentMethod from "./views/payment-method/Edit";
import Orders from "./views/orders";
import ViewOrders from "./views/orders/detail";

function App() {
    return (
        <>
            <Provider store={store}>
                <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick
                                rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
                <Routes>
                    <Route path={'/'} element={<SecureRoute/>}>
                        <Route path={'/'} element={<PublicRoute/>}>
                            <Route path={'/'} element={<AuthLayout/>}>
                                <Route path={`/`} element={<Login/>}/>
                                <Route path={`/login`} element={<Login/>}/>
                                <Route path={`/forgot-password`} element={<ForgotPassword/>}/>
                                <Route path={`/reset-password/:token`} element={<ResetPassword/>}/>
                                <Route path={`/set-password/:token`} element={<SetPassword/>}/>
                            </Route>
                        </Route>

                        <Route path={'/'} element={<PrivateRoute/>}>
                            <Route path={'/'} element={<CheckPermission/>}>
                                <Route path={`/`} element={<Layouts/>}>
                                    <Route path={`/dashboard`} element={<Dashboard/>}/>
                                    <Route path={`/profile`} element={<Profile/>}/>
                                    <Route path={`/setting`} element={<Settings/>}/>
                                    <Route path={`/roles`} element={<Roles/>}/>
                                    <Route path={`/roles/:uuid/permissions`} element={<Permission/>}/>

                                    <Route path={`/branch`} element={<Branch/>}/>
                                    <Route path={`/branch/create`} element={<CreateBranch/>}/>
                                    <Route path={`/branch/:uuid/edit`} element={<EditBranch/>}/>
                                    <Route path={`/branch/:uuid/table`} element={<BranchTable/>}/>
                                    <Route path={`/branch/:uuid/table-layout`} element={<BranchTableLayout/>}/>
                                    <Route path={`/branch/:uuid/qr-menu`} element={<BranchQrMenu/>}/>

                                    <Route path={`/employee`} element={<Employee/>}/>
                                    <Route path={`/employee/create`} element={<CreateEmployee/>}/>
                                    <Route path={`/employee/:uuid/edit`} element={<EditEmployee/>}/>

                                    <Route path={`/tax`} element={<Tax/>}/>
                                    <Route path={`/tax/create`} element={<CreateTax/>}/>
                                    <Route path={`/tax/:uuid/edit`} element={<EditTax/>}/>

                                    <Route path={`/display-banner`} element={<DisplayBanner/>}/>
                                    <Route path={`/display-banner/create`} element={<CreateDisplayBanner/>}/>
                                    <Route path={`/display-banner/:uuid/edit`} element={<EditDisplayBanner/>}/>

                                    <Route path={`/promo-code`} element={<PromoCode/>}/>
                                    <Route path={`/promo-code/create`} element={<CreatePromoCode/>}/>
                                    <Route path={`/promo-code/:uuid/edit`} element={<EditPromoCode/>}/>

                                    <Route path={`/payment-method`} element={<PaymentMethod/>}/>
                                    <Route path={`/payment-method/create`} element={<CreatePaymentMethod/>}/>
                                    <Route path={`/payment-method/:uuid/edit`} element={<EditPaymentMethod/>}/>

                                    <Route path={`/supplier`} element={<Supplier/>}/>
                                    <Route path={`/supplier/create`} element={<CreateSupplier/>}/>
                                    <Route path={`/supplier/:uuid/edit`} element={<EditSupplier/>}/>

                                    <Route path={`/product-tags`} element={<Tags entity_type={1}/>}/>
                                    <Route path={`/customer-tags`} element={<Tags entity_type={2}/>}/>

                                    <Route path={`/customers`} element={<Customers/>}/>
                                    <Route path={`/customers/create`} element={<CreateCustomers/>}/>
                                    <Route path={`/customers/:uuid/edit`} element={<EditCustomers/>}/>

                                    <Route path={`/units`} element={<Units/>}/>
                                    <Route path={`/units/create`} element={<CreateUnits/>}/>
                                    <Route path={`/units/:uuid/edit`} element={<EditUnits/>}/>

                                    <Route path={`/category`} element={<Category/>}/>
                                    <Route path={`/category/create`} element={<CreateCategory/>}/>
                                    <Route path={`/category/:uuid/edit`} element={<EditCategory/>}/>

                                    <Route path={`/products`} element={<Product/>}/>
                                    <Route path={`/products/create`} element={<CreateProduct/>}/>
                                    <Route path={`/products/:uuid/edit`} element={<EditProduct/>}/>

                                    <Route path={`/reservation`} element={<Reservation/>}/>

                                    <Route path={`/orders`} element={<Orders/>}/>
                                    <Route path={`/orders/:uuid`} element={<ViewOrders/>}/>
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route path={'*'} element={<Error404/>}/>
                    <Route path={'/404'} element={<Error404/>}/>
                    <Route path={'/403'} element={<Error403/>}/>
                    <Route path={'/500'} element={<Error500/>}/>
                </Routes>
            </Provider>
        </>
    );
}

export default App;