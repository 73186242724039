import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getRoleList(data) {
    return axiosInstance.post(`${hostName}/roles/list`, data);
}

export function getAllRoleList() {
    return axiosInstance.get(`${hostName}/roles/list`);
}

export function actionStoreRole(data) {
    return axiosInstance.post(`${hostName}/roles/create`, data);
}

export function actionUpdateRole(data) {
    return axiosInstance.post(`${hostName}/roles/update`, data);
}

export function actionDelRole(data) {
    return axiosInstance.post(`${hostName}/roles/delete`, data);
}

export function actionGetRolePermission(data) {
    return axiosInstance.post(`${hostName}/roles/permission`, data);
}

export function actionModifyRolePermission(data) {
    return axiosInstance.post(`${hostName}/roles/permission-update`, data);
}

export function actionGetUserPermission(data) {
    return axiosInstance.post(`${hostName}/roles/user-permission`, data);
}