import React from "react";
import {Button, Col, Container, Media} from "reactstrap";
import {NavLink} from "react-router-dom";

import sadImg from "../../assets/images/other-images/sad.png";
import useDocumentTitle from "../../utils/useDocumentTitle";

function Error403() {

    useDocumentTitle(403);

    return (
        <div className="page-wrapper">
            <div className="error-wrapper">
                <Container>
                    <Media body className="img-100" src={sadImg} alt=""/>
                    <div className="error-heading">
                        <h2 className="headline font-success">{"403"}</h2>
                    </div>
                    <Col md="8 offset-md-2">
                        <p className="sub-content">{"The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved."}</p>
                    </Col>
                    <NavLink to={`/dashboard`}>
                        <Button color="success-gradien" size='lg'>{`BACK TO HOME PAGE`}</Button>
                    </NavLink>
                </Container>
            </div>
        </div>
    )
}

export default Error403;