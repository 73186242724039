import React, {useEffect, useState} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {decryptData, encryptData} from "./crypto";
import {actionGetUserPermission} from "../services/Role";
import {permissionActions} from "../store";
import Utils from "./index";

function CheckPermission() {
    const auth = useSelector(x => x.auth.value);
    const loginRoleId = auth.role_id;
    const permission = useSelector(x => x.permission.value);
    const dispatch = useDispatch();
    const [loadStep, setLoadStep] = useState(1);
    const [status, setStatus] = useState(500);

    useEffect(() => {
        let data = encryptData({role_id: loginRoleId});
        actionGetUserPermission(data)
            .then(response => {
                setLoadStep(2);
                response = decryptData(response.data);
                dispatch(permissionActions.permission(response.data));
            })
            .catch(err => {
                setLoadStep(3);
            });
    }, [loginRoleId, dispatch]);

    useEffect(() => {
        if (loadStep === 2) {
            let pathName = window.location.pathname;
            if (pathName === '/dashboard' || pathName === '/profile') {
                setStatus(200);
                setLoadStep(3);
            } else {
                if (permission && permission.length > 0) {
                    let permissionName = Utils.getPermissionName(pathName);
                    if (permission.indexOf(permissionName) > -1) {
                        setStatus(200);
                        setLoadStep(3);
                    } else {
                        setStatus(500);
                        setLoadStep(3);
                    }
                } else {
                    setStatus(500);
                    setLoadStep(3);
                }
            }
        }
    }, [permission, loadStep]);

    return (
        <>
            {loadStep === 1 && (
                <div className={`loader-wrapper`}>
                    <div className="loader-index"><span></span></div>
                    <svg>
                        <defs></defs>
                        <filter id="goo">
                            <fegaussianblur in="SourceGraphic" stdDeviation="11" result="blur"></fegaussianblur>
                            <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                           result="goo"></fecolormatrix>
                        </filter>
                    </svg>
                </div>
            )}
            {loadStep === 3 &&
                <>
                    {status === 200 ? <Outlet/> : <Navigate to={`/403`}/>}
                </>
            }
        </>
    )
}

export default CheckPermission;