import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getDisplayBannerList(data) {
    return axiosInstance.post(`${hostName}/display-banner/list`, data);
}

export function actionStoreDisplayBanner(data) {
    return axiosInstance.post(`${hostName}/display-banner/create`, data);
}

export function actionGetDisplayBanner(data) {
    return axiosInstance.post(`${hostName}/display-banner/detail`, data);
}

export function actionUpdateDisplayBanner(data) {
    return axiosInstance.post(`${hostName}/display-banner/update`, data);
}

export function actionDeleteDisplayBanner(data) {
    return axiosInstance.post(`${hostName}/display-banner/delete`, data);
}