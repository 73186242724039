import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Select from "react-select";

import Breadcrumbs from "../partials/Breadcrumbs";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import useDocumentTitle from "../../utils/useDocumentTitle";
import configDb from "../../config";
import {actionCountryList, actionTimeZoneList} from "../../services/Common";
import {decryptData, encryptData} from "../../utils/crypto";
import validator from "validator";
import {toast} from "react-toastify";
import Utils from "../../utils";
import {actionUpdateSupplier, getSupplierDetails} from "../../services/Supplier";
import {useDispatch} from "react-redux";
import {loaderActions} from "../../store";

let statusList = configDb.data.statusList;

function EditSupplier(props) {
    const dispatch = useDispatch();
    const {uuid} = useParams();
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [countryId, setCountryId] = useState('');
    const [timezoneId, setTimezoneId] = useState('');
    const [postCode, setPostCode] = useState('');
    const [fax, setFax] = useState('');
    const [website, setWebsite] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [countryList, setCountryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectCountry, setSelectCountry] = useState(null);
    const [selectTimezone, setSelectTimezone] = useState(null);
    const [isTimezoneLoad, setIsTimezoneLoad] = useState(false);
    const [timeZoneList, setTimeZoneList] = useState([]);
    useDocumentTitle(props.t('EditSupplier'));
    const errorsObj = {
        fullName: "",
        contactName: "",
        email: "",
        contact_number: "",
        address_1: "",
        city: "",
        state: "",
        country_id: "",
        timezone_id: "",
        postcode: "",
        status_id: ""
    };
    const [errors, setErrors] = useState(errorsObj);

    useEffect(function () {
        dispatch(loaderActions.loader(true));
        let data = encryptData({uuid: uuid, language: getLanguage()});
        getSupplierDetails(data)
            .then(response => {
                response = decryptData(response.data);
                setFullName((response?.data?.full_name) ? response?.data?.full_name : '');
                setContactName((response?.data?.contact_name) ? response.data?.contact_name : '');
                setEmail((response?.data?.email) ? response.data?.email : '');
                setContactNumber((response?.data?.contact_number) ? response.data.contact_number : '');
                setWebsite((response?.data?.website) ? response.data.website : '');
                setFax((response?.data?.fax) ? response.data.fax : '');
                setAddress1((response?.data?.address_1) ? response.data.address_1 : '');
                setAddress2((response?.data?.address_2) ? response.data.address_2 : '');
                setCity((response?.data?.city) ? response.data.city : '');
                setState((response?.data?.state) ? response.data.state : '');
                setPostCode((response?.data?.postcode) ? response.data.postcode : '');
                setStatusId((response?.data?.status_id) ? response.data.status_id : '');
                setTimezoneId(response.data.timezone_id);
                actionCountryList()
                    .then(result => {
                        result = decryptData(result.data);
                        let list = [];
                        for (let i = 0; i < result.data.length; i++) {
                            list.push({
                                value: result.data[i].id,
                                label: result.data[i].name
                            });
                        }
                        setCountryList(list);
                        let index = list.findIndex(x => parseInt(x.value) === parseInt(response.data.country_id));
                        if (index > -1) {
                            setSelectCountry(list[index]);
                            setCountryId(response.data.country_id);
                        }
                    })
                    .catch(err => {
                    });
                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/supplier');
            })
    }, [uuid, navigate, dispatch]);
    useEffect(() => {
        if (countryId) {
            setIsTimezoneLoad(true);
            let data = encryptData({country_id: countryId});
            actionTimeZoneList(data)
                .then(response => {
                    response = decryptData(response.data);
                    let list = [];
                    for (let i = 0; i < response.data.length; i++) {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].name
                        });
                    }
                    setTimeZoneList(list);
                    setIsTimezoneLoad(false);
                    let index = list.findIndex(x => parseInt(x.value) === parseInt(timezoneId));
                    if (index > -1) {
                        setSelectTimezone(list[index]);
                        setTimezoneId(timezoneId);
                    }
                })
                .catch(err => {
                    setIsTimezoneLoad(false);
                });
        }
    }, [countryId, timezoneId]);
    const onUpdateTimezone = (e) => {
        setSelectTimezone(null);
        setTimezoneId('');
        setCountryId((e?.value) ? e.value : '');
        setSelectCountry(e);
    }
    const handleTimezone = (e) => {
        setSelectTimezone(e)
        setTimezoneId((e?.value) ? e.value : '');
    }
    const onUpdate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!fullName) {
            errorObj.fullName = props.t('RequiredFullName');
            error = true;
        }
        if (!contactName) {
            errorObj.contactName = props.t('RequiredContactName');
            error = true;
        }
        if (!email) {
            errorObj.email = props.t('RequiredEmail');
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = props.t('InValidEmail');
            error = true;
        }
        if (!contactNumber) {
            errorObj.contact_number = props.t('RequiredContactNumber');
            error = true;
        } else if (!validator.isMobilePhone(contactNumber)) {
            errorObj.contact_number = props.t('InValidNumber');
            error = true;
        } else if (contactNumber && contactNumber.length !== 10) {
            errorObj.contact_number = props.t('InValidNumber');
            error = true;
        }
        if (website && !validator.isURL(website)) {
            errorObj.website = props.t('InValidURL');
            error = true;
        }
        if (countryId && !timezoneId) {
            errorObj.timezone_id = props.t('RequiredTimeZone');
            error = true;
        }
        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);

        let objectUpdate = encryptData({
            uuid: uuid,
            language: getLanguage(),
            full_name: fullName,
            contact_name: contactName,
            email: email,
            contact_number: contactNumber,
            address_1: address1,
            address_2: address2,
            city: city,
            state: state,
            country_id: countryId,
            timezone_id: timezoneId,
            fax: fax,
            website: website,
            postcode: postCode,
            status_id: statusId
        });
        actionUpdateSupplier(objectUpdate)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/supplier');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }
    return (
        <>
            <Breadcrumbs parentLink={'/supplier'} parent={`Supplier`} title={`EditSupplier`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onUpdate}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`FullName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`full_name`}
                                                       value={fullName} onChange={(e) => setFullName(e.target.value)}
                                                       placeholder={props.t(`EnterFullName`)} maxLength={75}/>
                                                {errors.fullName && (
                                                    <span className={`invalid-feedback`}>{errors.fullName}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ContactName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`contact_name`}
                                                       value={contactName}
                                                       onChange={(e) => setContactName(e.target.value)}
                                                       placeholder={props.t(`EnterContactName`)} maxLength={75}/>
                                                {errors.contactName && (
                                                    <span className={`invalid-feedback`}>{errors.contactName}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`EmailAddress`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`email`}
                                                       value={email} onChange={(e) => setEmail(e.target.value)}
                                                       placeholder={props.t(`EnterEmailAddress`)} maxLength={75}/>
                                                {errors.email && (
                                                    <span className={`invalid-feedback`}>{errors.email}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ContactNumber`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text"
                                                       name={`contact_number`}
                                                       value={contactNumber}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                       onChange={(e) => setContactNumber(e.target.value)}
                                                       placeholder={props.t(`EnterContactNumber`)} maxLength={10}
                                                />
                                                {errors.contact_number && (
                                                    <span
                                                        className={`invalid-feedback`}>{errors.contact_number}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address1`)}</Label>
                                                <Input className="form-control" type="text" name={`address1`}
                                                       value={address1} onChange={(e) => setAddress1(e.target.value)}
                                                       placeholder={props.t(`EnterAddress1`)} maxLength={75}/>
                                                {errors.address_1 && (
                                                    <span className={`invalid-feedback`}>{errors.address_1}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address2`)}</Label>
                                                <Input className="form-control" type="text" name={`address2`}
                                                       value={address2} onChange={(e) => setAddress2(e.target.value)}
                                                       placeholder={props.t(`EnterAddress2`)} maxLength={75}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`city`)}</Label>
                                                <Input className="form-control" type="text" name={`city`}
                                                       value={city} onChange={(e) => setCity(e.target.value)}
                                                       placeholder={props.t(`EnterCity`)} maxLength={10}/>
                                                {errors.city && (
                                                    <span className={`invalid-feedback`}>{errors.city}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`state`)}</Label>
                                                <Input className="form-control" type="text" name={`state`}
                                                       value={state} onChange={(e) => setState(e.target.value)}
                                                       placeholder={props.t(`EnterState`)} maxLength={10}/>
                                                {errors.state && (
                                                    <span className={`invalid-feedback`}>{errors.state}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Country`)} </Label>
                                                <Select options={countryList} onChange={onUpdateTimezone}
                                                        value={selectCountry} isClearable={true}
                                                        placeholder={props.t(`PleaseSelectCountry`)}
                                                        name={`country_id`}/>
                                                {errors.countryId && (
                                                    <span className={`invalid-feedback`}>{errors.countryId}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`TimeZone`)}</Label>
                                                <Select name={`time_zone`} options={timeZoneList}
                                                        value={selectTimezone} isLoading={isTimezoneLoad}
                                                        placeholder={props.t(`PleaseSelectTimezone`)} isClearable={true}
                                                        onChange={handleTimezone}/>
                                                {errors.timezone_id && (
                                                    <span className={`invalid-feedback`}>{errors.timezone_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`postcode`)}</Label>
                                                <Input className="form-control" type="text" name={`postcode`}
                                                       value={postCode} onChange={(e) => setPostCode(e.target.value)}
                                                       placeholder={props.t(`EnterPostCode`)} maxLength={10}/>
                                                {errors.postcode && (
                                                    <span className={`invalid-feedback`}>{errors.postcode}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`website`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`website`}
                                                       value={website}
                                                       onChange={(e) => setWebsite(e.target.value)}
                                                       placeholder={props.t(`EnterWebsite`)}/>
                                                {errors.website && (
                                                    <span className={`invalid-feedback`}>{errors.website}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`fax`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`fax`}
                                                       value={fax}
                                                       onChange={(e) => setFax(e.target.value)}
                                                       placeholder={props.t(`EnterFax`)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input
                                                    id="exampleSelect"
                                                    name="select"
                                                    value={statusId}
                                                    onChange={(e) => setStatusId(e.target.value)}
                                                    type="select">
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                                {errors.status_id && (
                                                    <span className={`invalid-feedback`}>{errors.status_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={`/supplier`} role={`button`} end
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} className={`btn-square`}
                                                        disabled={true}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(EditSupplier);