import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import Breadcrumbs from "../partials/Breadcrumbs";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
    Table,
    Form
} from "reactstrap";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import configDb from "../../config";
import {decryptData, encryptData} from "../../utils/crypto";
import {actionDeleteSupplier, actionImportSupplier, getSupplierList} from "../../services/Supplier";
import {toast} from "react-toastify";
import Utils from "../../utils";
import Pagination from "react-js-pagination";
import Select from "react-select";
import useDocumentTitle from "../../utils/useDocumentTitle";

const tableLengthList = configDb.data.dataTableLength;
const statusList = configDb.data.statusSelect2List;

function Supplier(props) {
    const allPermissionsList = useSelector(x => x.permission.value);
    const [supplierList, setSupplierList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [delModal, setDelModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
    const [isImportButtonLoad, setIsImportButtonLoad] = useState(false);
    const [delSupplier, setDelSupplier] = useState('');
    const [importFile, setImportFile] = useState([]);
    const [keyFile, setKeyFile] = useState(Date.now);
    const [isLoading, setIsLoading] = useState(false);
    useDocumentTitle(props.t('Supplier'));
    const [params, setParams] = useState({
        limit: tableLengthList[0],
        page: 1,
        search: "",
        is_reload: false,
        status_id: ''
    });
    let errorsObj = {import_file: ''};
    const [errors, setErrors] = useState(errorsObj);
    const [importError, setImportError] = useState('');
    const [importSuccess, setImportSuccess] = useState('');
    useEffect(function () {
        let data = encryptData(params);
        setIsLoading(true);
        getSupplierList(data)
            .then(response => {
                response = decryptData(response.data);
                setTotalRecords(response.total);
                setSupplierList(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
            });
    }, [params]);
    const handleParams = (e, type) => {
        let paramsObj = {...params};
        if (type === 'length') {
            paramsObj.limit = e.target.value;
        } else if (type === 'search') {
            paramsObj.page = 1;
            paramsObj.search = e.target.value;
        } else if (type === 'pagination') {
            paramsObj.page = e;
        } else if (type === 'status') {
            paramsObj.page = 1;
            paramsObj.status_id = (e?.value) ? e?.value : '';
        }
        setParams(paramsObj);
    }
    const onDeleteSupplier = (e, item) => {
        e.preventDefault();
        setDelSupplier(item.uuid);
        setDelModal(true);
    }
    const onDeleteModalClose = (e) => {
        e.preventDefault();
        setDelSupplier('');
        setDelModal(false);
    }
    const onDestroySupplier = (e) => {
        e.preventDefault();
        setIsDelButtonLoad(true);
        let data = encryptData({uuid: delSupplier, language: getLanguage()});
        actionDeleteSupplier(data)
            .then(response => {
                let paramsObj = {...params};
                paramsObj.page = 1;
                paramsObj.is_reload = !paramsObj.is_reload;
                setParams(paramsObj);
                response = decryptData(response.data);
                toast.success(response.message);
                setDelSupplier('');
                setDelModal(false);
                setIsDelButtonLoad(false);
            })
            .catch(err => {
                setIsDelButtonLoad(false);
                toast.error(Utils.getErrorMessage(err));
            });
    }
    const onImportSupplier = (e) => {
        e.preventDefault();
        setImportModal(true);
    };
    const onImportModalClose = (e) => {
        e.preventDefault();
        setImportModal(false);
        setImportFile([]);
        let errorObj = {...errorsObj};
        setErrors(errorObj);
        setImportSuccess('');
        setImportError('');
    }
    const onImport = (e) => {
        e.preventDefault();
        let error = false;
        setImportSuccess('');
        setImportError('');
        let errorObj = {...errorsObj};
        if (importFile.length === 0) {
            errorObj.import_file = props.t('RequiredImportFile');
            error = true;
        } else if (!importFile[0].name.match(configDb.data.fileExt)) {
            errorObj.import_file = props.t('InvalidXLSXFile');
            error = true;
        }
        setErrors(errorObj);
        if (error) return;

        setIsImportButtonLoad(true);
        const formData = new FormData();
        formData.append('language', getLanguage());
        if (importFile.length > 0) {
            formData.append('import_file', importFile[0]);
        }
        actionImportSupplier(formData)
            .then(response => {
                response = decryptData(response.data);
                if (response.message) {
                    let paramsObj = {...params};
                    paramsObj.page = 1;
                    paramsObj.is_reload = !paramsObj.is_reload;
                    setParams(paramsObj);
                }
                setImportError(response.errorMessage);
                if (!response.errorMessage) {
                    setImportModal(false);
                    toast.success(response.message);
                } else {
                    setImportSuccess(response.message);
                }
                setKeyFile(Date.now);
                setImportFile([]);
                setIsImportButtonLoad(false);
            })
            .catch(err => {
                setIsImportButtonLoad(false);
                toast.error(Utils.getErrorMessage(err));
            });
    }
    return (
        <>
            <Breadcrumbs title={`Supplier`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardHeader className={`py-4`}>
                                <h5>
                                    <span>{props.t(`ListSupplier`)}</span>
                                    {allPermissionsList.indexOf('modify_supplier') > -1 && (
                                        <>
                                            <NavLink to={`/supplier/create`} role={`button`}
                                                     className={`btn btn-sm btn-square float-right btn-primary`}>{props.t(`AddSupplier`)}</NavLink>

                                            <Button color={`primary`} type={`button`}
                                                    onClick={(e) => onImportSupplier(e)}
                                                    className={`btn-sm btn-square float-right mr-1 `}>{props.t(`ImportSupplierByXlsx`)}</Button>
                                        </>

                                    )}
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={1}>
                                        <FormGroup>
                                            <select name="length" className="form-control" value={params.limit}
                                                    onChange={(e) => handleParams(e, 'length')}>
                                                {tableLengthList && tableLengthList.map((item, i) =>
                                                    <option value={item} key={i}>{item}</option>
                                                )}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2" className={`offset-sm-7`}>
                                        <FormGroup>
                                            <Select options={statusList} onChange={(e) => handleParams(e, 'status')}
                                                    placeholder={props.t(`Status`)}
                                                    isClearable={true}
                                                    name={`status_id`}/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Input type={`text`} value={params.search}
                                                   onChange={(e) => handleParams(e, 'search')}
                                                   className={`form-control`}
                                                   placeholder={props.t(`Search`)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <Table className={`table-bordered ${isLoading ? `loading` : ``}`}>
                                        <thead>
                                        <tr>
                                            <th scope={`col`}>#</th>
                                            <th scope={`col`}>{props.t(`FullName`)}</th>
                                            <th scope={`col`}>{props.t(`ContactName`)}</th>
                                            <th scope={`col`}>{props.t(`EmailAddress`)}</th>
                                            <th scope={`col`}>{props.t(`ContactNumber`)}</th>
                                            <th scope={`col`}>{props.t(`IsActive`)}</th>
                                            {(allPermissionsList.indexOf('modify_supplier') > -1 || allPermissionsList.indexOf('delete_supplier') > -1) && (
                                                <th scope={`col`}>{props.t(`Action`)}</th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {supplierList && supplierList.map((item, i) =>
                                            <tr key={i}>
                                                <td>{item.index}</td>
                                                <td>{item.full_name}</td>
                                                <td>{item.contact_name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.contact_number}</td>
                                                <td>
                                                    {item.status_id === 1 ?
                                                        <Badge color={`success`}>{item.is_active}</Badge> :
                                                        <Badge color={`danger`}>{item.is_active}</Badge>}
                                                </td>
                                                {(allPermissionsList.indexOf('modify_supplier') > -1 || allPermissionsList.indexOf('delete_supplier') > -1) && (
                                                    <td>
                                                        {allPermissionsList.indexOf('modify_supplier') > -1 && (
                                                            <NavLink to={`/supplier/${item.uuid}/edit`}
                                                                     role={`button`}
                                                                     className={`btn btn-square btn-outline-primary btn-sm mr-1`}>{props.t(`Edit`)}</NavLink>
                                                        )}
                                                        {allPermissionsList.indexOf('delete_supplier') > -1 && (
                                                            <Button outline color={`danger`} type={`button`}
                                                                    onClick={(e) => onDeleteSupplier(e, item)}
                                                                    className={`btn-sm btn-square`}>{props.t(`Delete`)}</Button>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                        {supplierList && supplierList.length === 0 && (
                                            <tr>
                                                <td colSpan={(allPermissionsList.indexOf('modify_supplier') > -1 || allPermissionsList.indexOf('delete_supplier') > -1) ? 7 : 6}
                                                    className={`text-center`}>{props.t(`RecordNotFound`)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                                <Row>
                                    <Col sm={12} className={`mt-3`}>
                                        <div className={`float-right`}>
                                            <Pagination
                                                activePage={params.page}
                                                itemsCountPerPage={parseInt(params.limit)}
                                                totalItemsCount={totalRecords}
                                                pageRangeDisplayed={5}
                                                itemClass={`page-item`}
                                                linkClass={`page-link`}
                                                onChange={(e) => handleParams(e, 'pagination')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={delModal}>
                <ModalHeader>{props.t(`DeleteSupplier`)}</ModalHeader>
                <ModalBody>
                    <p className={`mb-0`}>{props.t(`DeleteWarn`)}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={`button`} disabled={isDelButtonLoad} outline
                            onClick={onDeleteModalClose} className={`btn-square`}>{props.t(`Close`)}</Button>
                    {!isDelButtonLoad && (
                        <Button color="secondary" type={`button`} className={`btn-square`}
                                onClick={onDestroySupplier}>{props.t(`Delete`)}</Button>
                    )}
                    {isDelButtonLoad && (
                        <Button color="secondary" type={`button`} disabled={true} className={`btn-square`}>
                            <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
            <Modal isOpen={importModal}>
                <ModalHeader>{props.t(`ImportSupplierByXlsx`)}</ModalHeader>

                <Form method={`post`} onSubmit={onImport} encType={`multipart/form-data`}>
                    <ModalBody>
                        <p className={`mb-0`}>{props.t(`QuicklySuppliersNote`)} <NavLink
                            to={`/files/supplier_sample.xlsx`}
                            target={`_blank`}>{props.t(`XlsxTemplate`)}</NavLink>
                        </p>
                        <p className={`mb-0`}>{props.t(`SuppliersNote`)}</p>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label className="col-form-label">{props.t(`FileImport`)}<span
                                        className={`text-danger ml-1`}>*</span></Label>
                                    <Input className="form-control" type="file"
                                           onChange={(e) => setImportFile([e.target.files[0]])}
                                           key={keyFile}/>
                                    {errors.import_file && (
                                        <span className={`invalid-feedback`}>{errors.import_file}</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                {importSuccess && (
                                    <div className={`alert alert-success`}
                                         dangerouslySetInnerHTML={{__html: importSuccess}}/>
                                )}
                                {importError && (
                                    <div className={`alert alert-danger`}
                                         dangerouslySetInnerHTML={{__html: importError}}/>
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type={`button`} disabled={isImportButtonLoad} outline
                                onClick={onImportModalClose} className={`btn-square`}>{props.t(`Close`)}</Button>
                        {!isImportButtonLoad && (
                            <Button color="success" type={`submit`}
                                    className={`btn-square`}>{props.t(`Import`)}</Button>
                        )}
                        {isImportButtonLoad && (
                            <Button color="secondary" type={`button`} disabled={true} className={`btn-square`}>
                                <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                            </Button>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default translate(Supplier);