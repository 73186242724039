import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Media, Row} from "reactstrap";
import {translate, getLanguage} from "react-switch-lang";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

import Breadcrumbs from "../partials/Breadcrumbs";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {decryptData, encryptData} from "../../utils/crypto";
import Select from "react-select";
import {actionCountryList, actionTimeZoneList} from "../../services/Common";
import Utils from "../../utils";
import validator from "validator";
import configDb from "../../config";
import {actionGetBranch, actionUpdateBranch} from "../../services/Branch";
import {toast} from "react-toastify";
import {loaderActions} from "../../store";


let statusList = configDb.data.statusList;

function EditBranch(props) {
    const dispatch = useDispatch();
    const {uuid} = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [detail, setDetail] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [address_1, setAddress1] = useState('');
    const [address_2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [countryId, setCountryId] = useState('');
    const [countryList, setCountryList] = useState('');
    const [selectCountry, setSelectCountry] = useState(null);
    const [selectTimezone, setSelectTimezone] = useState(null);
    const [isTimezoneLoad, setIsTimezoneLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [timezoneId, setTimezoneId] = useState('');
    const [postcode, setPostCode] = useState('');
    const [website, setWebsite] = useState('');
    const [business_brn, setBusiness_brn] = useState('');
    const [sstId, setSstId] = useState('');
    const [gstId, setGstId] = useState('');
    const [statusId, setStatusId] = useState('');
    const [image, setImage] = useState('');
    const [imageFile, setImageFile] = useState([]);
    const [selfPickupCharge, setSelfPickupCharge] = useState('');
    const errorsObj = {
        name: "",
        display_name: "",
        email: "",
        contact_number: "",
        address_1: "",
        city: "",
        state: "",
        country_id: "",
        timezone_id: "",
        postcode: "",
        website: "",
        status_id: ""
    };
    const [errors, setErrors] = useState(errorsObj);
    useDocumentTitle(props.t('EditBranch'));
    useEffect(function () {
        dispatch(loaderActions.loader(true));
        let data = encryptData({uuid: uuid, language: getLanguage()});
        actionGetBranch(data)
            .then(response => {
                response = decryptData(response.data);
                setName(response.data.name);
                setDetail((response.data.detail) ? response.data.detail : '');
                setDisplayName(response.data.display_name);
                setEmail(response.data.email);
                setContactNumber(response.data.contact_number ? response.data.contact_number : '');
                setAddress1(response.data.address_1);
                setAddress2(response.data.address_2);
                setCity(response.data.city);
                setState(response.data.state);
                setTimezoneId(response.data.timezone_id);
                setPostCode(response.data.postcode);
                setWebsite(response.data.website);
                setStatusId(response.data.status_id);
                setBusiness_brn(response.data.business_brn);
                setSstId(response.data.sst_id);
                setGstId(response.data.gst_id);
                setSelfPickupCharge((response.data.take_away_charge) ? response.data.take_away_charge : '');
                if (response.data.image) {
                    setImage(response.data.image);
                }

                actionCountryList()
                    .then(result => {
                        result = decryptData(result.data);
                        let list = [];
                        for (let i = 0; i < result.data.length; i++) {
                            list.push({
                                value: result.data[i].id,
                                label: result.data[i].name
                            });
                        }
                        setCountryList(list);
                        let index = list.findIndex(x => parseInt(x.value) === parseInt(response.data.country_id));
                        if (index > -1) {
                            setSelectCountry(list[index]);
                            setCountryId(response.data.country_id);
                        }
                    })
                    .catch(err => {
                    });
                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/branch');
            });
    }, [uuid, navigate, dispatch]);

    useEffect(() => {
        if (countryId) {
            setIsTimezoneLoad(true);
            let data = encryptData({country_id: countryId});
            actionTimeZoneList(data)
                .then(response => {
                    response = decryptData(response.data);
                    let list = [];
                    for (let i = 0; i < response.data.length; i++) {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].name
                        });
                    }
                    setTimeZoneList(list);
                    setIsTimezoneLoad(false);
                    let index = list.findIndex(x => parseInt(x.value) === parseInt(timezoneId));
                    if (index > -1) {
                        setSelectTimezone(list[index]);
                        setTimezoneId(timezoneId);
                    }
                })
                .catch(err => {
                    setIsTimezoneLoad(false);
                });
        }
    }, [countryId, timezoneId]);
    const onUpdateTimezone = (e) => {
        setCountryId(e.value);
        setSelectCountry(e);
        setTimeZoneList([]);
        setSelectTimezone(null);
        setTimezoneId('');
    }
    const handleTimezone = (e) => {
        setSelectTimezone(e)
        setTimezoneId((e?.value) ? e.value : '');
    }
    const handleFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setImage(URL.createObjectURL(e.target.files[0]));
            setImageFile([e.target.files[0]]);
        } else {
            let data = Utils.loginCompanyDetail();
            setImage(data.image);
            setImageFile([]);
        }
    };
    const onUpdateBranch = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!name) {
            errorObj.name = props.t('RequiredName');
            error = true;
        }
        if (!displayName) {
            errorObj.display_name = props.t('RequiredDisplayName');
            error = true;
        }
        if (!email) {
            errorObj.email = props.t('RequiredEmail');
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = props.t('InValidEmail');
            error = true;
        }
        if (!contactNumber) {
            errorObj.contact_number = props.t('RequiredContactNumber');
            error = true;
        } else if (!validator.isMobilePhone(contactNumber)) {
            errorObj.contact_number = props.t('InValidNumber');
            error = true;
        } else if (contactNumber && contactNumber.length !== 10) {
            errorObj.contact_number = props.t('InValidNumber');
            error = true;
        }
        if (!address_1) {
            errorObj.address_1 = props.t('RequiredAddress1');
            error = true;
        }
        if (!timezoneId) {
            errorObj.timezone_id = props.t('RequiredTimeZone');
            error = true;
        }
        if (!countryId) {
            errorObj.countryId = props.t('RequiredCountry');
            error = true;
        }
        if (!city) {
            errorObj.city = props.t('RequiredCity');
            error = true;
        }
        if (!state) {
            errorObj.state = props.t('RequiredState');
            error = true;
        }
        if (!postcode) {
            errorObj.postcode = props.t('RequiredPostcode');
            error = true;
        }
        if (website && !validator.isURL(website)) {
            errorObj.website = props.t('InValidURL');
            error = true;
        }
        if (imageFile.length > 0) {
            if (!imageFile[0].name.match(configDb.data.imageExt)) {
                errorObj.image = props.t('InvalidImage');
                error = true;
            }
        }
        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('language', getLanguage());
        formData.append('uuid', uuid);
        formData.append('name', name);
        formData.append('display_name', displayName);
        formData.append('email', email);
        formData.append('contact_number', contactNumber);
        formData.append('address_1', address_1);
        formData.append('address_2', address_2);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('country_id', countryId);
        formData.append('timezone_id', timezoneId);
        formData.append('postcode', postcode);
        formData.append('website', website);
        formData.append('business_brn', business_brn);
        formData.append('sst_id', sstId);
        formData.append('gst_id', gstId);
        formData.append('status_id', statusId);
        formData.append('detail', detail);
        formData.append('take_away_charge', selfPickupCharge);
        if (imageFile.length > 0) {
            formData.append('image', imageFile[0]);
        }
        actionUpdateBranch(formData)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/branch');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }
    return (
        <>
            <Breadcrumbs parentLink={'/branch'} parent={`Branch`} title={`EditBranch`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onUpdateBranch} encType={`multipart/form-data`}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Name`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`name`}
                                                       value={name} onChange={(e) => setName(e.target.value)}
                                                       placeholder={props.t(`EnterName`)} maxLength={75}/>
                                                {errors.name && (
                                                    <span className={`invalid-feedback`}>{errors.name}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`DisplayName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`display_name`}
                                                       value={displayName}
                                                       onChange={(e) => setDisplayName(e.target.value)}
                                                       placeholder={props.t(`EnterDisplayName`)} maxLength={75}/>
                                                {errors.display_name && (
                                                    <span className={`invalid-feedback`}>{errors.display_name}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`EmailAddress`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`email`}
                                                       value={email} onChange={(e) => setEmail(e.target.value)}
                                                       placeholder={props.t(`EnterEmailAddress`)} maxLength={75}/>
                                                {errors.email && (
                                                    <span className={`invalid-feedback`}>{errors.email}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ContactNumber`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="number" name={`contact_number`}
                                                       value={contactNumber} min={0}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                       onChange={(e) => setContactNumber(e.target.value)}
                                                       placeholder={props.t(`EnterContactNumber`)} maxLength={10}/>
                                                {errors.contact_number && (
                                                    <span
                                                        className={`invalid-feedback`}>{errors.contact_number}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address1`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`address_1`}
                                                       value={address_1} onChange={(e) => setAddress1(e.target.value)}
                                                       placeholder={props.t(`EnterAddress1`)} maxLength={75}/>
                                                {errors.address_1 && (
                                                    <span className={`invalid-feedback`}>{errors.address_1}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address2`)}</Label>
                                                <Input className="form-control" type="text" name={`address_2`}
                                                       value={address_2} onChange={(e) => setAddress2(e.target.value)}
                                                       placeholder={props.t(`EnterAddress2`)} maxLength={75}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`city`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`city`}
                                                       value={city} onChange={(e) => setCity(e.target.value)}
                                                       placeholder={props.t(`EnterCity`)} maxLength={10}/>
                                                {errors.city && (
                                                    <span className={`invalid-feedback`}>{errors.city}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`state`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`state`}
                                                       value={state} onChange={(e) => setState(e.target.value)}
                                                       placeholder={props.t(`EnterState`)} maxLength={10}/>
                                                {errors.state && (
                                                    <span className={`invalid-feedback`}>{errors.state}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Country`)} <span
                                                    className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={countryList} onChange={onUpdateTimezone}
                                                        value={selectCountry}
                                                        placeholder={props.t(`PleaseSelectCountry`)}
                                                        name={`country_id`}/>
                                                {errors.country_id && (
                                                    <span className={`invalid-feedback`}>{errors.country_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`TimeZone`)}<span
                                                    className={`text-danger ml-1`}>*</span></Label>
                                                <Select name={`time_zone`} options={timeZoneList}
                                                        value={selectTimezone} isLoading={isTimezoneLoad}
                                                        placeholder={props.t(`PleaseSelectTimezone`)}
                                                        onChange={handleTimezone}/>
                                                {errors.timezone_id && (
                                                    <span className={`invalid-feedback`}>{errors.timezone_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`postcode`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`postcode`}
                                                       value={postcode} onChange={(e) => setPostCode(e.target.value)}
                                                       placeholder={props.t(`EnterPostCode`)} maxLength={10}/>
                                                {errors.postcode && (
                                                    <span className={`invalid-feedback`}>{errors.postcode}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`website`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`website`}
                                                       value={website}
                                                       onChange={(e) => setWebsite(e.target.value)}
                                                       placeholder={props.t(`EnterWebsite`)}/>
                                                {errors.website && (
                                                    <span className={`invalid-feedback`}>{errors.website}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`business_brn`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`business_brn`}
                                                       value={business_brn}
                                                       onChange={(e) => setBusiness_brn(e.target.value)}
                                                       placeholder={props.t(`EnterBusiness_brn`)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`SstId`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`sst_id`}
                                                       value={sstId}
                                                       onChange={(e) => setSstId(e.target.value)}
                                                       placeholder={props.t(`EnterSstId`)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`GstId`)}</Label>
                                                <Input className="form-control" type="text"
                                                       name={`gst_id`}
                                                       value={gstId}
                                                       onChange={(e) => setGstId(e.target.value)}
                                                       placeholder={props.t(`EnterGstId`)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Image`)}</Label>
                                                <Input className="form-control" type="file" onChange={handleFile}
                                                       accept="image/*"/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup>
                                                {image && (
                                                    <div className="avatars">
                                                        <div className="avatar mt-3">
                                                            <Media body className="img-50 b-r-15" src={image} alt=""/>
                                                        </div>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input
                                                    id="exampleSelect"
                                                    name="select"
                                                    value={statusId}
                                                    onChange={(e) => setStatusId(e.target.value)}
                                                    type="select">
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                                {errors.status_id && (
                                                    <span className={`invalid-feedback`}>{errors.status_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label
                                                    className="col-form-label">{props.t(`SelfPickupCharge`)}</Label>
                                                <Input className="form-control" type="number"
                                                       name={`self_pickup_charge`} step={`0.0`}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberFloatInput(evt)}
                                                       value={selfPickupCharge}
                                                       onChange={(e) => setSelfPickupCharge(e.target.value)}
                                                       placeholder={props.t(`EnterSelfPickupCharge`)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`BranchDetail`)}</Label>
                                                <Input className="form-control" type="textarea" name={`detail`}
                                                       value={detail} onChange={(e) => setDetail(e.target.value)}
                                                       placeholder={props.t(`EnterBranchDetail`)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={`/branch`} role={`button`} end
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default translate(EditBranch);