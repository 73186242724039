import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate, useParams} from "react-router-dom";

import useDocumentTitle from "../../utils/useDocumentTitle";
import {decryptData, encryptData} from "../../utils/crypto";
import {actionUpdatePromoCode, getPromoCodeDetail} from "../../services/PromoCode";
import Breadcrumbs from "../partials/Breadcrumbs";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Media,
    Row
} from "reactstrap";
import Select from "react-select";
import configDb from "../../config";
import DatePicker from "react-datepicker";
import Utils from "../../utils";
import validator from "validator";
import {toast} from "react-toastify";
import {loaderActions} from "../../store";
import {getAllBranchList} from "../../services/Branch";

let statusList = configDb.data.statusList;
let promotionApplyOnList = configDb.data.promotionApplyOnList;
let promotionForList = configDb.data.promotionCodeForList;

function EditPromoCode(props) {
    const loginAuth = useSelector(x => x.auth.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {uuid} = useParams();
    const [selectPromotionApplyOn, setSelectPromotionApplyOn] = useState([]);
    const [entityType, setEntityType] = useState(0);
    const [entityValue, setEntityValue] = useState(0);
    const [branchList, setBranchList] = useState([]);
    const [selectBranch, setSelectBranch] = useState(null);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [discount, setDiscount] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [detail, setDetail] = useState('');
    const [image, setImage] = useState('');
    const [isAllDay, setIsAllDay] = useState(true);
    const [isPercentage, setIsPercentage] = useState(true);
    const [imageFile, setImageFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [promoData, setPromoData] = useState({});
    const errorsObj = {
        name: '',
        code: '',
        code_for: '',
        branch_id: '',
        discount: '',
        start_date: '',
        end_date: '',
        entity_value: '',
        image: ''
    };
    const [errors, setErrors] = useState(errorsObj);
    useDocumentTitle(props.t(`EditPromoCode`));
    useEffect(function () {
        dispatch(loaderActions.loader(true));
        let data = encryptData({uuid: uuid, language: getLanguage()});
        getPromoCodeDetail(data)
            .then(response => {
                response = decryptData(response.data);
                setPromoData(response.data);
                setName(response.data.name);
                setCode(response.data.code);
                let applyForIds = response.data.code_for.split(',');
                let list = [];
                for (let i = 0; i < promotionApplyOnList.length; i++) {
                    if (applyForIds.findIndex(x => parseInt(x) === parseInt(promotionApplyOnList[i].value)) > -1) {
                        list.push({
                            value: promotionApplyOnList[i].value,
                            label: promotionApplyOnList[i].label
                        });
                    }
                }
                setSelectPromotionApplyOn(list);
                setImage(response.data.image);
                setDetail(response.data.detail);
                setIsAllDay((response.data.is_all_day) ? true : false);
                if (!response.data.is_all_day) {
                    setStartDate(response.data.start_date);
                    setEndDate(response.data.end_date);
                }
                setIsPercentage((response.data.is_percentage) ? true : false);
                setDiscount(response.data.discount);
                setEntityType(response.data.entity_type);
                setEntityValue(response.data.entity_value);
                setStatusId(response.data.status_id);

                let bData = encryptData({category_id: ''});
                getAllBranchList(bData)
                    .then(resultBranch => {
                        resultBranch = decryptData(resultBranch.data);
                        let list = [];
                        let selectedBranch = [];
                        let branchIds = response.data.branch_id.split(',');
                        for (let i = 0; i < resultBranch.data.length; i++) {
                            if (loginAuth?.branch_id) {
                                if (branchIds.findIndex(x => parseInt(x) === parseInt(resultBranch.data[i].id)) > -1) {
                                    list.push({
                                        value: resultBranch.data[i].id,
                                        label: resultBranch.data[i].display_name
                                    });
                                } else {
                                    let loginBranchIds = loginAuth?.branch_id.split(',');
                                    loginBranchIds = loginBranchIds.map(x => parseInt(x));
                                    if (loginBranchIds.indexOf(parseInt(resultBranch.data[i].id)) > -1) {
                                        list.push({
                                            value: resultBranch.data[i].id,
                                            label: resultBranch.data[i].display_name
                                        });
                                    }
                                }
                            } else {
                                list.push({
                                    value: resultBranch.data[i].id,
                                    label: resultBranch.data[i].display_name
                                });
                            }
                            if (branchIds.findIndex(x => parseInt(x) === parseInt(resultBranch.data[i].id)) > -1) {
                                selectedBranch.push({
                                    value: resultBranch.data[i].id,
                                    label: resultBranch.data[i].display_name
                                });
                            }
                        }
                        setSelectBranch(selectedBranch);
                        setBranchList(list);
                    })
                    .catch(err => {

                    });
                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/promo-code');
            });
    }, [uuid, navigate, dispatch, loginAuth]);
    const showDate = (date) => {
        let dateFormat = '';
        if (date) {
            dateFormat = new Date(date);
        }
        return dateFormat;
    };
    const handleFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setImage(URL.createObjectURL(e.target.files[0]));
            setImageFile([e.target.files[0]]);
        }
    };
    const handleChangeApplyOn = (e) => {
        let list = e;
        if (list.length > 0) {
            let index = list.findIndex(x => parseInt(x.value) !== 0);
            if (index > -1) {
                let checkLastObj = list[list.length - 1];
                if (parseInt(checkLastObj.value) === 0) {
                    list = [{value: 0, label: 'All'}];
                } else {
                    let checkAll = list.findIndex(x => parseInt(x.value) === 0);
                    if (checkAll > -1) {
                        list.splice(checkAll, 1);
                    }
                    if (list.length === 2) {
                        list = [{value: 0, label: 'All'}];
                    }
                }
            } else {
                list = [{value: 0, label: 'All'}];
            }
        }
        setSelectPromotionApplyOn(list);
    };
    const onUpdate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!name.trim()) {
            errorObj.name = props.t('RequiredCodeName');
            error = true;
        }
        if (!code.trim()) {
            errorObj.code = props.t('RequiredPromoCode');
            error = true;
        } else if (!validator.isAlphanumeric(code)) {
            errorObj.code = props.t('OnlyAlphaNumeric');
            error = true;
        }
        if (selectBranch.length === 0) {
            errorObj.branch_id = props.t('RequiredBranch');
            error = true;
        }
        if (selectPromotionApplyOn.length === 0) {
            errorObj.code_for = props.t('RequiredPromotionApplyOn');
            error = true;
        }
        if (entityType > 0 && (!entityValue || parseInt(entityValue) === 0)) {
            errorObj.entity_value = props.t('RequiredEntityValue' + entityType);
            error = true;
        }
        if (!isAllDay && !startDate) {
            errorObj.start_date = props.t('RequiredStartDate');
            error = true;
        }
        if (!isAllDay && !endDate) {
            errorObj.end_date = props.t('RequiredEndDate');
            error = true;
        }
        if (!discount) {
            errorObj.discount = props.t('RequiredDiscount');
            error = true;
        } else if (parseInt(discount) === 0) {
            errorObj.discount = props.t('RequiredDiscount');
            error = true;
        } else if (isPercentage && discount > 99) {
            errorObj.discount = props.t('100LessValue');
            error = true;
        }
        if (imageFile.length > 0) {
            if (!imageFile[0].name.match(configDb.data.imageExt)) {
                errorObj.image = props.t('InvalidImage');
                error = true;
            }
        }
        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        let codeFors = selectPromotionApplyOn.map(e => e.value);
        codeFors = codeFors.join();
        let branchIds = selectBranch.map(e => e.value);
        branchIds = branchIds.join();
        if (loginAuth?.branch_id) {
            let ids = Utils.getBranchDiffIds(promoData.branch_id, loginAuth.branch_id, branchIds);
            if (ids) {
                branchIds = branchIds + ',' + ids;
            }
        }
        const formData = new FormData();
        formData.append('uuid', uuid);
        formData.append('language', getLanguage());
        formData.append('name', name);
        formData.append('code', code);
        formData.append('code_for', codeFors);
        formData.append('branch_id', branchIds);
        formData.append('detail', detail);
        formData.append('is_all_day', (isAllDay) ? 1 : 0);
        if (!isAllDay) {
            formData.append('start_date', Utils.dateFormatText(new Date(startDate)));
            formData.append('end_date', Utils.dateFormatText(new Date(endDate)));
        }
        formData.append('is_percentage', (isPercentage) ? 1 : 0);
        formData.append('discount', discount);
        formData.append('entity_type', entityType);
        formData.append('entity_value', entityValue);
        formData.append('status_id', statusId);
        if (imageFile.length > 0) {
            formData.append('image', imageFile[0]);
        }
        actionUpdatePromoCode((formData))
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/promo-code');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }
    return (
        <>
            <Breadcrumbs parentLink={'/promo-code'} parent={`PromoCode`} title={`EditPromoCode`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form method={`post`} onSubmit={onUpdate} encType={`multipart/form-data`}>
                            <Card className="b-t-primary">
                                <CardBody>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`PromoCodeName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`name`}
                                                       value={name} onChange={(e) => setName(e.target.value)}
                                                       placeholder={props.t(`EnterPromoCodeName`)} maxLength={75}/>
                                                {errors.name && (
                                                    <span className={`invalid-feedback`}>{errors.name}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`PromoCode`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`code`}
                                                       value={code} onChange={(e) => setCode(e.target.value)}
                                                       placeholder={props.t(`EnterPromoCode`)} maxLength={75}/>
                                                {errors.code && (
                                                    <span className={`invalid-feedback`}>{errors.code}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Branch`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={branchList} isMulti value={selectBranch}
                                                        onChange={(e) => setSelectBranch(e)}
                                                        placeholder={props.t(`PleaseSelectBranch`)}
                                                        name={`branch_id`}/>
                                                {errors.branch_id && (
                                                    <span className={`invalid-feedback`}>{errors.branch_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`PromotionApplyOn`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={promotionApplyOnList} isMulti
                                                        value={selectPromotionApplyOn}
                                                        onChange={handleChangeApplyOn}
                                                        placeholder={props.t(`PleaseSelectPromotionApplyOn`)}
                                                        name={`code_for`}/>
                                                {errors.code_for && (
                                                    <span className={`invalid-feedback`}>{errors.code_for}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`PromotionCodeFor`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input name="entity_type" value={entityType} type="select"
                                                       onChange={(e) => setEntityType(e.target.value)}>
                                                    {promotionForList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                            </FormGroup>
                                        </Col>
                                        {entityType > 0 && (
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Label
                                                        className="col-form-label">{(parseInt(entityType) === 1) ? props.t(`HowManyProductShouldBeCart`) : props.t(`HowMuchPrice`)}
                                                        <span className={`text-danger ml-1`}>*</span></Label>
                                                    <Input className="form-control" type="number" name={`entity_value`}
                                                           value={entityValue}
                                                           onChange={(e) => setEntityValue(e.target.value)}
                                                           min={0}
                                                           onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}/>
                                                    {errors.entity_value && (
                                                        <span
                                                            className={`invalid-feedback`}>{errors.entity_value}</span>)}
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col sm={4}>
                                            <FormGroup className={`pt-4`}>
                                                <Label check>{props.t(`SpecificDay`)}</Label>
                                                <label className="custom-switch mx-3">
                                                    <input type="checkbox" checked={isAllDay}
                                                           onChange={(e) => setIsAllDay(e.target.checked)}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <Label check>{props.t(`AllDays`)}</Label>
                                            </FormGroup>
                                        </Col>
                                        {!isAllDay && (
                                            <>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label
                                                            className="col-form-label">{props.t(`PromoStartDate`)}<span
                                                            className={`text-danger ml-1`}>*</span></Label>
                                                        <DatePicker className="form-control" type="text"
                                                                    selected={showDate(startDate)}
                                                                    onChange={(date) => {
                                                                        setStartDate(date);
                                                                        setEndDate('');
                                                                    }}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    minDate={Utils.blockMinDate()}
                                                                    placeholderText={props.t(`EnterStartDate`)}/>
                                                        {errors.start_date && (
                                                            <span
                                                                className={`invalid-feedback`}>{errors.start_date}</span>)}
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <Label className="col-form-label">{props.t(`PromoEndDate`)}<span
                                                            className={`text-danger ml-1`}>*</span></Label>
                                                        <DatePicker className="form-control" type="text"
                                                                    selected={showDate(endDate)}
                                                                    onChange={(date) => setEndDate(date)}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    minDate={Utils.blockMinDate(showDate(startDate))}
                                                                    placeholderText={props.t(`EnterEndDate`)}/>
                                                        {errors.end_date && (
                                                            <span
                                                                className={`invalid-feedback`}>{errors.end_date}</span>)}
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )}
                                        <Col sm={4}>
                                            <FormGroup className={`pt-4`}>
                                                <label className="custom-switch mx-3">
                                                    <input type="checkbox" checked={isPercentage}
                                                           onChange={(e) => setIsPercentage(e.target.checked)}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <Label check>{props.t(`IsPercentage`)}</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Discount`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="number" name={`discount`}
                                                       value={discount} onChange={(e) => setDiscount(e.target.value)}
                                                       placeholder={props.t(`EnterDiscount`)} min={0}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}/>
                                                {errors.discount && (
                                                    <span className={`invalid-feedback`}>{errors.discount}</span>)}
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Details`)}</Label>
                                                <Input className="form-control" type="textarea" name={`detail`}
                                                       value={detail}
                                                       onChange={(e) => setDetail(e.target.value)} rows={5}
                                                       placeholder={props.t(`EnterPromoDetail`)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Image`)}</Label>
                                                <Input className="form-control" type="file" onChange={handleFile}
                                                       accept="image/*"/>
                                                {errors.image && (
                                                    <span className={`invalid-feedback`}>{errors.image}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup>
                                                {image && (
                                                    <div className="avatars">
                                                        <div className="avatar mt-3">
                                                            <Media body className="img-50 b-r-15" src={image} alt=""/>
                                                        </div>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input name="status_id" value={statusId} type="select"
                                                       onChange={(e) => setStatusId(e.target.value)}>
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                                {errors.status_id && (
                                                    <span className={`invalid-feedback`}>{errors.status_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className={`py-4`}>
                                    <Row>
                                        <Col sm={12} className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={'/promo-code'} end role={`button`}
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" className={`btn-square`}
                                                            type={`submit`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(EditPromoCode);