import React, {useEffect, useState} from "react";
import {Form, FormGroup, Input, Label, Button, Row, Col} from 'reactstrap';
import {NavLink, useNavigate, useLocation, useSearchParams} from "react-router-dom";
import validator from 'validator';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';

import useDocumentTitle from "../../utils/useDocumentTitle";
import {login} from "../../services/AuthService";
import Utils from "../../utils";
import {decryptData, encryptData} from "../../utils/crypto";
import {authActions} from "../../store";
import configDb from "../../config";

function Login() {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [togglePassword, setTogglePassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let errorsObj = {email: '', password: ''};
    const [errors, setErrors] = useState(errorsObj);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useDocumentTitle('');
    useEffect(function () {
        if (searchParams.get('email')) {
            setEmail(searchParams.get('email'))
        }
    }, [searchParams]);
    const onLogin = (e) => {
        e.preventDefault();
        let errorObj = {...errorsObj};
        let error = false;

        if (!email) {
            errorObj.email = 'Email address is required';
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = 'Please enter valid email address';
            error = true;
        }
        if (!password) {
            errorObj.password = 'Password is required';
            error = true;
        }
        setErrors(errorObj);
        if (error) return;
        setIsLoading(true);
        let obj = {
            email: email,
            password: password
        };
        let data = encryptData(obj);
        login(data)
            .then(response => {
                Utils.setDetail(response.data);
                if (configDb.data.is_json) {
                    dispatch(authActions.login(response.data.data));
                } else {
                    let data = decryptData(response.data);
                    dispatch(authActions.login(data.data));
                }

                if (location?.state?.path) {
                    navigate(location?.state?.path);
                } else {
                    navigate('/dashboard');
                }
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }

    return (
        <Form method={`post`} onSubmit={onLogin}>
            <h4>{`Sign In`}</h4>
            <p>{`Enter your email & password to login`}</p>
            <Row>
                <Col sm={12}>
                    <FormGroup>
                        <Label className="col-form-label">{`Email Address`}</Label>
                        <Input className="form-control" type="text" name={`email`} value={email}
                               onChange={(e) => setEmail(e.target.value)} placeholder="Test@gmail.com"/>
                        {errors.email && (<span className={`invalid-feedback`}>{errors.email}</span>)}
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                        <Label className="col-form-label">{`Password`}</Label>
                        <Input className="form-control" type={togglePassword ? "text" : "password"}
                               name="login[password]"
                               value={password} onChange={(e) => setPassword(e.target.value)} placeholder="*********"/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? "" : "show"}></span>
                        </div>
                        {errors.password && (<span className={`invalid-feedback`}>{errors.password}</span>)}
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                        {!isLoading && (
                            <Button color="primary" type={`submit`}
                                    className="btn-block btn-square btn-lg">{`Sign In`}</Button>
                        )}
                        {isLoading && (
                            <Button color="primary" type={`button`} disabled={true}
                                    className="btn-block btn-square btn-lg">
                                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}</Button>
                        )}
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup className="mb-0 text-right">
                        <NavLink className="link" to={`/forgot-password`}>{`Forgot password?`}</NavLink>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}

export default Login;