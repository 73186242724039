import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function updateProfile(data) {
    let config = {
        "content-type": "multipart/form-data",
    };
    return axiosInstance.post(`${hostName}/update-profile`, data, config);
}
export function updateCompanyProfile(data) {
    let config = {
        "content-type": "multipart/form-data",
    };
    return axiosInstance.post(`${hostName}/setting`, data, config);
}