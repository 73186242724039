import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getPromoCodeList(data) {
    return axiosInstance.post(`${hostName}/promo-code/list`, data);
}

export function actionDeletePromoCode(data) {
    return axiosInstance.post(`${hostName}/promo-code/delete`, data);
}

export function actionStorePromoCode(data) {
    return axiosInstance.post(`${hostName}/promo-code/create`, data);
}

export function actionUpdatePromoCode(data) {
    return axiosInstance.post(`${hostName}/promo-code/update`, data);
}

export function getPromoCodeDetail(data) {
    return axiosInstance.post(`${hostName}/promo-code/detail`, data);
}
