import CryptoJS from 'crypto-js';

import configDb from "../config";

export const encryptData = (data) => {

    if (configDb.data.is_json) {
        return data;
    }

    try {
        let key = configDb.data.decrypt_key;
        let iv = configDb.data.decrypt_iv;
        return CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(key)
            , {
                iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            }).toString();
    } catch (e) {
        return '';
    }
};


export const decryptData = (data) => {
    if (configDb.data.is_json) {
        return data;
    }
    try {
        let key = configDb.data.decrypt_key;
        let iv = configDb.data.decrypt_iv;
        const cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key)
            , {
                iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            });
        return JSON.parse(cipher.toString(CryptoJS.enc.Utf8));
    } catch (e) {
        return {};
    }
};