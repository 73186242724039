import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {Button, Card, CardBody, CardFooter, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {NavLink, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Select from "react-select";
import {toast} from "react-toastify";

import useDocumentTitle from "../../utils/useDocumentTitle";
import Breadcrumbs from "../partials/Breadcrumbs";
import {decryptData, encryptData} from "../../utils/crypto";
import {getAllBranchList} from "../../services/Branch";
import Utils from "../../utils";
import configDb from "../../config";
import {actionStorePayment} from "../../services/PaymentMethod";

const applicationForList = configDb.data.paymentPortalList;
const entityList = configDb.data.paymentEntityTypeList;

function CreatePaymentMethod(props) {
    const navigate = useNavigate();
    const loginAuth = useSelector(x => x.auth.value);
    const [isLoading, setIsLoading] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [selectBranch, setSelectBranch] = useState([]);
    const [selectApplicationFor, setSelectApplicationFor] = useState([]);
    const [entityType, setEntityType] = useState('');
    const [razorpayKeyId, setRazorpayKeyId] = useState('');
    const [razorpayKeySecret, setRazorpayKeySecret] = useState('');
    const [razorpayCurrency, setRazorpayCurrency] = useState('');
    const errorsObj = {
        branch_id: '',
        application_for: '',
        entity_type: '',
        razorpay_key_id: '',
        razorpay_key_secret: '',
        razorpay_currency: ''
    };
    const [errors, setErrors] = useState(errorsObj);

    useDocumentTitle(props.t(`CreatePaymentMethod`));

    useEffect(function () {
        let data = encryptData({category_id: ''});
        getAllBranchList(data)
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (loginAuth?.branch_id) {
                        let branchIds = loginAuth?.branch_id.split(',');
                        if (branchIds.indexOf(response.data[i].id.toString()) > -1) {
                            list.push({
                                value: response.data[i].id,
                                label: response.data[i].display_name
                            });
                        }
                    } else {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].display_name
                        });
                    }
                }
                setBranchList(list);
            })
            .catch(err => {

            });
    }, [loginAuth]);

    const onCreate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (selectBranch.length === 0) {
            errorObj.branch_id = props.t('RequiredBranch');
            error = true;
        }
        if (selectApplicationFor.length === 0) {
            errorObj.application_for = props.t('RequiredApplicationFor');
            error = true;
        }
        if (!entityType) {
            errorObj.entity_type = props.t('RequiredPaymentMethod');
            error = true;
        }
        if (parseInt(entityType) === 1) {
            if (!razorpayKeyId) {
                errorObj.razorpay_key_id = props.t('RequiredRazorpayId');
                error = true;
            }
            if (!razorpayKeySecret) {
                errorObj.razorpay_key_secret = props.t('RequiredRazorpaySecret');
                error = true;
            }
            if (!razorpayCurrency) {
                errorObj.razorpay_currency = props.t('RequiredRazorpayCurrency');
                error = true;
            }
        }

        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        let branchIds = selectBranch.map(e => e.value);
        branchIds = branchIds.join();
        let appFor = selectApplicationFor.map(e => e.value);
        appFor = appFor.join();

        let entityValue = '';
        if (parseInt(entityType) === 1) {
            entityValue = JSON.stringify({
                key_id: razorpayKeyId,
                key_secret: razorpayKeySecret,
                currency: razorpayCurrency
            });
        }
        let obj = {
            language: getLanguage(),
            branch_id: branchIds,
            application_for: appFor,
            entity_type: entityType,
            entity_value: entityValue
        };
        let data = encryptData(obj);
        actionStorePayment(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setIsLoading(false);
                navigate('/payment-method');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    };

    return (
        <>
            <Breadcrumbs parentLink={'/payment-method'} parent={`PaymentMethod`} title={`CreatePaymentMethod`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form method={`post`} onSubmit={onCreate}>
                            <Card className="b-t-primary">
                                <CardBody>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Branch`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={branchList} isMulti value={selectBranch}
                                                        onChange={(e) => setSelectBranch(e)}
                                                        placeholder={props.t(`PleaseSelectBranch`)} name={`branch_id`}/>
                                                {errors.branch_id && (
                                                    <span className={`invalid-feedback`}>{errors.branch_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ApplicationFor`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={applicationForList} isMulti
                                                        value={selectApplicationFor}
                                                        onChange={(e) => setSelectApplicationFor(e)}
                                                        placeholder={props.t(`SelectApplicationFor`)}
                                                        name={`branch_id`}/>
                                                {errors.application_for && (
                                                    <span
                                                        className={`invalid-feedback`}>{errors.application_for}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`PaymentMethod`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input type={`select`} name={`entity_type`} value={entityType}
                                                       onChange={(e) => setEntityType(e.target.value)}>
                                                    <option value="">{props.t(`PleaseSelectOption`)}</option>
                                                    {entityList.map((item, index) => (
                                                        <option value={item.id} key={index}>{item.name}</option>
                                                    ))}
                                                </Input>
                                                {errors.entity_type && (
                                                    <span
                                                        className={`invalid-feedback`}>{errors.entity_type}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {parseInt(entityType) === 1 && (
                                        <Row>
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Label className="col-form-label">{props.t(`RazorpayKeyId`)}
                                                        <span className={`text-danger ml-1`}>*</span></Label>
                                                    <Input name={`razorpay_key_id`} value={razorpayKeyId}
                                                           placeholder={props.t(`EnterRazorpayKeyId`)}
                                                           onChange={(e) => setRazorpayKeyId(e.target.value)}/>
                                                    {errors.razorpay_key_id && (
                                                        <span
                                                            className={`invalid-feedback`}>{errors.razorpay_key_id}</span>)}
                                                </FormGroup>
                                            </Col>
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Label className="col-form-label">{props.t(`RazorpayKeySecret`)}
                                                        <span className={`text-danger ml-1`}>*</span></Label>
                                                    <Input name={`razorpay_key_secret`} value={razorpayKeySecret}
                                                           placeholder={props.t(`EnterRazorpayKeySecret`)}
                                                           onChange={(e) => setRazorpayKeySecret(e.target.value)}/>
                                                    {errors.razorpay_key_secret && (
                                                        <span
                                                            className={`invalid-feedback`}>{errors.razorpay_key_secret}</span>)}
                                                </FormGroup>
                                            </Col>
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Label className="col-form-label">{props.t(`RazorpayCurrency`)}
                                                        <span className={`text-danger ml-1`}>*</span></Label>
                                                    <Input name={`razorpay_key_secret`} value={razorpayCurrency}
                                                           placeholder={props.t(`EnterRazorpayCurrency`)}
                                                           onChange={(e) => setRazorpayCurrency(e.target.value)}/>
                                                    {errors.razorpay_currency && (
                                                        <span
                                                            className={`invalid-feedback`}>{errors.razorpay_currency}</span>)}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                                <CardFooter className={`py-4`}>
                                    <Row>
                                        <Col sm={12} className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={'/payment-method'} end role={`button`}
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" className={`btn-square`}
                                                            type={`submit`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(CreatePaymentMethod);