import React from "react";
import {Row} from 'reactstrap';
import RightHeader from "./RightHeader";
import LeftHeader from "./LeftHeader";

function Header() {
    return (
        <>
            <div className="page-header">
                <Row className="header-wrapper m-0">
                    <LeftHeader/>
                    <RightHeader/>
                </Row>
            </div>
        </>
    );
}

export default Header;