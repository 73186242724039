import {useEffect} from 'react';

import configDb from "../config";
import Utils from "./index";

function useDocumentTitle(title) {
    useEffect(() => {
        let pageTitle = configDb.data.app_name;

        let domainName = Utils.getDomain();
        if(domainName){
            pageTitle = Utils.capitalizeFirstLetter(domainName);
        }
        if (title) {
            pageTitle = title + ' - ' + pageTitle;
        }
        document.title = pageTitle;
    }, [title]);
}

export default useDocumentTitle;