import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();
export function actionCountryList(data) {
    return axiosInstance.post(`${hostName}/country`, data);
}
export function actionTimeZoneList(data) {
    return axiosInstance.post(`${hostName}/country/timezone`, data);
}
