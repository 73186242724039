import React, {useEffect} from "react";
import {Navigate, useLocation, Outlet} from "react-router-dom";
import {useSelector} from 'react-redux';

export const PrivateRoute = () => {
    const auth = useSelector(x => x.auth.value);
    const location = useLocation();

    if (!auth?.token) {
        return <Navigate to="/" state={{path: location.pathname}}/>
    } else {
        return <Outlet/>;
    }
};
export const PublicRoute = () => {
    const auth = useSelector(x => x.auth.value);
    const location = useLocation();
    if (auth?.token) {
        return <Navigate to="/dashboard" state={{path: location.pathname}}/>
    } else {
        return <Outlet/>;
    }
};

export const ManageMenu = (menuList) => {
    const userPermission = useSelector(x => x.permission.value);
    let list = [];
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i]['type'] === 'link') {
            if (menuList[i]['access']) {
                if (userPermission.indexOf(menuList[i]['access']) > -1) {
                    list.push(menuList[i]);
                }
            } else {
                list.push(menuList[i]);
            }
        } else if (menuList[i]['type'] === 'sub') {
            let children = [];
            for (let c = 0; c < menuList[i]['children'].length; c++) {
                if (menuList[i]['children'][c]['access']) {
                    if (userPermission.indexOf(menuList[i]['children'][c]['access']) > -1) {
                        children.push(menuList[i]['children'][c]);
                    }
                } else {
                    children.push(menuList[i]['children'][c]);
                }
            }
            if (children.length > 0) {
                menuList[i]['children'] = children;
                list.push(menuList[i]);
            }
        }
    }
    return list;
}

export const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }, [pathname]);

    return null;
}