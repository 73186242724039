import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getUnitsList(data) {
    return axiosInstance.post(`${hostName}/units/list`, data);
}

export function getUnitsAll() {
    return axiosInstance.get(`${hostName}/units/list`);
}

export function getUnitsDetails(data) {
    return axiosInstance.post(`${hostName}/units/detail`, data);
}

export function actionStoreUnits(data) {
    return axiosInstance.post(`${hostName}/units/create`, data);
}

export function actionUpdateUnits(data) {
    return axiosInstance.post(`${hostName}/units/update`, data);
}

export function actionDelUnits(data) {
    return axiosInstance.post(`${hostName}/units/delete`, data);
}