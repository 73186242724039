import React, {useEffect, useState} from "react";
import {translate, getLanguage} from "react-switch-lang";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Media, Row} from "reactstrap";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Select from "react-select";
import {useSelector} from "react-redux";

import Breadcrumbs from "../partials/Breadcrumbs";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {decryptData, encryptData} from "../../utils/crypto";
import Utils from "../../utils";
import {actionStoreCategory, getAllCategory} from "../../services/Category";
import configDb from "../../config";
import {getAllBranchList} from "../../services/Branch";

let statusList = configDb.data.statusList;
let appMenuList = configDb.data.yesNoList;

function CreateCategory(props) {
    const loginAuth = useSelector(x => x.auth.value);
    const navigate = useNavigate();
    const [categoryName, setCategoryName] = useState('');
    const [selectParentCategory, setSelectParentCategory] = useState(null);
    const [description, setDescription] = useState('');
    const [isShowAppMenu, setIsShowAppMenu] = useState(0);
    const [statusId, setStatusId] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [selectBranch, setSelectBranch] = useState([]);
    const [parentList, setParentList] = useState([]);
    const [image, setImage] = useState('');
    const [imageFile, setImageFile] = useState([]);
    useDocumentTitle(props.t('CreateCategory'));
    const errorsObj = {
        categoryName: "",
        status_id: "",
        branch_id: "",
        image: ""
    };
    const [errors, setErrors] = useState(errorsObj);

    useEffect(function () {
        let data = encryptData({category_id: ''});
        getAllBranchList(data)
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (loginAuth?.branch_id) {
                        let branchIds = loginAuth?.branch_id.split(',');
                        if (branchIds.indexOf(response.data[i].id.toString()) > -1) {
                            list.push({
                                value: response.data[i].id,
                                label: response.data[i].display_name
                            });
                        }
                    } else {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].display_name
                        });
                    }
                }
                setBranchList(list);
            })
            .catch(err => {

            });
    }, [loginAuth]);

    useEffect(function () {
        let branchIds = [...selectBranch];
        branchIds = branchIds.map(x => x.value);
        branchIds = branchIds.join();
        let list = [{value: 0, label: 'Is Parent'}];
        if (branchIds) {
            let data = encryptData({branch_id: branchIds, is_all: false});
            getAllCategory(data)
                .then(response => {
                    response = decryptData(response.data);
                    list = [...list, ...response.data];
                    setParentList(list);
                    setSelectParentCategory(null);
                })
                .catch(err => {

                });
        } else {
            setParentList(list);
            setSelectParentCategory(null);
        }
    }, [selectBranch]);

    const handleImageFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setImage(URL.createObjectURL(e.target.files[0]));
            setImageFile([e.target.files[0]]);
        }
    };
    const onCreate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!categoryName) {
            errorObj.categoryName = props.t('RequiredCategoryName');
            error = true;
        }
        if (selectBranch.length === 0) {
            errorObj.branch_id = props.t('RequiredBranch');
            error = true;
        }
        if (imageFile.length > 0) {
            if (!imageFile[0].name.match(configDb.data.imageExt)) {
                errorObj.profile = props.t('InvalidImage');
                error = true;
            }
        }
        setErrors(errorObj);

        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        let branchIds = selectBranch.map(e => e.value);
        branchIds = branchIds.join();
        const formData = new FormData();
        formData.append('language', getLanguage());
        formData.append('name', categoryName);
        formData.append('branch_id', branchIds);
        formData.append('parent_id', (selectParentCategory.value) ? selectParentCategory.value : 0);
        formData.append('description', description);
        formData.append('is_show_app_menu', isShowAppMenu);
        formData.append('status_id', statusId);
        if (imageFile.length > 0) {
            formData.append('image', imageFile[0]);
        }
        actionStoreCategory((formData))
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/category');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    };
    return (
        <>
            <Breadcrumbs parentLink={'/category'} parent={`Category`} title={`CreateCategory`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onCreate} encType={`multipart/form-data`}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`CategoryName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`category_name`}
                                                       value={categoryName}
                                                       onChange={(e) => setCategoryName(e.target.value)}
                                                       placeholder={props.t(`EnterCategoryName`)} maxLength={75}/>
                                                {errors.categoryName && (
                                                    <span className={`invalid-feedback`}>{errors.categoryName}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Branch`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={branchList} isMulti value={selectBranch}
                                                        onChange={(e) => setSelectBranch(e)}
                                                        placeholder={props.t(`PleaseSelectBranch`)}
                                                        name={`branch_id`}/>
                                                {errors.branch_id && (
                                                    <span className={`invalid-feedback`}>{errors.branch_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ParentCategory`)}</Label>
                                                <Select options={parentList} value={selectParentCategory}
                                                        onChange={(e) => setSelectParentCategory(e)}
                                                        placeholder={props.t(`PleaseSelectParentCategory`)}
                                                        isClearable={true} name={`parent_id`}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`isShowAppMenu`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input
                                                    name="is_show_app_menu"
                                                    value={isShowAppMenu}
                                                    onChange={(e) => setIsShowAppMenu(e.target.value)}
                                                    type="select">
                                                    {appMenuList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input
                                                    name="status_id"
                                                    value={statusId}
                                                    onChange={(e) => setStatusId(e.target.value)}
                                                    type="select">
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                                {errors.status_id && (
                                                    <span className={`invalid-feedback`}>{errors.status_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Image`)}</Label>
                                                <Input className="form-control" type="file" onChange={handleImageFile}
                                                       accept="image/*"/>
                                                {errors.image && (
                                                    <span className={`invalid-feedback`}>{errors.image}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup>
                                                {image && (
                                                    <div className="avatars">
                                                        <div className="avatar mt-3">
                                                            <Media body className="img-50 b-r-15" src={image}
                                                                   alt=""/>
                                                        </div>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Description`)}</Label>
                                                <Input className="form-control" type="textarea" name={`description`}
                                                       value={description} rows={5}
                                                       onChange={(e) => setDescription(e.target.value)}
                                                       placeholder={props.t(`EnterDescription`)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={`/category`} role={`button`} end
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default translate(CreateCategory);