import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getTagsList(data) {
    return axiosInstance.post(`${hostName}/tag/list`, data);
}
export function getAllTagsList(data) {
    return axiosInstance.post(`${hostName}/tag/list-all`, data);
}

export function actionStoreTags(data) {
    return axiosInstance.post(`${hostName}/tag/create`, data);
}

export function actionUpdateTags(data) {
    return axiosInstance.post(`${hostName}/tag/update`, data);
}

export function actionDelTags(data) {
    return axiosInstance.post(`${hostName}/tag/delete`, data);
}