import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import configDb from "../config";
import Utils from "../utils";

const name = 'company';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const companyActions = {...slice.actions, ...extraActions};
export const companyReducer = slice.reducer;

function createInitialState() {
    let data = Utils.loginCompanyDetail();
    return {
        value: data
    }
}

function createReducers() {
    return {
        setCompany
    };

    function setCompany(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        login: login(),
        logout: logout()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            function (arg, {dispatch}) {
                dispatch(companyActions.setCompany(arg));
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            function (arg, {dispatch}) {
                dispatch(companyActions.setCompany(null));
                localStorage.removeItem(configDb.data.api_c_local_storage);
            }
        );
    }
}