import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getSupplierList(data) {
    return axiosInstance.post(`${hostName}/supplier/list`, data);
}

export function getSupplierAllList() {
    return axiosInstance.get(`${hostName}/supplier/list`);
}

export function getSupplierDetails(data) {
    return axiosInstance.post(`${hostName}/supplier/detail`, data);
}

export function actionStoreSupplier(data) {
    return axiosInstance.post(`${hostName}/supplier/create`, data);
}

export function actionUpdateSupplier(data) {
    return axiosInstance.post(`${hostName}/supplier/update`, data);
}

export function actionDeleteSupplier(data) {
    return axiosInstance.post(`${hostName}/supplier/delete`, data);
}

export function actionImportSupplier(data) {
    return axiosInstance.post(`${hostName}/supplier/import`, data);
}