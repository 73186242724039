import Moment from "moment";

import {decryptData} from "./crypto";
import configDb from "../config";

class Utils {
    static getErrorMessage(err) {
        let error = 'Oops...something went wrong.';
        if (err.response && err.response.data) {
            let decrypt = decryptData(err.response.data);
            if (decrypt.message) {
                error = decrypt.message;
            }
        } else if (err.message) {
            error = err.message;
        }
        return error;
    }

    static setDetail(data) {
        if (configDb.data.is_json) {
            data = JSON.stringify(data);
        }
        localStorage.setItem(configDb.data.api_local_storage, data);
    }

    static setCompanyDetail(data) {
        if (configDb.data.is_json) {
            data = JSON.stringify(data);
        }
        localStorage.setItem(configDb.data.api_c_local_storage, data);
    }

    static loginDetail() {
        const data = localStorage.getItem(configDb.data.api_local_storage);
        if (data) {
            let auth = decryptData(data);
            return auth.data;
        } else {
            return {};
        }
    }

    static loginCompanyDetail() {
        const data = localStorage.getItem(configDb.data.api_c_local_storage);
        if (data) {
            let auth = decryptData(data);
            return auth.data;
        } else {
            return {};
        }
    }

    static getDomain() {
        const parts = window.location.hostname.split('.');
        let sliceTill = -2;

        const isLocalHost = parts.slice(-1)[0] === "localhost";
        if (isLocalHost) sliceTill = -1;

        return parts.slice(0, sliceTill).join('');
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static dateFormatText(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    static blockKeyNumberInput(e) {
        return ["e", "E", "+", "-", ".", "Control"].includes(e.key) && e.preventDefault();
    }

    static blockKeyNumberFloatInput(e) {
        return ["e", "E", "+", "-", "Control", "Enter"].includes(e.key) && e.preventDefault();
    }

    static blockFutureDate() {
        return new Date(Moment().subtract(1, 'days').format('YYYY-MM-DD'));
    }

    static blockMinDate(date = '') {
        if (date) {
            return new Date(Moment(date).format('YYYY-MM-DD'));
        }
        return new Date(Moment().format('YYYY-MM-DD'));
    }

    static focusError() {
        setTimeout(function () {
            let classList = document.getElementsByClassName('invalid-feedback');
            if (classList.length > 0) {
                let scrollDiv = classList[0].getBoundingClientRect().top + window.scrollY - 200;
                window.scrollTo({top: scrollDiv, behavior: 'smooth'});
            }
        }, 0);
    }

    static getPermissionName(path) {
        let split = path.split('/');
        let name = '';
        if (split.length === 2) {
            name = 'view_' + split[1].replace('-', '_');
        } else if (split.length > 2) {
            name = 'modify_' + split[1].replace('-', '_');
        }
        return name;
    }

    static getBranchDiffIds(dataString, loginString, updateString) {
        let dataStringArray = dataString.split(',');
        let updateStringArray = updateString.split(',');
        let loginStringArray = loginString.split(',');
        dataStringArray = dataStringArray.map(e => e.toString());
        loginStringArray = loginStringArray.map(e => e.toString());
        updateStringArray = updateStringArray.map(e => e.toString());
        let leftIds = dataStringArray.filter(x => !loginStringArray.includes(x));
        let removeIds = leftIds.filter(x => !updateStringArray.includes(x));
        return removeIds.join();
    }

    static showOrderStatusName(type, statusId) {
        let statusList = [];
        if (parseInt(type) === 1) {
            statusList = configDb.data.selfPickUpStatusList;
        } else if (parseInt(type) === 2) {
            statusList = configDb.data.deliveryStatusList;
        } else if (parseInt(type) === 3) {
            statusList = configDb.data.dineInStatusList;
        }

        let statusName = '';
        let index = statusList.findIndex(x => x.id === parseInt(statusId));
        if (index > -1) {
            statusName = statusList[index]['name'];
        }

        return statusName;
    }

    static calculateProcessBar(type, statusId) {
        let statusList = [];
        if (parseInt(type) === 1) {
            statusList = configDb.data.selfPickUpStatusList;
        } else if (parseInt(type) === 2) {
            statusList = configDb.data.deliveryStatusList;
        } else if (parseInt(type) === 3) {
            statusList = configDb.data.dineInStatusList;
        }

        let value = parseInt((100 * parseInt(statusId)) / statusList.length);
        if (parseInt(type) === 2) {
            value = parseInt((100 * (parseInt(statusId) + 1)) / statusList.length);
        }

        return value;
    }
}

export default Utils;