import axiosInstance from "./AxiosInstance";
import Utils from "../utils";

const hostName = Utils.getDomain();

export function getTaxList(data) {
    return axiosInstance.post(`${hostName}/tax/list`, data);
}

export function getTaxAllList() {
    return axiosInstance.get(`${hostName}/tax/list`);
}

export function getTaxDetails(data) {
    return axiosInstance.post(`${hostName}/tax/detail`, data);
}

export function actionStoreTax(data) {
    return axiosInstance.post(`${hostName}/tax/create`, data);
}

export function actionUpdateTax(data) {
    return axiosInstance.post(`${hostName}/tax/update`, data);
}

export function actionDeleteTax(data) {
    return axiosInstance.post(`${hostName}/tax/delete`, data);
}