import React, {useState, useEffect} from "react";
import {ArrowRight, ArrowLeft, Grid, Home, Users, Gift, Settings, FileText, ShoppingBag} from 'react-feather';
import {NavLink} from 'react-router-dom';
import {translate} from "react-switch-lang";
import {useSelector} from "react-redux";

import logo from '../../assets/images/logo/logo.png';
import logoDark from '../../assets/images/logo/logo_dark.png';
import logoIcon from '../../assets/images/logo/logo-icon.png';
import {ManageMenu} from "../../utils/functions";
import configDb from "../../config";

function Sidebar(props) {
    const company = useSelector(x => x.company.value);
    const [companyLogo, setCompanyLogo] = useState(logo);
    const [companyDarkLogo, setCompanyDarkLogo] = useState(logoDark);

    let MENU_LIST = [
        {path: `/dashboard`, icon: Home, title: 'Dashboard', type: 'link', isShow: true, active: false, access: ''},
        {
            path: `/employee`,
            icon: Users,
            title: 'Employee',
            type: 'link',
            isShow: true,
            active: false,
            access: 'view_employee'
        },
        {
            icon: Gift, title: 'Product', type: 'sub', isShow: true, active: false, children: [
                {path: `/products`, type: 'link', title: 'Product', access: 'view_products'},
                {path: `/supplier`, type: 'link', title: 'Suppliers', access: 'view_supplier'},
                {path: `/category`, type: 'link', title: 'Category', access: 'view_category'},
                {path: `/product-tags`, type: 'link', title: 'Tags', access: 'view_product_tags'},
                {path: `/units`, type: 'link', title: 'Units', access: 'view_units'},
            ]
        },
        {
            path: `/orders`,
            icon: ShoppingBag,
            title: 'Orders',
            type: 'link',
            isShow: true,
            active: false,
            access: 'view_orders'
        },
        {
            icon: Users, title: 'Customers', type: 'sub', isShow: true, active: false, children: [
                {path: `/customers`, type: 'link', title: 'Customers', access: 'view_customers'},
                {path: `/customer-tags`, type: 'link', title: 'Tags', access: 'view_customer_tags'},
            ]
        },
        {
            icon: Settings, title: 'Settings', type: 'sub', isShow: true, active: false, children: [
                {path: `/setting`, type: 'link', title: 'CompanyProfile', access: 'view_setting'},
                {path: `/roles`, type: 'link', title: 'Roles', access: 'view_roles'},
                {path: `/branch`, type: 'link', title: 'Branch', access: 'view_branch'},
                {path: `/tax`, type: 'link', title: 'Tax', access: 'view_tax'},
                {path: `/display-banner`, type: 'link', title: 'DisplayBanner', access: 'view_display_banner'},
                {path: `/promo-code`, type: 'link', title: 'PromoCode', access: 'view_promo_code'},
                {path: `/payment-method`, type: 'link', title: 'PaymentMethod', access: 'view_payment_method'}
            ]
        },
        {
            icon: FileText, title: 'Reservation', type: 'sub', isShow: true, active: false, children: [
                {path: `/reservation`, type: 'link', title: 'Settings', access: 'view_reservation'},
                {
                    path: `/reservation/availability`,
                    type: 'link',
                    title: 'AvailabilitySchedule',
                    access: 'modify_reservation'
                }
            ]
        }
    ];

    const [allMenuList, setAllMenuList] = useState(ManageMenu(MENU_LIST));
    const [isLoad, setIsLoad] = useState(true);
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [sideBarToggle, setSideBarToggle] = useState(true);

    useEffect(() => {
        if (company?.logo) {
            setCompanyLogo(company?.logo);
        }
        if (company?.dark_logo) {
            setCompanyDarkLogo(company?.dark_logo);
        }
        setIsLoad(true);
    }, [company]);

    useEffect(() => {
        document.querySelector(".left-arrow").classList.add("d-none");
        window.addEventListener('resize', handleResize);
        handleResize();

        if (window.innerWidth <= 991) {
            setSideBarToggle(false);
            document.querySelector(".page-header").className = "page-header close_icon";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon";
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        if (isLoad) {
            let currentUrl = window.location.pathname;
            let splitUrl = currentUrl.split('/');
            if (splitUrl.length > 1) {
                currentUrl = '/' + splitUrl[1];
            }
            let list = [...allMenuList];
            for (let m = 0; m < list.length; m++) {
                if (list[m].type === 'sub') {
                    if (list[m]?.children) {
                        let isActive = false;
                        for (let sm = 0; sm < list[m].children.length; sm++) {
                            if (list[m]['children'][sm]['path'] === currentUrl) {
                                isActive = true;
                            }
                        }
                        list[m]['active'] = isActive;
                    }
                }
            }

            let index = list.findIndex(x => x.title === 'Reservation');
            let isShowReservation = false;
            if (company?.business_category_name && company?.business_category_name === configDb.data.restaurant) {
                if (company?.business_mode && company?.business_mode === 2) {
                    isShowReservation = true;
                }
            }
            if (index > -1) {
                if (isShowReservation) {
                    list[index]['isShow'] = true;
                } else {
                    list[index]['isShow'] = false;
                }
            }

            setAllMenuList(list);
            setIsLoad(false);
        }
    }, [isLoad, allMenuList, company]);

    const handleResize = () => {
        setWidth(window.innerWidth - 500);
    }

    const onLinkClick = (menu, subMenu) => {
        if (window.innerWidth <= 991) {
            document.querySelector(".page-header").className = "page-header close_icon";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon";
            if (subMenu?.type && subMenu?.type === "sub") {
                document.querySelector(".page-header").className = "page-header";
                document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper";
            }
        }
        if (menu.type === 'link') {
            let list = [...allMenuList];
            for (let i = 0; i < list.length; i++) {
                list[i]['active'] = false;
            }
            setAllMenuList(list);
        }
    };

    const onOpenSubMenu = (e, index) => {
        e.preventDefault();
        let list = [...allMenuList];
        for (let i = 0; i < list.length; i++) {
            let isActive = false;
            if (i === index) {
                isActive = true;
            }
            list[i]['active'] = isActive;
        }
        setAllMenuList(list);
    }

    const scrollToRight = () => {
        if (margin <= -2598 || margin <= -2034) {
            if (width === 492) {
                setMargin(-3570)
            } else {
                setMargin(-3464)
            }
            document.querySelector(".right-arrow").classList.add("d-none");
            document.querySelector(".left-arrow").classList.remove("d-none");
        } else {
            setMargin(margin => margin += (-width));
            document.querySelector(".left-arrow").classList.remove("d-none");
        }
    }

    const scrollToLeft = () => {
        if (margin >= -width) {
            setMargin(0)
            document.querySelector(".left-arrow").classList.add("d-none");
            document.querySelector(".right-arrow").classList.remove("d-none");
        } else {
            setMargin(margin => margin += width);
            document.querySelector(".right-arrow").classList.remove("d-none");
        }
    }

    const closeOverlay = () => {
        document.querySelector(".bg-overlay1").classList.remove("active");
        document.querySelector(".sidebar-link").classList.remove("active");
    }

    const openCloseSidebar = (toggle) => {
        if (toggle) {
            setSideBarToggle(!toggle);
            document.querySelector(".page-header").className = "page-header close_icon";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon";
        } else {
            setSideBarToggle(!toggle);
            document.querySelector(".page-header").className = "page-header";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper";
        }
    };

    const responsiveSidebar = () => {
        document.querySelector(".page-header").className = "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon";
    }

    return (
        <>
            <div className={`bg-overlay1`} onClick={() => {
                closeOverlay()
            }}></div>
            <div className="sidebar-wrapper" id="sidebar-wrapper">
                <div className="logo-wrapper">
                    <NavLink to={`/dashboard`}>
                        <img className="img-fluid for-light" src={companyLogo} alt=""/>
                        <img className="img-fluid for-dark" src={companyDarkLogo} alt=""/>
                    </NavLink>
                    <div className="back-btn" onClick={() => responsiveSidebar()}>
                        <i className="fa fa-angle-left"></i>
                    </div>
                    <div className="toggle-sidebar" onClick={() => openCloseSidebar(sideBarToggle)}>
                        <Grid className="status_toggle middle sidebar-toggle"/>
                    </div>
                </div>
                <div className="logo-icon-wrapper">
                    <NavLink to={`/`}>
                        <img className="img-fluid" src={logoIcon} alt=""/>
                    </NavLink>
                </div>
                <nav className="sidebar-main" id="sidebar-main">
                    <div className="left-arrow" onClick={scrollToLeft}><ArrowLeft/></div>
                    <div id="sidebar-menu" style={{margin: '0px'}}>
                        <ul className="sidebar-links custom-scrollbar">
                            <li className="back-btn">
                                <div className="mobile-back text-right">
                                    <span>{props.t(`Back`)}</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                </div>
                            </li>

                            {allMenuList.map((menu, index) =>
                                <React.Fragment key={index}>
                                    {menu.isShow && (
                                        <li className="sidebar-list">
                                            {menu.type === 'link' && (
                                                <NavLink to={menu.path}
                                                         className={`sidebar-link sidebar-title link-nav`}
                                                         onClick={() => onLinkClick(menu, {})}>
                                                    <menu.icon/>
                                                    <span>{props.t(menu.title)}</span>
                                                </NavLink>
                                            )}

                                            {menu.type === 'sub' && (
                                                <>
                                                    <a href="#javascript" onClick={(e) => onOpenSubMenu(e, index)}
                                                       className={`sidebar-link sidebar-title`}>
                                                        <menu.icon/>
                                                        <span>{props.t(menu.title)}</span>
                                                        <div className="according-menu">
                                                            {menu.active ? <i className="fa fa-angle-down"></i> :
                                                                <i className="fa fa-angle-right"></i>}
                                                        </div>
                                                    </a>
                                                    <ul className="sidebar-submenu"
                                                        style={menu.active ? sideBarToggle ? {
                                                            opacity: 1,
                                                            transition: 'opacity 500ms ease-in'
                                                        } : {display: "block"} : {display: "none"}}>
                                                        {menu.children.map((subMenu, idx) => (
                                                            <li key={idx}>
                                                                {subMenu.type === 'link' && (
                                                                    <NavLink to={subMenu.path}
                                                                             onClick={() => onLinkClick(menu, subMenu)}>{props.t(subMenu.title)}</NavLink>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}
                                        </li>
                                    )}
                                </React.Fragment>
                            )}
                        </ul>
                    </div>
                    <div className="right-arrow" onClick={scrollToRight}><ArrowRight/></div>
                </nav>
            </div>
        </>
    );
}

export default translate(Sidebar);