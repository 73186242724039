import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container, FormGroup,
    Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Progress,
    Row
} from "reactstrap";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select";

import useDocumentTitle from "../../utils/useDocumentTitle";
import Breadcrumbs from "../partials/Breadcrumbs";
import Utils from "../../utils";
import {decryptData, encryptData} from "../../utils/crypto";
import {getAvailableTables, getOrderDetails, updateOrderStatus} from "../../services/Order";
import configDb from "../../config";

function ViewOrder(props) {
    const {uuid} = useParams();
    const allPermissionsList = useSelector(x => x.permission.value);
    const navigate = useNavigate();
    const company = useSelector(x => x.company.value);
    const [orderDetail, setOrderDetail] = useState({});
    const [discountData, setDiscountData] = useState({});
    const [productList, setProductList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [statusId, setStatusId] = useState('');
    const [tableList, setTableList] = useState([]);
    const [selectTable, setSelectTable] = useState([]);
    const [assignTableModal, setAssignTableModal] = useState(false);
    const [isAssignTableButtonLoad, setIsAssignTableButtonLoad] = useState(false);
    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const [confirmStatusModal, setConfirmStatusModal] = useState(false);
    const [isChangeStatusButtonLoad, setIsChangeStatusButtonLoad] = useState(false);
    const [cancelReason, setCancelReason] = useState('');

    let errorsObj = {status_id: '', table_id: '', cancel_reason: ''};
    const [errors, setErrors] = useState(errorsObj);

    useEffect(() => {
        let data = encryptData({language: getLanguage(), uuid: uuid});
        getOrderDetails(data)
            .then(response => {
                response = decryptData(response.data);
                setOrderDetail(response.data);
                if (response.data.discount_detail) {
                    setDiscountData(JSON.parse(response.data.discount_detail));

                }
                setProductList(response.data.items);

                if (parseInt(response.data.serve_type) === 1) {
                    setStatusList(configDb.data.selfPickUpStatusList);
                } else if (parseInt(response.data.serve_type) === 2) {
                    setStatusList(configDb.data.deliveryStatusList);
                } else if (parseInt(response.data.serve_type) === 3) {
                    setStatusList(configDb.data.dineInStatusList);
                }
                if (parseInt(response.data.business_mode) === 2 && parseInt(response.data.serve_type) === 3) {
                    let dataT = encryptData({
                        order_id: response.data.id,
                        branch_id: response.data.branch_id,
                        language: getLanguage()
                    });
                    getAvailableTables(dataT)
                        .then(resp => {
                            resp = decryptData(resp.data);
                            let list = [];
                            let selectedTable = [];
                            let tableIds = (response?.data?.table_id) ? response.data.table_id.split(',') : [];
                            for (let i = 0; i < resp.data.length; i++) {
                                list.push({
                                    value: resp.data[i].id,
                                    label: resp.data[i].name + ' (' + resp.data[i].seating + ')'
                                });
                                if (tableIds.findIndex(x => parseInt(x) === parseInt(resp.data[i].id)) > -1) {
                                    selectedTable.push({
                                        value: resp.data[i].id,
                                        label: resp.data[i].name + ' (' + resp.data[i].seating + ')'
                                    });
                                }
                            }
                            setSelectTable(selectedTable);
                            setTableList(list);
                        })
                        .catch(err => {

                        });
                }
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                navigate('/orders');
            })
    }, [uuid, navigate]);
    const showAddress = (addressData) => {
        let address = '';
        if (addressData) {
            let data = addressData;
            if (data.address_1) {
                address += data.address_1;
            }
            if (data.address_2) {
                address += ', ' + data.address_2;
            }
            if (data.city) {
                address += ', ' + data.city;
            }
            if (data.state) {
                address += ', ' + data.state;
            }
            if (data.country) {
                address += ', ' + data.country;
            }
            if (data.postcode) {
                address += ' - ' + data.postcode;
            }
        }
        return address;
    };
    const showPrice = (data) => {
        if (parseInt(orderDetail?.tax_type) === 1) {
            return parseFloat(data.item_price_included).toFixed(2);
        }
        return parseFloat(data.item_price_excluded).toFixed(2);
    }
    const showMultiGroup = (group) => {
        let data = '';
        for (let i = 0; i < group.item_list.length; i++) {
            data += group.item_list[i].item_name;
            data += ' (' + company.currency_symbol + showPrice(group.item_list[i]) + ')';
            if (group.item_list.length !== (i + 1)) {
                data += ', ';
            }
        }
        return data;
    };
    const showVariant = (data) => {
        let variant = '';
        if (data) {
            data = JSON.parse(data);
            if (data.single_group.length > 0) {
                for (let i = 0; i < data.single_group.length; i++) {
                    variant += '<div>';
                    variant += '<span class="fw-bold">' + data.single_group[i].name + ' : </span>';
                    variant += '<span>' + data.single_group[i].item_name + ' (' + company.currency_symbol + showPrice(data.single_group[i]) + ')</span>';
                    variant += '</div>';
                }
            }

            if (data.multi_group.length > 0) {
                for (let i = 0; i < data.multi_group.length; i++) {
                    variant += '<div>';
                    variant += '<span class="fw-bold">' + data.multi_group[i].name + ' : </span>';
                    variant += '<span>' + showMultiGroup(data.multi_group[i]) + '</span>';
                    variant += '</div>';
                }
            }
        }
        return variant;
    }
    useDocumentTitle(props.t(`OrderDetails`));


    const onChangeStatusPopup = (e) => {
        e.preventDefault();
        setChangeStatusModal(true);
    };
    const onChangeStatusPopupClose = (e) => {
        e.preventDefault();
        setChangeStatusModal(false);
        setStatusId('');
        let errorObj = {...errorsObj};
        setErrors(errorObj);
    };
    const onChangeStatus = (e, confirmFlag = false) => {
        e.preventDefault();
        let errorObj = {...errorsObj};
        let error = false;
        if (!statusId) {
            errorObj.status_id = props.t('RequiredStatus');
            error = true;
        }
        if (statusId) {
            if (parseInt(statusId) === parseInt(orderDetail.status_id)) {
                return false;
            }
            if (parseInt(statusId) === 1 && !confirmFlag) {
                setConfirmStatusModal(true);
                error = true;
            } else if (confirmFlag && !cancelReason) {
                errorObj.cancel_reason = props.t('ReasonRequired');
                error = true;
            }

            if (parseInt(orderDetail.business_mode) === 2 && parseInt(orderDetail.serve_type) === 3 && parseInt(statusId) === 6 && !orderDetail.table_id) {
                errorObj.status_id = props.t('PleaseAssignTable');
                error = true;
            }
        }
        setErrors(errorObj);
        if (error) return;
        setIsChangeStatusButtonLoad(true);
        let data = encryptData({uuid: uuid, status_id: statusId, cancel_reason: cancelReason});
        updateOrderStatus(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setChangeStatusModal(false);
                setIsChangeStatusButtonLoad(false);
                if (confirmFlag) {
                    setConfirmStatusModal(false);
                    setCancelReason('');
                }
                let orderData = {...orderDetail};
                orderData.status_id = statusId;
                setOrderDetail(orderData);
                setStatusId('');
            })
            .catch(err => {
                setChangeStatusModal(false);
                toast.error(Utils.getErrorMessage(err));
            });
    };

    const onAssignStatusPopup = (e) => {
        e.preventDefault();
        setAssignTableModal(true);
    };
    const onAssignModalClose = (e) => {
        e.preventDefault();
        setAssignTableModal(false);
    };
    const onAssignTables = (e) => {
        e.preventDefault();
        let errorObj = {...errorsObj};
        let error = false;
        if (selectTable.length === 0) {
            errorObj.table_id = props.t('PleaseSelectTables');
            error = true;
        }
        setErrors(errorObj);
        if (error) return;
        setIsAssignTableButtonLoad(true);
        let tableIds = selectTable.map(e => e.value);
        let tableName = selectTable.map(e => e.label).join();
        tableIds = tableIds.join();
        let data = encryptData({uuid: uuid, table_ids: tableIds});
        updateOrderStatus(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setAssignTableModal(false);
                setIsAssignTableButtonLoad(false);
                let orderData = {...orderDetail};
                orderData.table_id = tableIds;
                orderData.table_name = tableName;
                setOrderDetail(orderData);
            })
            .catch(err => {
                setIsAssignTableButtonLoad(false);
                toast.error(Utils.getErrorMessage(err));
            });
    };
    const onConfirmationClose = (e) => {
        e.preventDefault();
        setConfirmStatusModal(false);
    };

    return (
        <>
            <Breadcrumbs title={`OrderDetails`} parentLink={'/orders'} parent={`Orders`}/>
            <Container fluid={true}>
                {orderDetail?.order_number && (
                    <Row>
                        <Col sm="12">
                            <Card className="b-t-primary">
                                <CardBody>
                                    <Row>
                                        <Col sm={6}>
                                            <div className="text-md-end text-xs-center">
                                                <h3>{props.t(`Order`)} : #{orderDetail?.order_number}</h3>
                                                <div>
                                                    <span className={`f-w-600`}>{props.t(`CreatedDate`)} : </span>
                                                    <span>{orderDetail?.created_at}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="text-md-end text-xs-center text-right">
                                                {allPermissionsList.indexOf('modify_orders') > -1 && (parseInt(orderDetail.status_id) !== 6) && (
                                                    <div>
                                                        {(parseInt(orderDetail.status_id) !== 1) && (
                                                            <Button color={`primary`} className="btn-square mr-2"
                                                                    type="button"
                                                                    onClick={(e) => onChangeStatusPopup(e)}>{props.t(`ChangeStatus`)}
                                                            </Button>
                                                        )}
                                                        {parseInt(orderDetail.business_mode) === 2 && parseInt(orderDetail.serve_type) === 3 && (orderDetail.status_id > 1) && (
                                                            <Button color={`info`} className="btn-square ml-2"
                                                                    type="button"
                                                                    onClick={(e) => onAssignStatusPopup(e)}>{props.t(`AssignTable`)}
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <hr/>
                                        </Col>
                                    </Row>
                                    {parseInt(orderDetail?.status_id) > 1 && (
                                        <Row>
                                            <Col sm={12}>
                                                <Progress multi className={`height-35`}>
                                                    <Progress bar
                                                              color={parseInt(orderDetail?.status_id) === 6 ? `success` : ``}
                                                              value={Utils.calculateProcessBar(orderDetail.serve_type, orderDetail.status_id)}>{Utils.showOrderStatusName(orderDetail.serve_type, orderDetail.status_id)}</Progress>
                                                </Progress>
                                            </Col>
                                            <Col sm={12}>
                                                <hr/>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col sm={4}>
                                            {orderDetail?.address?.name && (
                                                <div className="media">
                                                    <div className="media-body">
                                                        <h4 className="media-heading">{orderDetail?.address?.name}</h4>
                                                        <p className={`mb-0`}>{orderDetail?.address?.mobile}</p>
                                                        <p className={`mb-0`}>{orderDetail?.address?.email}</p>
                                                        <p className={`mb-0`}>{showAddress(orderDetail?.address)}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                        <Col sm={8}>
                                            <div className="text-md-end text-right">
                                                <div>
                                                    <span className={`f-w-600`}>{props.t(`Branch`)} : </span>
                                                    <span>{orderDetail?.branch_name}</span>
                                                </div>
                                                <div>
                                                    <span className={`f-w-600`}>{props.t(`ServeType`)} : </span>
                                                    <span> {orderDetail?.serve_type_name}</span>
                                                </div>
                                                <div>
                                                    <span className={`f-w-600`}>{props.t(`PaymentMethod`)} : </span>
                                                    <span> {orderDetail?.payment_method}</span>
                                                </div>
                                                {orderDetail?.payment_id && (
                                                    <div>
                                                        <span className={`f-w-600`}>{props.t(`PaymentId`)} : </span>
                                                        <span> {orderDetail?.payment_id}</span>
                                                    </div>
                                                )}
                                                {orderDetail?.pax_number > 0 && (
                                                    <div>
                                                        <span className={`f-w-600`}>{props.t(`TotalPax`)} : </span>
                                                        <span> {orderDetail?.pax_number}</span>
                                                    </div>
                                                )}
                                                {orderDetail?.customer_tag && (
                                                    <div>
                                                        <span className={`f-w-600`}>{props.t(`CustomerTag`)} : </span>
                                                        <span>{orderDetail?.customer_tag}</span>
                                                    </div>
                                                )}
                                                <div>
                                                    <span className={`f-w-600`}>{props.t(`Status`)} : </span>
                                                    <span
                                                        className={`badge ${parseInt(orderDetail.status_id) === 1 ? `badge-light-danger` : parseInt(orderDetail.status_id) === 6 ? `badge-light-success` : `badge-light-primary`}`}>{Utils.showOrderStatusName(orderDetail.serve_type, orderDetail.status_id)}</span>
                                                </div>
                                                {orderDetail?.table_name && (
                                                    <div>
                                                        <span className={`f-w-600`}>{props.t(`AssignedTable`)} : </span>
                                                        <span>{orderDetail.table_name}</span>
                                                    </div>
                                                )}
                                                {discountData?.name && (
                                                    <div>
                                                        <span className={`f-w-600`}>{props.t(`PromoCode`)} : </span>
                                                        <span>{discountData.name}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <hr/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="table-responsive invoice-table" id="table">
                                                <table className="table table-bordered table-striped">
                                                    <tbody>
                                                    <tr>
                                                        <td className="item">
                                                            <h6 className="p-2 mb-0">{props.t(`Product`)} </h6>
                                                        </td>
                                                        {parseInt(orderDetail?.tax_type) === 2 && (
                                                            <td className="Hours">
                                                                <h6 className="p-2 mb-0">{props.t(`Tax`)}</h6>
                                                            </td>
                                                        )}
                                                        <td className="Rate">
                                                            <h6 className="p-2 mb-0">{props.t(`Quantity`)}</h6>
                                                        </td>
                                                        <td className="subtotal">
                                                            <h6 className="p-2 mb-0">{props.t(`Price`)}</h6>
                                                        </td>
                                                    </tr>
                                                    {productList.map((product, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <p className="itemtext">{product.product_name} <br/>
                                                                    <span> {props.t(`price`)} :{company.currency_symbol}{parseFloat(product.price).toFixed(2)}</span>
                                                                </p>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: showVariant(product.pro_detail)}}/>
                                                            </td>
                                                            {parseInt(orderDetail?.tax_type) === 2 && (
                                                                <td>
                                                                    {product.tax > 0 && (
                                                                        <span>{(product.tax > 0) ? product.tax : 0}%</span>
                                                                    )}
                                                                </td>
                                                            )}
                                                            <td>
                                                                {product.quantity}
                                                            </td>
                                                            <td>
                                                                <p className="m-0">{company.currency_symbol}{parseFloat(product.total_price).toFixed(2)}</p>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td className="Rate text-right"
                                                            colSpan={(parseInt(orderDetail?.tax_type) === 2) ? 3 : 2}>
                                                            <h6 className="mb-0 p-2">{props.t('Price')}</h6>
                                                        </td>
                                                        <td className="payment">
                                                            <h6 className="mb-0 p-2">{company.currency_symbol}{parseFloat(orderDetail.amount).toFixed(2)}</h6>
                                                        </td>
                                                    </tr>
                                                    {parseInt(orderDetail.discount) > 0 && (
                                                        <tr>
                                                            <td className="Rate text-right"
                                                                colSpan={(parseInt(orderDetail?.tax_type) === 2) ? 3 : 2}>
                                                                <h6 className="mb-0 p-2">{props.t('Discount')} </h6>
                                                            </td>
                                                            <td className="payment">
                                                                <h6 className="mb-0 p-2">{company.currency_symbol}{parseFloat(orderDetail.discount).toFixed(2)}</h6>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {parseInt(orderDetail.delivery_rate) > 0 && (
                                                        <tr>
                                                            <td className="Rate text-right"
                                                                colSpan={(parseInt(orderDetail?.tax_type) === 2) ? 3 : 2}>
                                                                <h6 className="mb-0 p-2">{props.t('DeliveryCharge')} </h6>
                                                            </td>
                                                            <td className="payment">
                                                                <h6 className="mb-0 p-2">{company.currency_symbol}{parseFloat(orderDetail.delivery_rate).toFixed(2)}</h6>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {parseInt(orderDetail.take_away_charge) > 0 && (
                                                        <tr>
                                                            <td className="Rate text-right"
                                                                colSpan={(parseInt(orderDetail?.tax_type) === 2) ? 3 : 2}>
                                                                <h6 className="mb-0 p-2">{props.t('ServiceCharge')} </h6>
                                                            </td>
                                                            <td className="payment">
                                                                <h6 className="mb-0 p-2">{company.currency_symbol}{parseFloat(orderDetail.take_away_charge).toFixed(2)}</h6>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td className="Rate text-right"
                                                            colSpan={(parseInt(orderDetail?.tax_type) === 2) ? 3 : 2}>
                                                            <h6 className="mb-0 p-2">{props.t('TotalPrice')}</h6>
                                                        </td>
                                                        <td className="payment">
                                                            <h6 className="mb-0 p-2">{company.currency_symbol}{parseFloat(orderDetail.total_amount).toFixed(2)}</h6>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal isOpen={assignTableModal}>
                <ModalHeader>{props.t(`AssignTable`)}</ModalHeader>
                <ModalBody>
                    <Select options={tableList} isMulti value={selectTable}
                            onChange={(e) => setSelectTable(e)}
                            placeholder={props.t(`PleaseSelectTables`)}
                            name={`table_id`}/>
                    {errors.table_id && (
                        <span
                            className={`invalid-feedback`}>{errors.table_id}</span>)}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={`button`} disabled={isAssignTableButtonLoad} outline
                            className={`btn-square`}
                            onClick={onAssignModalClose}>{props.t(`Close`)}</Button>
                    {!isAssignTableButtonLoad && (
                        <Button color="primary" type={`button`} className={`btn-square`}
                                onClick={onAssignTables}>{props.t(`Assign`)}</Button>
                    )}
                    {isAssignTableButtonLoad && (
                        <Button color="primary" type={`button`} disabled={true} className={`btn-square`}>
                            <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
            <Modal isOpen={changeStatusModal}>
                <ModalHeader>{props.t(`ChangeStatus`)}</ModalHeader>
                <ModalBody>
                    <Input type={`select`} name={`status_id`} value={statusId}
                           onChange={(e) => setStatusId(e.target.value)}>
                        <option value="">{props.t(`SelectStatus`)}</option>
                        {statusList.slice(parseInt(orderDetail.status_id) + 1).map((item, index) => (
                            <option value={item.id} key={index}>{item.name}</option>
                        ))}
                    </Input>
                    {errors.status_id && (<span className={`invalid-feedback`}>{errors.status_id}</span>)}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={`button`} disabled={isChangeStatusButtonLoad} outline
                            className={`btn-square`}
                            onClick={onChangeStatusPopupClose}>{props.t(`Close`)}</Button>
                    {!isChangeStatusButtonLoad && (
                        <Button color="primary" type={`button`} className={`btn-square`}
                                onClick={onChangeStatus}>{props.t(`Change`)}</Button>
                    )}
                    {isChangeStatusButtonLoad && (
                        <Button color="primary" type={`button`} disabled={true} className={`btn-square`}>
                            <i className="fa fa-circle-o-notch fa-spin"/> {props.t(`Loading`)}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>

            <Modal isOpen={confirmStatusModal}>
                <ModalHeader>{props.t(`CancelConfirmation`)}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <p className={`mb-0`}>{props.t(`ConfirmationWarn`)}</p>
                        </Col>
                        <Col sm="12">
                            <FormGroup>
                                <Label className="col-form-label">{props.t(`CancelReason`)}</Label>
                                <Input className="form-control" type="textarea" name={`cancel_reason`}
                                       value={cancelReason} rows={5}
                                       onChange={(e) => setCancelReason(e.target.value)}
                                       placeholder={props.t(`EnterCancelReason`)}/>
                            </FormGroup>
                            {errors.cancel_reason && (
                                <span className={`invalid-feedback`}>{errors.cancel_reason}</span>)}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={`button`} disabled={isChangeStatusButtonLoad} outline
                            className={`btn-square`}
                            onClick={onConfirmationClose}>{props.t(`Close`)}</Button>
                    {!isChangeStatusButtonLoad && (
                        <Button color="danger" type={`button`} className={`btn-square`}
                                onClick={(e) => onChangeStatus(e, true)}>{props.t(`Confirm`)}</Button>
                    )}
                    {isChangeStatusButtonLoad && (
                        <Button color="primary" type={`button`} disabled={true} className={`btn-square`}>
                            <i className="fa fa-circle-o-notch fa-spin"/> {props.t(`Loading`)}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default translate(ViewOrder);