import React, {useEffect, useState, useRef} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row} from "reactstrap";

import useDocumentTitle from "../../utils/useDocumentTitle";
import {loaderActions} from "../../store";
import {decryptData, encryptData} from "../../utils/crypto";
import {getAllTables, getCheckBranch, getUpdateTableLayout} from "../../services/Branch";
import Utils from "../../utils";
import Breadcrumbs from "../partials/Breadcrumbs";
import configDb from "../../config";

function BranchTableLayout(props) {
    const {uuid} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const company = useSelector(x => x.company.value);
    const [branchData, setBranchData] = useState({id: '', display_name: ''});
    const [allList, setAllList] = useState([]);
    const [allTempList, setAllTempList] = useState([]);
    const [usedSearch, setUsedSearch] = useState('');
    const [unUsedSearch, setUnUsedSearch] = useState('');
    const [isUpdateButton, setIsUpdateButton] = useState(false);

    const tableDragIndex = useRef(null);
    const tableDragOverIndex = useRef(null);
    const tableDragOverItem = useRef(null);

    useDocumentTitle(branchData.display_name + ' ' + props.t(`TableLayout`));

    useEffect(() => {
        if (company.business_category_name === configDb.data.restaurant && company.business_mode === 2) {
            dispatch(loaderActions.loader(true));
            let data = encryptData({uuid: uuid, language: getLanguage()});
            getCheckBranch(data)
                .then(response => {
                    response = decryptData(response.data);
                    setBranchData(response.data);
                    dispatch(loaderActions.loader(false));
                })
                .catch(err => {
                    dispatch(loaderActions.loader(false));
                    toast.error(Utils.getErrorMessage(err));
                    navigate('/branch');
                });
        } else {
            navigate('/403');
        }
    }, [company, uuid, navigate, dispatch]);

    useEffect(() => {
        if (branchData.id) {
            let data = encryptData({branch_id: branchData.id, language: getLanguage()});
            getAllTables(data)
                .then(response => {
                    response = decryptData(response.data);
                    setAllList(response.data);
                    setAllTempList(response.data);
                })
                .catch(err => {

                });
        }
    }, [branchData]);

    const onTableDragStart = (e, index) => {
        tableDragIndex.current = index;
    }

    const onTableDragEnter = (e, index) => {
        tableDragOverIndex.current = index;
    }

    const onTableDragOver = (e) => {
        let id = e.target.getAttribute('data-id');
        let isUsed = 0;
        if (id === 'used') {
            isUsed = 1;
        }
        tableDragOverItem.current = isUsed;
    };

    const onTableDragEnd = (e) => {
        let list = [...allList];
        let dragItemContent = list[tableDragIndex.current];
        dragItemContent.is_used = tableDragOverItem.current;
        list.splice(tableDragIndex.current, 1);
        list.splice(tableDragOverIndex.current, 0, dragItemContent);
        tableDragOverIndex.current = null;
        tableDragIndex.current = null;
        tableDragOverItem.current = null;
        setAllList(list);
    }

    const handleUsedTableSearch = (e) => {
        let value = e.target.value;
        let list = [...allTempList];
        let usedTableList = list.filter(item => item.is_used === 1);
        let filtered = usedTableList.filter(item => item.name.includes(value));
        setUsedSearch(value);
        let unUsedTableList = list.filter(item => item.is_used === 0);
        let combined = [...filtered, ...unUsedTableList];
        setAllList(combined);
        setUnUsedSearch('');
    }

    const handleUnUsedTableSearch = (e) => {
        let value = e.target.value;
        let list = [...allTempList];
        let unUsedTableList = list.filter(item => item.is_used === 0);
        let filtered = unUsedTableList.filter(item => item.name.includes(value));
        setUnUsedSearch(value);
        let usedTableList = list.filter(item => item.is_used === 1);
        let combined = [...filtered, ...usedTableList];
        setAllList(combined);
        setUsedSearch('');
    }

    const onUpdateTables = (e) => {
        e.preventDefault();
        setIsUpdateButton(true);
        let data = encryptData({
            branch_id: branchData.id,
            language: getLanguage(),
            list: allList
        });
        getUpdateTableLayout(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setIsUpdateButton(false);
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsUpdateButton(false);
            });
    }
    return (
        <>
            <Breadcrumbs title={'TableLayout'} parent={'Branch'} parentLink={'/branch'}
                         childLink={`/branch/${uuid}/table`} child={'Tables'}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={6}>
                        <Card>
                            <CardHeader className={`py-4`}>
                                <h5>{props.t('UsedTable')}</h5>
                            </CardHeader>
                            <CardBody onDragEnd={onTableDragEnd} onDragOver={onTableDragOver} data-id={`used`}>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Input type={`text`} value={usedSearch} onChange={handleUsedTableSearch}
                                                   placeholder={props.t(`Search`)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row data-id={`used`}>
                                    {allList && allList.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {parseInt(item.is_used) === 1 && (
                                                <Col sm={4} data-id={`used`}>
                                                    <Card className={`o-hidden rounded-0 bg-primary py-4 cur-grab`}
                                                          data-id={`used`} draggable
                                                          onDragStart={(e) => onTableDragStart(e, index)}
                                                          onDragEnter={(e) => onTableDragEnter(e, index)}>
                                                        <h6 className={`mb-0 text-center`}
                                                            data-id={`used`}>{item.name}</h6>
                                                    </Card>
                                                </Col>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <CardHeader className={`py-4`}>
                                <h5>{props.t('NotUsedTable')}</h5>
                            </CardHeader>
                            <CardBody onDragEnd={onTableDragEnd} onDragOver={onTableDragOver} data-id={`unused`}>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Input type={`text`} value={unUsedSearch} onChange={handleUnUsedTableSearch}
                                                   placeholder={props.t(`Search`)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row data-id={`unused`}>
                                    {allList && allList.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {parseInt(item.is_used) === 0 && (
                                                <Col sm={4} data-id={`unused`}>
                                                    <Card className={`o-hidden rounded-0 bg-secondary py-4 cur-grab`}
                                                          data-id={`unused`} draggable
                                                          onDragStart={(e) => onTableDragStart(e, index)}
                                                          onDragEnter={(e) => onTableDragEnter(e, index)}>
                                                        <h6 className={`mb-0 text-center`}
                                                            data-id={`unused`}>{item.name}</h6>
                                                    </Card>
                                                </Col>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12}>
                        <Card>
                            <CardBody className={`text-right`}>
                                <NavLink to={`/branch/${uuid}/table`} role={`button`} end
                                         className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                {allList && allList.length > 0 && (
                                    <>
                                        {!isUpdateButton && (
                                            <Button type={`button`} color={`primary`} onClick={onUpdateTables}
                                                    className={`btn-square`}>{props.t(`Submit`)}</Button>
                                        )}
                                        {isUpdateButton && (
                                            <Button color="primary" type={`button`} disabled={true}
                                                    className={`btn-square`}>
                                                <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default translate(BranchTableLayout);