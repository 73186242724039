import React, {useEffect, useState} from "react";
import {translate, getLanguage} from "react-switch-lang";
import Breadcrumbs from "../partials/Breadcrumbs";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
    Table
} from "reactstrap";
import {NavLink} from "react-router-dom";
import configDb from "../../config";
import {decryptData, encryptData} from "../../utils/crypto";
import {toast} from "react-toastify";
import Utils from "../../utils";
import Pagination from "react-js-pagination";
import Select from "react-select";
import {actionDeleteCategory, getCategoryList} from "../../services/Category";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {useSelector} from "react-redux";
import {getAllBranchList} from "../../services/Branch";

const tableLengthList = configDb.data.dataTableLength;
const statusList = configDb.data.statusSelect2List;

function Category(props) {
    const allPermissionsList = useSelector(x => x.permission.value);
    const loginAuth = useSelector(x => x.auth.value);
    const [categoryList, setCategoryList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [delModal, setDelModal] = useState(false);
    const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
    const [delCategory, setDelCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useDocumentTitle(props.t('Category'));
    const [params, setParams] = useState({
        limit: tableLengthList[0],
        page: 1,
        search: "",
        is_reload: false,
        status_id: '',
        branch_id: ''
    });
    useEffect(function () {
        let data = encryptData({category_id: ''});
        getAllBranchList(data)
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (loginAuth?.main_branch) {
                        if (parseInt(response.data[i].id) === parseInt(loginAuth.main_branch)) {
                            list.push({
                                value: response.data[i].id,
                                label: response.data[i].display_name
                            });
                        }
                    } else {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].display_name
                        });
                    }
                }
                setBranchList(list);
            })
            .catch(err => {

            });
    }, [loginAuth]);

    useEffect(function () {
        let data = encryptData(params);
        setIsLoading(true);
        getCategoryList(data)
            .then(response => {
                response = decryptData(response.data);
                setTotalRecords(response.total);
                setCategoryList(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
            });
    }, [params]);
    const handleParams = (e, type) => {
        let paramsObj = {...params};
        if (type === 'length') {
            paramsObj.limit = e.target.value;
        } else if (type === 'search') {
            paramsObj.page = 1;
            paramsObj.search = e.target.value;
        } else if (type === 'pagination') {
            paramsObj.page = e;
        } else if (type === 'status') {
            paramsObj.page = 1;
            paramsObj.status_id = (e?.value) ? e?.value : '';
        } else if (type === 'branch') {
            paramsObj.page = 1;
            paramsObj.branch_id = (e?.value) ? e?.value : '';
        }
        setParams(paramsObj);
    }
    const onDeleteCategory = (e, item) => {
        e.preventDefault();
        setDelCategory(item.uuid);
        setDelModal(true);
    }
    const onDeleteModalClose = (e) => {
        e.preventDefault();
        setDelCategory('');
        setDelModal(false);
    }
    const onDestroyCategory = (e) => {
        e.preventDefault();
        setIsDelButtonLoad(true);
        let data = encryptData({uuid: delCategory, language: getLanguage()});
        actionDeleteCategory(data)
            .then(response => {
                let paramsObj = {...params};
                paramsObj.page = 1;
                paramsObj.is_reload = !paramsObj.is_reload;
                setParams(paramsObj);
                response = decryptData(response.data);
                toast.success(response.message);
                setDelCategory('');
                setDelModal(false);
                setIsDelButtonLoad(false);
            })
            .catch(err => {
                setIsDelButtonLoad(false);
                toast.error(Utils.getErrorMessage(err));
            });
    }
    return (
        <>
            <Breadcrumbs title={`Category`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardHeader className={`py-4`}>
                                <h5>
                                    <span>{props.t(`ListCategory`)}</span>
                                    {allPermissionsList.indexOf('modify_category') > -1 && (
                                        <NavLink to={`/category/create`} role={`button`}
                                                 className={`btn btn-sm btn-square float-right btn-primary`}>{props.t(`AddCategory`)}</NavLink>
                                    )}
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={1}>
                                        <FormGroup>
                                            <select name="length" className="form-control" value={params.limit}
                                                    onChange={(e) => handleParams(e, 'length')}>
                                                {tableLengthList && tableLengthList.map((item, i) =>
                                                    <option value={item} key={i}>{item}</option>
                                                )}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className={`offset-sm-4`}>
                                        <FormGroup>
                                            <Select options={branchList} onChange={(e) => handleParams(e, 'branch')}
                                                    placeholder={props.t(`PleaseSelectBranch`)}
                                                    isClearable={true}
                                                    name={`branch_id`}/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Select options={statusList} onChange={(e) => handleParams(e, 'status')}
                                                    placeholder={props.t(`Status`)}
                                                    isClearable={true}
                                                    name={`status_id`}/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Input type={`text`} value={params.search}
                                                   onChange={(e) => handleParams(e, 'search')}
                                                   className={`form-control`}
                                                   placeholder={props.t(`Search`)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <Table className={`table-bordered ${isLoading ? `loading` : ``}`}>
                                        <thead>
                                        <tr>
                                            <th scope={`col`}>#</th>
                                            <th scope={`col`}>{props.t(`Name`)}</th>
                                            <th scope={`col`}>{props.t(`BranchName`)}</th>
                                            <th scope={`col`}>{props.t(`IsActive`)}</th>
                                            {(allPermissionsList.indexOf('modify_category') > -1 || allPermissionsList.indexOf('delete_category') > -1) && (
                                                <th scope={`col`}>{props.t(`Action`)}</th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {categoryList && categoryList.map((item, i) =>
                                            <tr key={i}>
                                                <td>{item.index}</td>
                                                <td>{item.name}</td>
                                                <td>{item.branch_name}</td>
                                                <td>
                                                    {item.status_id === 1 ?
                                                        <Badge color={`success`}>{item.is_active}</Badge> :
                                                        <Badge color={`danger`}>{item.is_active}</Badge>}
                                                </td>
                                                {(allPermissionsList.indexOf('modify_category') > -1 || allPermissionsList.indexOf('delete_category') > -1) && (
                                                    <td>
                                                        {allPermissionsList.indexOf('modify_category') > -1 && (
                                                            <NavLink to={`/category/${item.uuid}/edit`}
                                                                     role={`button`}
                                                                     className={`btn btn-square btn-outline-primary btn-sm mr-1`}>{props.t(`Edit`)}</NavLink>
                                                        )}
                                                        {allPermissionsList.indexOf('delete_category') > -1 && (
                                                            <Button outline color={`danger`} type={`button`}
                                                                    onClick={(e) => onDeleteCategory(e, item)}
                                                                    className={`btn-sm btn-square`}>{props.t(`Delete`)}</Button>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                        {categoryList && categoryList.length === 0 && (
                                            <tr>
                                                <td colSpan={(allPermissionsList.indexOf('modify_category') > -1 || allPermissionsList.indexOf('delete_category') > -1) ? 5 : 4}
                                                    className={`text-center`}>{props.t(`RecordNotFound`)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                                <Row>
                                    <Col sm={12} className={`mt-3`}>
                                        <div className={`float-right`}>
                                            <Pagination
                                                activePage={params.page}
                                                itemsCountPerPage={parseInt(params.limit)}
                                                totalItemsCount={totalRecords}
                                                pageRangeDisplayed={5}
                                                itemClass={`page-item`}
                                                linkClass={`page-link`}
                                                onChange={(e) => handleParams(e, 'pagination')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={delModal}>
                <ModalHeader>{props.t(`DeleteCategory`)}</ModalHeader>
                <ModalBody>
                    <p className={`mb-0`}>{props.t(`DeleteWarn`)}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={`button`} disabled={isDelButtonLoad} outline className={`btn-square`}
                            onClick={onDeleteModalClose}>{props.t(`Close`)}</Button>
                    {!isDelButtonLoad && (
                        <Button color="secondary" type={`button`} className={`btn-square`}
                                onClick={onDestroyCategory}>{props.t(`Delete`)}</Button>
                    )}
                    {isDelButtonLoad && (
                        <Button color="secondary" type={`button`} className={`btn-square`} disabled={true}>
                            <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default translate(Category);