import React, {useEffect, useState} from "react";
import {translate} from "react-switch-lang";
import {useSelector} from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Row,
    Table
} from "reactstrap";
import {NavLink} from "react-router-dom";
import Select from "react-select";
import Pagination from "react-js-pagination";

import useDocumentTitle from "../../utils/useDocumentTitle";
import configDb from "../../config";
import Breadcrumbs from "../partials/Breadcrumbs";
import {decryptData, encryptData} from "../../utils/crypto";
import {getAllBranchList} from "../../services/Branch";
import {getOrderList} from "../../services/Order";
import Utils from "../../utils";

const tableLengthList = configDb.data.dataTableLength;
const applicationForList = configDb.data.applicableForList;

function Orders(props) {
    const allPermissionsList = useSelector(x => x.permission.value);
    const company = useSelector(x => x.company?.value);
    const loginAuth = useSelector(x => x.auth.value);
    const [branchList, setBranchList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [selectStatus, setSelectStatus] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState({
        limit: tableLengthList[0],
        page: 1,
        serve_type: "",
        status_id: "",
        is_reload: false,
        branch_id: '',
    });

    useDocumentTitle(props.t(`ListOrders`));

    useEffect(() => {
        let data = encryptData({category_id: ''});
        getAllBranchList(data)
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (loginAuth?.main_branch) {
                        if (parseInt(response.data[i].id) === parseInt(loginAuth.main_branch)) {
                            list.push({
                                value: response.data[i].id,
                                label: response.data[i].display_name
                            });
                        }
                    } else {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].display_name
                        });
                    }
                }
                setBranchList(list);
            })
            .catch(err => {

            });
    }, [loginAuth]);

    useEffect(() => {
        let data = encryptData(params);
        setIsLoading(true);
        getOrderList(data)
            .then(response => {
                response = decryptData(response.data);
                setTotalRecords(response.total);
                setOrderList(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
            });
    }, [params]);

    const handleParams = (e, type) => {
        let paramsObj = {...params};
        if (type === 'length') {
            paramsObj.limit = e.target.value;
        } else if (type === 'serve_type') {
            paramsObj.page = 1;
            paramsObj.serve_type = (e?.value) ? e?.value : '';
            paramsObj.status_id = '';
            if (e?.value) {
                if (e?.value === 1) {
                    let list = [];
                    for (let i = 0; i < configDb.data.selfPickUpStatusList.length; i++) {
                        list.push({
                            value: configDb.data.selfPickUpStatusList[i].id,
                            label: configDb.data.selfPickUpStatusList[i].name
                        });
                    }
                    setStatusList(list);
                } else if (e?.value === 2) {
                    let list = [];
                    for (let i = 0; i < configDb.data.deliveryStatusList.length; i++) {
                        list.push({
                            value: configDb.data.deliveryStatusList[i].id,
                            label: configDb.data.deliveryStatusList[i].name
                        });
                    }
                    setStatusList(list);
                } else if (e?.value === 3) {
                    let list = [];
                    for (let i = 0; i < configDb.data.dineInStatusList.length; i++) {
                        list.push({
                            value: configDb.data.dineInStatusList[i].id,
                            label: configDb.data.dineInStatusList[i].name
                        });
                    }
                    setStatusList(list);
                } else {
                    setStatusList([]);
                }
            } else {
                setStatusList([]);
            }
            setSelectStatus(null);
        } else if (type === 'status_id') {
            paramsObj.page = 1;
            paramsObj.status_id = (e?.value > -1) ? e?.value : '';
            setSelectStatus(e);
        } else if (type === 'pagination') {
            paramsObj.page = e;
        } else if (type === 'branch') {
            paramsObj.page = 1;
            paramsObj.branch_id = (e?.value) ? e?.value : '';
        }
        setParams(paramsObj);
    }
    return (
        <>
            <Breadcrumbs title={`Orders`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardHeader className={`py-4`}>
                                <h5>
                                    <span>{props.t(`ListOrders`)}</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={1}>
                                        <FormGroup>
                                            <select name="length" className="form-control" value={params.limit}
                                                    onChange={(e) => handleParams(e, 'length')}>
                                                {tableLengthList && tableLengthList.map((item, i) =>
                                                    <option value={item} key={i}>{item}</option>
                                                )}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className={`offset-sm-2`}>
                                        <FormGroup>
                                            <Select options={branchList} onChange={(e) => handleParams(e, 'branch')}
                                                    placeholder={props.t(`PleaseSelectBranch`)}
                                                    isClearable={true} name={`branch_id`}/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Select options={applicationForList}
                                                    onChange={(e) => handleParams(e, 'serve_type')}
                                                    placeholder={props.t(`SelectServeType`)}
                                                    isClearable={true} name={`serve_type`}/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Select options={statusList} value={selectStatus}
                                                    onChange={(e) => handleParams(e, 'status_id')}
                                                    placeholder={props.t(`Status`)} isClearable={true}
                                                    name={`status_id`}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <Table className={`table-bordered ${isLoading ? `loading` : ``}`}>
                                        <thead>
                                        <tr>
                                            <th scope={`col`}>#</th>
                                            <th scope={`col`}>{props.t(`OrderNumber`)}</th>
                                            <th scope={`col`}>{props.t(`BranchName`)}</th>
                                            <th scope={`col`}>{props.t(`PaymentMethod`)}</th>
                                            <th scope={`col`}>{props.t(`ServeType`)}</th>
                                            <th scope={`col`}>{props.t(`Status`)}</th>
                                            <th scope={`col`}>{props.t(`TotalAmount`)}</th>
                                            <th scope={`col`}>{props.t(`OrderDate`)}</th>
                                            {(allPermissionsList.indexOf('modify_payment_method') > -1 || allPermissionsList.indexOf('delete_payment_method') > -1) && (
                                                <th scope={`col`}>{props.t(`Action`)}</th>
                                            )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {orderList && orderList.map((item, i) =>
                                            <tr key={i}>
                                                <td>{item.index}</td>
                                                <td>#{item.order_number}</td>
                                                <td>{item.branch_name}</td>
                                                <td>{item.payment_method}</td>
                                                <td>{item.serve_type_name}</td>
                                                <td>
                                                    <span
                                                        className={`badge ${parseInt(item.status_id) === 1 ? `badge-light-danger` : parseInt(item.status_id) === 6 ? `badge-light-success` : `badge-light-primary`}`}>
                                                    {Utils.showOrderStatusName(item.serve_type, item.status_id)}
                                                    </span>
                                                </td>
                                                <td>{company.currency_symbol}{parseFloat(item.total_amount).toFixed(2)}</td>
                                                <td>{item.created_at}</td>
                                                {allPermissionsList.indexOf('view_orders') > -1 && (
                                                    <td>
                                                        <NavLink to={`/orders/${item.uuid}`}
                                                                 role={`button`}
                                                                 className={`btn btn-outline-primary btn-square btn-sm mr-1`}>{props.t(`View`)}</NavLink>
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                        {orderList && orderList.length === 0 && (
                                            <tr>
                                                <td colSpan={(allPermissionsList.indexOf('view_orders') > -1) ? 8 : 7}
                                                    className={`text-center`}>{props.t(`RecordNotFound`)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                                <Row>
                                    <Col sm={12} className={`mt-3`}>
                                        <div className={`float-right`}>
                                            <Pagination
                                                activePage={params.page}
                                                itemsCountPerPage={parseInt(params.limit)}
                                                totalItemsCount={totalRecords}
                                                pageRangeDisplayed={5}
                                                itemClass={`page-item`}
                                                linkClass={`page-link`}
                                                onChange={(e) => handleParams(e, 'pagination')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default translate(Orders);