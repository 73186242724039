import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Media, Row} from "reactstrap";
import {useSelector} from "react-redux";
import Select from "react-select";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import useDocumentTitle from "../../utils/useDocumentTitle";
import Breadcrumbs from "../partials/Breadcrumbs";
import configDb from "../../config";
import {decryptData, encryptData} from "../../utils/crypto";
import {getAllBranchList} from "../../services/Branch";
import Utils from "../../utils";
import {actionStoreDisplayBanner} from "../../services/DisplayBanners";

const statusList = configDb.data.statusList;
const bannerForList = configDb.data.bannerForList;

function CreateDisplayBanner(props) {
    const navigate = useNavigate();
    const loginAuth = useSelector(x => x.auth.value);
    const [name, setName] = useState('');
    const [branchList, setBranchList] = useState([]);
    const [selectBranch, setSelectBranch] = useState([]);
    const [bannerFor, setBannerFor] = useState('');
    const [bannerSize, setBannerSize] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [imageList, setImageList] = useState([{file: {}, image: '', error: ''}]);
    const [isLoading, setIsLoading] = useState(false);
    let errorsObj = {
        name: '',
        branch_id: '',
        banner_for: ''
    };
    const [errors, setErrors] = useState(errorsObj);

    useDocumentTitle(props.t('CreateDisplayBanner'));

    useEffect(() => {
        let data = encryptData({category_id: ''});
        getAllBranchList(data)
            .then(response => {
                response = decryptData(response.data);
                let list = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (loginAuth?.branch_id) {
                        let branchIds = loginAuth?.branch_id.split(',');
                        if (branchIds.indexOf(response.data[i].id.toString()) > -1) {
                            list.push({
                                value: response.data[i].id,
                                label: response.data[i].display_name
                            });
                        }
                    } else {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].display_name
                        });
                    }
                }
                setBranchList(list);
            })
            .catch(err => {

            });
    }, [loginAuth]);

    const handleBannerFor = (e) => {
        let value = e.target.value;
        let size = '';
        if (value) {
            let index = bannerForList.findIndex(x => parseInt(x.value) === parseInt(value));
            if (index > -1) {
                size = bannerForList[index]['size'];
            }
        }
        setBannerSize(size);
        setBannerFor(value);
    }

    const handleSelectImage = (e, index) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            let list = [...imageList];
            if (!e.target.files[0].name.match(configDb.data.imageExt)) {
                list[index]['file'] = {};
                list[index]['error'] = props.t(`InvalidImage`);
                list[index].image = '';
            } else if (((e.target.files[0].size / 1000) / 1000) > 2) {
                list[index]['file'] = {};
                list[index]['error'] = props.t(`InvalidImageSize`);
                list[index].image = '';
            } else {
                list[index].image = URL.createObjectURL(e.target.files[0]);
                list[index]['file'] = e.target.files[0];
                list[index]['error'] = '';
            }
            setImageList(list);
        }
    }

    const handleAddImage = (e) => {
        e.preventDefault();
        let list = [...imageList];
        list.push({file: {}, image: '', error: ''});
        setImageList(list);
    }

    const handleImageRemove = (e, index) => {
        e.preventDefault();
        let list = [...imageList];
        list.splice(index, 1);

        setImageList(list);
    };

    const onCreateBanner = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!name.trim()) {
            errorObj.name = props.t(`RequiredName`);
            error = true;
        }
        if (!bannerFor) {
            errorObj.banner_for = props.t(`RequiredBannerFor`);
            error = true;
        }
        if (selectBranch.length === 0) {
            errorObj.branch_id = props.t('RequiredBranch');
            error = true;
        }
        for (let i = 0; i < imageList.length; i++) {
            if (imageList[i]['error']) {
                error = true;
            } else if (!imageList[i]['file']?.name) {
                imageList[i]['error'] = props.t('RequiredImage');
                error = true;
            } else {
                imageList[i]['error'] = '';
            }
        }

        setErrors(errorObj);
        Utils.focusError();

        if (error) return;

        setIsLoading(true);
        let branchIds = selectBranch.map(e => e.value);
        branchIds = branchIds.join();
        const formData = new FormData();
        formData.append('language', getLanguage());
        formData.append('name', name.trim());
        formData.append('branch_id', branchIds);
        formData.append('banner_for', bannerFor);
        formData.append('status_id', statusId);
        for (let i = 0; i < imageList.length; i++) {
            formData.append('images', imageList[i]['file']);
        }

        actionStoreDisplayBanner(formData)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setIsLoading(false);
                navigate('/display-banner');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    };

    return (
        <>
            <Breadcrumbs parentLink={'/display-banner'} parent={`DisplayBanner`} title={`CreateDisplayBanner`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onCreateBanner} encType={`multipart/form-data`}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Name`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`name`}
                                                       value={name} onChange={(e) => setName(e.target.value)}
                                                       placeholder={props.t(`EnterName`)} maxLength={50}/>
                                                {errors.name && (
                                                    <span className={`invalid-feedback`}>{errors.name}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Branch`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Select options={branchList} isMulti value={selectBranch}
                                                        onChange={(e) => setSelectBranch(e)}
                                                        placeholder={props.t(`PleaseSelectBranch`)}
                                                        name={`branch_id`}/>
                                                {errors.branch_id && (
                                                    <span className={`invalid-feedback`}>{errors.branch_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label
                                                    className="col-form-label">{props.t(`BannerFor`) + ' ' + (bannerSize && props.t(`RecommendedResolution`, {size: bannerSize}))}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input type="select" name={`entity_type`} value={bannerFor}
                                                       onChange={handleBannerFor}>
                                                    <option value="">Select banner for</option>
                                                    {bannerForList.map((item, i) => (
                                                        <option key={i} value={item.value}>{item.label}</option>
                                                    ))}
                                                </Input>
                                                {errors.banner_for && (
                                                    <span className={`invalid-feedback`}>{errors.banner_for}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input name="status_id" type="select" value={statusId}
                                                       onChange={(e) => setStatusId(e.target.value)}>
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {imageList && imageList.map((item, i) => (
                                        <Row key={i}>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    {i === 0 && (
                                                        <Label
                                                            className="col-form-label">{props.t(`SelectImage`)}
                                                            <span className={`text-danger ml-1`}>*</span>
                                                        </Label>
                                                    )}
                                                    <Input className="form-control" type="file"
                                                           onChange={(e) => handleSelectImage(e, i)}
                                                           accept="image/*"/>
                                                    {item.error && (
                                                        <span
                                                            className={`invalid-feedback`}>{item.error}</span>)}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <FormGroup>
                                                    {i === 0 && (
                                                        <>
                                                            <Label
                                                                className="col-form-label text-white">Remove</Label>
                                                            <br/>
                                                        </>
                                                    )}
                                                    {item.image && (
                                                        <Media body style={{
                                                            width: "35px",
                                                            height: "35px",
                                                            borderRadius: "50%"
                                                        }} src={item.image} alt=""/>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    {i === 0 && (
                                                        <>
                                                            <Label
                                                                className="col-form-label text-white">Remove</Label>
                                                            <br/>
                                                        </>
                                                    )}
                                                    {i === 0 ? <Button color={`primary`} className={`btn-square px-3`}
                                                                       onClick={handleAddImage}
                                                                       type={`button`}>{`+`}</Button>
                                                        : <Button color={`danger`} className={`btn-square px-3`}
                                                                  onClick={(e) => handleImageRemove(e, i)}
                                                                  type={`button`}>{`X`}</Button>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={`/display-banner`} role={`button`} end
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(CreateDisplayBanner);