import React, {useState} from "react";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {NavLink} from "react-router-dom";
import validator from 'validator';

import useDocumentTitle from "../../utils/useDocumentTitle";
import {decryptData, encryptData} from "../../utils/crypto";
import {toast} from "react-toastify";
import Utils from "../../utils";
import {forgotPassword} from "../../services/AuthService";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let errorsObj = {email: ''};
    const [errors, setErrors] = useState(errorsObj);
    useDocumentTitle('Forgot Password');

    const onForgotPassword = (e) => {
        e.preventDefault();
        let errorObj = {...errorsObj};
        let error = false;
        if (!email) {
            errorObj.email = 'Email address is required';
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = 'Please enter valid email address';
            error = true;
        }
        setErrors(errorObj);
        if (error) return;

        setIsLoading(true);
        let data = encryptData({email: email});
        forgotPassword(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                setIsLoading(false);
                setEmail('');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    };

    return (
        <Form method={`post`} onSubmit={onForgotPassword}>
            <h4>{`Forgot Password`}</h4>
            <p>{`Please enter your account's email address below and click the "Reset  My Password button. You will receive an email that contains a link set a new password."`}</p>
            <FormGroup>
                <Label className="col-form-label">Email Address</Label>
                <Input className="form-control" type="text" value={email} name={`email`}
                       onChange={(e) => setEmail(e.target.value)} placeholder="Test@gmail.com"/>
                {errors.email && (<span className={`invalid-feedback`}>{errors.email}</span>)}
            </FormGroup>
            <FormGroup>
                {!isLoading && (
                    <Button color="primary" className="btn-block btn-square btn-lg" type={`submit`}>{`Forgot Password`}</Button>
                )}
                {isLoading && (
                    <Button color="primary" type={`button`} disabled={true} className="btn-block btn-square btn-lg">
                        <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}</Button>
                )}
            </FormGroup>
            <FormGroup className="mb-0 text-right">
                <NavLink className="link" to={`/`}>{`Back to login page`}</NavLink>
            </FormGroup>
        </Form>
    );
}

export default ForgotPassword;