import React, {useEffect, useState} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Utils from "./index";
import {checkDomain} from "../services/AuthService";
import {decryptData, encryptData} from "./crypto";
import configDb from "../config";
import {companyActions} from "../store";

function SecureRoute() {
    const company = useSelector(x => x.company.value);
    const dispatch = useDispatch();
    const [status, setStatus] = useState(500);
    const [isLoad, setIsLoad] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        (async () => {
            let hostName = Utils.getDomain();
            if (hostName) {
                await checkDomain(encryptData({domain: hostName}))
                    .then(response => {
                        setStatus(200);
                        setIsLoad(false);
                        let data = response.data;
                        Utils.setCompanyDetail(data);
                        let result = data.data;
                        if (!configDb.data.is_json) {
                            result = decryptData(data);
                            result = result.data;
                        }
                        dispatch(companyActions.login(result));
                    })
                    .catch(err => {
                        setStatus((err?.response && err.response?.status) ? err.response.status : 500);
                        setMessage(Utils.getErrorMessage(err));
                        setIsLoad(false);
                    });
            } else {
                setStatus(404);
                setIsLoad(false);
            }
        })();
    }, [dispatch]);

    useEffect(() => {
        const favicon = document.querySelector('link[rel="icon"]');
        const appleFavicon = document.querySelector('link[rel="apple-touch-icon"]');
        if (company?.favicon) {
            favicon.setAttribute('href', company.favicon);
            appleFavicon.setAttribute('href', company.favicon);
        }
    },[company]);

    return (
        <>
            {isLoad && (
                <div className={`loader-wrapper`}>
                    <div className="loader-index"><span></span></div>
                    <svg>
                        <defs></defs>
                        <filter id="goo">
                            <fegaussianblur in="SourceGraphic" stdDeviation="11" result="blur"></fegaussianblur>
                            <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                           result="goo"></fecolormatrix>
                        </filter>
                    </svg>
                </div>
            )}
            {!isLoad &&
                <>
                    {status === 200 ? <Outlet/> : <Navigate to={`/${status}`} state={{message: message}}/>}
                </>
            }
        </>
    );
}

export default SecureRoute;