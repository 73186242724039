import React, {useEffect, useState} from "react";
import {Col, Container, Media} from "reactstrap";

import sadImg from "../../assets/images/other-images/sad.png";
import {useLocation} from "react-router-dom";
import useDocumentTitle from "../../utils/useDocumentTitle";

function Error404() {
    const [message, setMessage] = useState('The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved');
    const location = useLocation();

    useDocumentTitle(404);

    useEffect(function () {
        if (location?.state?.message) {
            setMessage(location.state.message);
        }
    }, [location?.state]);
    return (
        <div className="page-wrapper">
            <div className="error-wrapper">
                <Container>
                    <Media body className="img-100" src={sadImg} alt=""/>
                    <div className="error-heading">
                        <h2 className="headline font-info">{"404"}</h2>
                    </div>
                    <Col md="8 offset-md-2">
                        <div className="sub-content" dangerouslySetInnerHTML={{__html: message}}/>
                    </Col>
                </Container>
            </div>
        </div>
    );
}

export default Error404;