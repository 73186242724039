import React, {useState, useEffect} from "react";
import validator from 'validator';
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {NavLink, useParams, useNavigate} from "react-router-dom";
import useDocumentTitle from "../../utils/useDocumentTitle";
import configDb from "../../config";
import {decryptData, encryptData} from "../../utils/crypto";
import {actionResetPasswordCheckToken, resetPassword} from "../../services/AuthService";
import {toast} from "react-toastify";
import Utils from "../../utils";

function ResetPassword() {
    const navigate = useNavigate();
    const {token} = useParams();
    const [togglePassword, setTogglePassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    let errorsObj = {password: '', confirmPassword: ''};
    const [errors, setErrors] = useState(errorsObj);
    const [linkExpMsg, setLinkExpMsg] = useState('');

    useDocumentTitle('Reset Password');
    useEffect(function () {
        let data = encryptData({token: token});
        actionResetPasswordCheckToken(data)
            .then(response => {

            })
            .catch(err => {
                setLinkExpMsg(Utils.getErrorMessage(err));
            });
    }, [token]);

    const onResetPassword = (e) => {
        e.preventDefault();
        let errorObj = {...errorsObj};
        let error = false;
        if (!password) {
            errorObj.password = 'Password is required';
            error = true;
        } else if (!validator.isStrongPassword(password, configDb.data.strongPassword)) {
            errorObj.password = "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
            error = true;
        }
        if (!confirmPassword) {
            errorObj.confirmPassword = 'Confirm password is required';
            error = true;
        } else if (password !== confirmPassword) {
            errorObj.confirmPassword = 'New password and confirm password does not match';
            error = true;
        }
        setErrors(errorObj);
        if (error) return;
        setIsLoading(true);
        let obj = {
            token: token,
            password: password
        };
        let data = encryptData(obj);
        resetPassword(data)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }

    return (
        <>
            {linkExpMsg ?
                <>
                    <h4>{`Reset Password`}</h4>
                    <p>{linkExpMsg}</p>
                </>
                :
                <Form method={`post`} onSubmit={onResetPassword}>
                    <h4>{`Reset Password`}</h4>
                    <p>{`Enter a new password for account : test@gmail.com`}</p>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label className="col-form-label">{`New password`}</Label>
                                <Input className="form-control" type={togglePassword ? "text" : "password"}
                                       name="password"
                                       value={password} onChange={(e) => setPassword(e.target.value)}
                                       placeholder="*********"/>
                                <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                    <span className={togglePassword ? "" : "show"}></span>
                                </div>
                                {errors.password && (<span className={`invalid-feedback`}>{errors.password}</span>)}
                            </FormGroup>
                            <FormGroup>
                                <Label className="col-form-label">{`Confirm password`}</Label>
                                <Input className="form-control" type="password" name="confirm_password"
                                       value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                       placeholder="*********"/>
                                {errors.confirmPassword && (
                                    <span className={`invalid-feedback`}>{errors.confirmPassword}</span>)}
                            </FormGroup>
                            <FormGroup>
                                {!isLoading && (
                                    <Button color="primary" className="btn-block btn-lg btn-square"
                                            type={`submit`}>{`Reset Password`}</Button>
                                )}
                                {isLoading && (
                                    <Button color="primary" type={`button`} disabled={true}
                                            className="btn-block btn-square btn-lg">
                                        <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}</Button>
                                )}
                            </FormGroup>
                            <FormGroup className="mb-0 text-right">
                                <NavLink className="link" to={`/`}>{`Back to login page`}</NavLink>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            }
        </>
    );
}

export default ResetPassword;