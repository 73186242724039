import React, {useEffect, useState} from "react";
import {getLanguage, translate} from "react-switch-lang";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {toast} from "react-toastify";
import validator from "validator";
import Select from "react-select";
import DatePicker from "react-datepicker";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {decryptData, encryptData} from "../../utils/crypto";
import {actionCountryList, actionTimeZoneList} from "../../services/Common";
import Utils from "../../utils";
import {actionUpdateCustomer, getCustomerDetails} from "../../services/Customer";
import configDb from "../../config";
import Breadcrumbs from "../partials/Breadcrumbs";
import {getAllTagsList} from "../../services/Tags";
import {useDispatch} from "react-redux";
import {loaderActions} from "../../store";


let statusList = configDb.data.statusList;

function EditCustomers(props) {
    const {uuid} = useParams();
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [countryId, setCountryId] = useState('');
    const [timezoneId, setTimezoneId] = useState('');
    const [postCode, setPostCode] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [countryList, setCountryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectCountry, setSelectCountry] = useState(null);
    const [selectTimezone, setSelectTimezone] = useState(null);
    const [isTimezoneLoad, setIsTimezoneLoad] = useState(false);
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [birthDate, setBirthDate] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [togglePassword, setTogglePassword] = useState(false);
    const [selectTag, setSelectTag] = useState(null);
    const [tagList, setTagList] = useState([]);
    useDocumentTitle(props.t('EditCustomer'));
    const errorsObj = {
        fullName: "",
        email: "",
        mobile: "",
        address_1: "",
        city: "",
        state: "",
        country_id: "",
        timezone_id: "",
        postcode: "",
        status_id: "",
        password: "",
        confirmPassword: "",
        birth_date: ''
    };
    const [errors, setErrors] = useState(errorsObj);
    const dispatch = useDispatch();
    useEffect(function () {
        dispatch(loaderActions.loader(true));
        let data = encryptData({uuid: uuid, language: getLanguage()});
        getCustomerDetails(data)
            .then(response => {
                response = decryptData(response.data);
                setFullName((response?.data?.full_name) ? response?.data?.full_name : '');
                setEmail((response?.data?.email) ? response.data?.email : '');
                setMobile((response?.data?.mobile) ? response.data.mobile : '');
                setAddress1((response?.data?.address_1) ? response.data.address_1 : '');
                setAddress2((response?.data?.address_2) ? response.data.address_2 : '');
                setCity((response?.data?.city) ? response.data.city : '');
                setState((response?.data?.state) ? response.data.state : '');
                setPostCode((response?.data?.postcode) ? response.data.postcode : '');
                setStatusId((response?.data?.status_id) ? response.data.status_id : '');
                setBirthDate(response?.data?.birth_date ? response.data.birth_date : '');
                setTimezoneId(response.data.timezone_id);
                actionCountryList()
                    .then(result => {
                        result = decryptData(result.data);
                        let list = [];
                        for (let i = 0; i < result.data.length; i++) {
                            list.push({
                                value: result.data[i].id,
                                label: result.data[i].name
                            });
                        }
                        setCountryList(list);
                        let index = list.findIndex(x => parseInt(x.value) === parseInt(response.data.country_id));
                        if (index > -1) {
                            setSelectCountry(list[index]);
                            setCountryId(response.data.country_id);
                        }
                    })
                    .catch(err => {
                    });

                let dataTag = encryptData({entity_type: 2, language: getLanguage()});
                getAllTagsList(dataTag)
                    .then(resultTag => {
                        resultTag = decryptData(resultTag.data);
                        let list = [];
                        let selectedTag = [];
                        let tagIds = (response?.data?.tag_ids) ? response.data.tag_ids.split(',') : [];
                        for (let i = 0; i < resultTag.data.length; i++) {
                            list.push({
                                value: resultTag.data[i].id,
                                label: resultTag.data[i].name
                            });
                            if (tagIds.findIndex(x => parseInt(x) === parseInt(resultTag.data[i].id)) > -1) {
                                selectedTag.push({
                                    value: resultTag.data[i].id,
                                    label: resultTag.data[i].name
                                });
                            }
                        }
                        setSelectTag(selectedTag);
                        setTagList(list);
                    })
                    .catch(err => {
                    });
                dispatch(loaderActions.loader(false));
            })
            .catch(err => {
                dispatch(loaderActions.loader(false));
                toast.error(Utils.getErrorMessage(err));
                navigate('/customers');
            })
    }, [uuid, navigate,dispatch]);

    useEffect(() => {
        if (countryId) {
            setIsTimezoneLoad(true);
            let data = encryptData({country_id: countryId});
            actionTimeZoneList(data)
                .then(response => {
                    response = decryptData(response.data);
                    let list = [];
                    for (let i = 0; i < response.data.length; i++) {
                        list.push({
                            value: response.data[i].id,
                            label: response.data[i].name
                        });
                    }
                    setTimeZoneList(list);
                    setIsTimezoneLoad(false);
                    let index = list.findIndex(x => parseInt(x.value) === parseInt(timezoneId));
                    if (index > -1) {
                        setSelectTimezone(list[index]);
                        setTimezoneId(timezoneId);
                    }
                })
                .catch(err => {
                    setIsTimezoneLoad(false);
                });
        }
    }, [countryId, timezoneId]);
    const showDate = (date) => {
        let dateFormat = '';
        if (date) {
            dateFormat = new Date(date);
        }
        return dateFormat;
    };
    const onUpdateTimezone = (e) => {
        setSelectTimezone(null);
        setTimezoneId('');
        setCountryId((e?.value) ? e.value : '');
        setSelectCountry(e);
    }
    const handleTimezone = (e) => {
        setSelectTimezone(e)
        setTimezoneId((e?.value) ? e.value : '');
    }
    const onUpdate = (e) => {
        e.preventDefault();
        let error = false;
        let errorObj = {...errorsObj};
        if (!fullName) {
            errorObj.fullName = props.t('RequiredFullName');
            error = true;
        }
        if (!email) {
            errorObj.email = props.t('RequiredEmail');
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = props.t('InValidEmail');
            error = true;
        }
        if (!mobile) {
            errorObj.mobile = props.t('RequiredMobile');
            error = true;
        } else if (!validator.isMobilePhone(mobile)) {
            errorObj.mobile = props.t('InValidMobile');
            error = true;
        }

        if (!password && confirmPassword) {
            errorObj.password = props.t('RequiredPassword');
            error = true;
        } else if (password && !validator.isStrongPassword(password, configDb.data.strongPassword)) {
            errorObj.password = props.t('InValidPassword');
            error = true;
        }
        if (password && !confirmPassword) {
            errorObj.confirmPassword = props.t('RequiredConfirmPassword');
            error = true;
        } else if (password !== confirmPassword) {
            errorObj.confirmPassword = props.t('PasswordNotMath');
            error = true;
        }
        if (!birthDate) {
            errorObj.birth_date = props.t('RequiredBirthDate');
            error = true;
        }
        if (countryId && !timezoneId) {
            errorObj.timezone_id = props.t('RequiredTimeZone');
            error = true;
        }
        setErrors(errorObj);
        Utils.focusError();
        if (error) return;
        setIsLoading(true);
        let birthDateFormat = Utils.dateFormatText(new Date(birthDate));
        let tagIds = '';
        if (selectTag.length > 0) {
            tagIds = selectTag.map(e => e.value);
            tagIds = tagIds.join();
        }
        let objectUpdate = encryptData({
            uuid: uuid,
            language: getLanguage(),
            full_name: fullName,
            mobile: mobile,
            email: email,
            address_1: address1,
            address_2: address2,
            city: city,
            state: state,
            country_id: countryId,
            timezone_id: timezoneId,
            birth_date: birthDateFormat,
            postcode: postCode,
            status_id: statusId,
            tag_id: tagIds
        });
        actionUpdateCustomer(objectUpdate)
            .then(response => {
                response = decryptData(response.data);
                toast.success(response.message);
                navigate('/customers');
            })
            .catch(err => {
                toast.error(Utils.getErrorMessage(err));
                setIsLoading(false);
            });
    }
    return (
        <>
            <Breadcrumbs parentLink={'/customers'} parent={`Customers`} title={`EditCustomer`}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="b-t-primary">
                            <CardBody>
                                <Form method={`post`} onSubmit={onUpdate}>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`FullName`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`full_name`}
                                                       value={fullName} onChange={(e) => setFullName(e.target.value)}
                                                       placeholder={props.t(`EnterFullName`)} maxLength={75}/>
                                                {errors.fullName && (
                                                    <span className={`invalid-feedback`}>{errors.fullName}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`EmailAddress`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="text" name={`email`}
                                                       value={email} onChange={(e) => setEmail(e.target.value)}
                                                       placeholder={props.t(`EnterEmailAddress`)} maxLength={75}/>
                                                {errors.email && (
                                                    <span className={`invalid-feedback`}>{errors.email}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`MobileNumber`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="number" name={`mobile`}
                                                       onKeyDown={(evt) => Utils.blockKeyNumberInput(evt)}
                                                       value={mobile} onChange={(e) => setMobile(e.target.value)}
                                                       placeholder={props.t(`EnterMobileNumber`)} maxLength={10}/>
                                                {errors.mobile && (
                                                    <span className={`invalid-feedback`}>{errors.mobile}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Password`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control"
                                                       type={togglePassword ? "text" : "password"} name={`password`}
                                                       value={password} onChange={(e) => setPassword(e.target.value)}
                                                       placeholder="*******"/>
                                                <div className="show-hide"
                                                     onClick={() => setTogglePassword(!togglePassword)}>
                                                    <span className={togglePassword ? "" : "show"}></span>
                                                </div>
                                                {errors.password && (
                                                    <span className={`invalid-feedback`}>{errors.password}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`ConfirmPassword`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input className="form-control" type="password"
                                                       name={`confirm_password`}
                                                       value={confirmPassword}
                                                       onChange={(e) => setConfirmPassword(e.target.value)}
                                                       placeholder="*******"/>
                                                {errors.confirmPassword && (
                                                    <span className={`invalid-feedback`}>{errors.confirmPassword}</span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`BirthDate`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <DatePicker className="form-control" type="text"
                                                            selected={showDate(birthDate)}
                                                            onChange={(date) => setBirthDate(date)}
                                                            dateFormat="dd-MM-yyyy"
                                                            maxDate={Utils.blockFutureDate()}
                                                            placeholderText={props.t(`EnterBirthDate`)}/>
                                                {errors.birth_date && (
                                                    <span className={`invalid-feedback`}>{errors.birth_date}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address1`)}</Label>
                                                <Input className="form-control" type="text" name={`address1`}
                                                       value={address1} onChange={(e) => setAddress1(e.target.value)}
                                                       placeholder={props.t(`EnterAddress1`)} maxLength={75}/>
                                                {errors.address_1 && (
                                                    <span className={`invalid-feedback`}>{errors.address_1}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Address2`)}</Label>
                                                <Input className="form-control" type="text" name={`address2`}
                                                       value={address2} onChange={(e) => setAddress2(e.target.value)}
                                                       placeholder={props.t(`EnterAddress2`)} maxLength={75}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`city`)}</Label>
                                                <Input className="form-control" type="text" name={`city`}
                                                       value={city} onChange={(e) => setCity(e.target.value)}
                                                       placeholder={props.t(`EnterCity`)} maxLength={10}/>
                                                {errors.city && (
                                                    <span className={`invalid-feedback`}>{errors.city}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`state`)}</Label>
                                                <Input className="form-control" type="text" name={`state`}
                                                       value={state} onChange={(e) => setState(e.target.value)}
                                                       placeholder={props.t(`EnterState`)} maxLength={10}/>
                                                {errors.state && (
                                                    <span className={`invalid-feedback`}>{errors.state}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Country`)}</Label>
                                                <Select options={countryList} onChange={onUpdateTimezone}
                                                        value={selectCountry} isClearable={true}
                                                        placeholder={props.t(`PleaseSelectCountry`)}
                                                        name={`country_id`}/>
                                                {errors.countryId && (
                                                    <span className={`invalid-feedback`}>{errors.countryId}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`TimeZone`)}</Label>
                                                <Select name={`time_zone`} options={timeZoneList}
                                                        value={selectTimezone} isLoading={isTimezoneLoad}
                                                        isClearable={true}
                                                        placeholder={props.t(`PleaseSelectTimezone`)}
                                                        onChange={handleTimezone}/>
                                                {errors.timezone_id && (
                                                    <span className={`invalid-feedback`}>{errors.timezone_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`postcode`)}</Label>
                                                <Input className="form-control" type="text" name={`postcode`}
                                                       value={postCode} onChange={(e) => setPostCode(e.target.value)}
                                                       placeholder={props.t(`EnterPostCode`)} maxLength={10}/>
                                                {errors.postcode && (
                                                    <span className={`invalid-feedback`}>{errors.postcode}</span>)}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Tag`)}</Label>
                                                <Select options={tagList} isMulti value={selectTag}
                                                        onChange={(e) => setSelectTag(e)}
                                                        placeholder={props.t(`PleaseSelectTag`)}
                                                        name={`tag_id`}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label className="col-form-label">{props.t(`Status`)}
                                                    <span className={`text-danger ml-1`}>*</span></Label>
                                                <Input
                                                    name="select"
                                                    value={statusId}
                                                    onChange={(e) => setStatusId(e.target.value)}
                                                    type="select">
                                                    {statusList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    )}</Input>
                                                {errors.status_id && (
                                                    <span className={`invalid-feedback`}>{errors.status_id}</span>)}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" className={`text-right`}>
                                            {!isLoading && (
                                                <>
                                                    <NavLink to={`/customers`} role={`button`} end
                                                             className={`btn btn-outline-danger btn-square mr-2`}>{props.t(`Back`)}</NavLink>
                                                    <Button color="primary" type={`submit`}
                                                            className={`btn-square`}>{props.t(`Submit`)}</Button>
                                                </>
                                            )}
                                            {isLoading && (
                                                <Button color="primary" type={`button`} disabled={true}
                                                        className={`btn-square`}>
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {props.t(`Loading`)}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default translate(EditCustomers);